import React, { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import clsx from "clsx";
import { Modal as Legacy } from "./Modal";
import { ModalProps } from "./types";

// Modal with transitions, can't be used until react and testing-library versions are updated.
// The headlessui elements are not accessible with the outdated version of testing-library in icm.

export function Modal({
    testId,
    title,
    titleClassName,
    description,
    icon,
    isOpen,
    onClose = () => {},
    children,
    withTransitions = false,
    wide = false,
    preventClose = false,
    className,
}: ModalProps) {
    return withTransitions ? (
        <Transition.Root show={isOpen} as={Fragment}>
            <Dialog
                as="div"
                className="relative z-50"
                onClose={!preventClose ? onClose : () => {}}
            >
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-40"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-40"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-slate-900 opacity-40" />
                </Transition.Child>

                <div
                    className="fixed inset-0 z-10 overflow-y-auto"
                    data-testid={testId}
                >
                    <div className="flex min-h-full items-center justify-center text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                            enterTo="opacity-100 translate-y-0 sm:scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                        >
                            <Dialog.Panel
                                className={clsx(
                                    "shadow-default bg-default border-default relative rounded-lg border text-left transition-all dark:bg-slate-800",
                                    wide ? "w-[516px]" : "w-[424px]",
                                    className,
                                )}
                            >
                                {title && (
                                    <div className="p-8">
                                        {icon && (
                                            <div className="mx-auto flex items-center justify-center">
                                                {icon}
                                            </div>
                                        )}
                                        <div className="mt-3 sm:mt-5">
                                            <Dialog.Title
                                                as="h4"
                                                className={clsx(
                                                    "font-pageheading pageheading",
                                                    titleClassName,
                                                )}
                                            >
                                                {title}
                                            </Dialog.Title>
                                            {description && (
                                                <div className="mt-2">
                                                    <p className="subdued body-m">
                                                        {description}
                                                    </p>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                )}

                                {children}
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition.Root>
    ) : (
        <Legacy
            testId={testId}
            title={title}
            description={description}
            isOpen={isOpen}
            wide={wide}
            className={className}
        >
            {children}
        </Legacy>
    );
}
