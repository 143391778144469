import { useApiQuery } from "@/api";
import { Modal, ModalFooter, Search } from "@/ui";
import { searchCompanies } from "@/entities/Company";
import { useState } from "react";
import { useDebounce } from "@/utils";

type CompanySearchModalProps = {
    isOpen: boolean;
    onCancel: () => void;
    onSelect: (companyId: number) => void;
};

export const CompanySearchModal = ({
    isOpen,
    onCancel,
    onSelect,
}: CompanySearchModalProps) => {
    const [searchTerm, setSearchTerm] = useState<string>("");
    const [selectedCompany, setSelectedCompany] = useState<number | null>(null);
    const {
        data: companies,
        isLoading,
        isError,
    } = useApiQuery(searchCompanies, {
        params: { query: searchTerm },
    });
    const debouncedSearch = useDebounce((newSearchTerm) => {
        setSearchTerm(newSearchTerm);
    }, 500);

    const handleCancel = () => {
        setSelectedCompany(null);
        setSearchTerm("");
        onCancel();
    };

    const handleSelect = (companyId: number) => {
        setSelectedCompany(null);
        onSelect(companyId);
    };

    return (
        <Modal
            isOpen={isOpen}
            className="w-[702px]"
            withTransitions
            testId="modal:company-search"
        >
            <div className="p-8">
                <Search.Header
                    title="Create a New Deal"
                    description="To select a company search, or scroll below..."
                >
                    {isError && <Search.Error />}
                    {isLoading && <Search.Loading />}
                </Search.Header>
                <Search.Bar
                    placeholder="Search"
                    searchHandler={(value) => debouncedSearch(value)}
                    useV2Background
                />
                <Search.Results className="bg-secondary">
                    {companies?.map((company) => (
                        <Search.RadioResult
                            key={company.id}
                            id={company.id}
                            name={company.name}
                            onChange={() => setSelectedCompany(company.id)}
                        />
                    ))}
                </Search.Results>
            </div>
            <ModalFooter>
                <button className="secondary" onClick={handleCancel}>
                    Cancel
                </button>
                <button
                    disabled={!selectedCompany}
                    onClick={() => handleSelect(selectedCompany!)}
                >
                    Next
                </button>
            </ModalFooter>
        </Modal>
    );
};
