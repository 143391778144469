import clsx from "clsx";

type SearchCheckboxResultProps = {
    testId?: string;
    className?: string;
    id: string | number;
    name: string;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
    children?: React.ReactNode;
    disabled?: boolean;
    checked?: boolean;
};

export function SearchCheckboxResult({
    testId,
    id,
    name,
    onChange,
    children,
    disabled,
    className,
    checked,
}: SearchCheckboxResultProps) {
    return (
        <div
            className={clsx("flex items-center p-3", className)}
            role="listitem"
            data-testid={testId}
        >
            <input
                id={id.toString()}
                name={name}
                type="checkbox"
                role="checkbox"
                className={clsx(
                    "bg-onSurface-active mr-2",
                    "border-2 border-gray-400",
                    "size-4 text-blue-500",
                    "focus:ring-0 focus:ring-offset-0 disabled:cursor-not-allowed disabled:border-transparent disabled:bg-slate-400 disabled:dark:bg-slate-900",
                )}
                data-testid={id.toString()}
                onChange={onChange}
                disabled={disabled}
                checked={checked}
            />
            <label htmlFor={id.toString()} className={clsx("mr-2")}>
                <div className="flex items-center gap-2">
                    <p
                        className={clsx(
                            "ui-element",
                            disabled && "text-gray-400",
                        )}
                    >
                        {name}
                    </p>
                    {children}
                </div>
            </label>
        </div>
    );
}
