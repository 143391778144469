import { Modal, ModalFooter, ModalProps } from "../../molecules/Modal";
import { Spinner } from "../../atoms/Spinner";
import { Form, FormContext, FormProps } from "./Form";
import { ObjectSchema } from "yup";
import { useContext } from "react";
import { FormState } from "./Form.types";
import { hasRequiredField } from "./utils";

export type FormModalProps<T extends ObjectSchema<any>> = {
    onCancel?: () => void;
    submitPending?: boolean;
    cancelLabel?: string;
    submitLabel?: string;
} & Pick<ModalProps, "isOpen" | "title" | "description"> &
    FormProps<T>;

export function FormModal<T extends ObjectSchema<any>>({
    id,
    isOpen,
    title,
    description,
    schema,
    children,
    submitPending = false,
    onChange,
    onSubmit,
    onCancel,
    cancelLabel = "Cancel",
    submitLabel = "Save",
    ...rest
}: FormModalProps<T>) {
    const hasRequired = hasRequiredField(schema);

    return (
        <Modal
            isOpen={isOpen}
            title={title}
            description={description}
            className="w-[702px]"
            withTransitions
            testId={`modal:${id}`}
        >
            <Form
                id={id}
                schema={schema}
                onChange={onChange}
                onSubmit={onSubmit}
                {...rest}
            >
                <div className="px-8">
                    {children}
                    {hasRequired && (
                        <div className="mt-2 flex items-baseline gap-1">
                            <p className="ui-element text-red-400">*</p>
                            <p className="body-micro">Required fields</p>
                        </div>
                    )}
                </div>

                <FormModalFooter
                    formId={id}
                    cancelLabel={cancelLabel}
                    submitLabel={submitLabel}
                    onCancel={onCancel}
                    submitPending={submitPending}
                />
            </Form>
        </Modal>
    );
}

function FormModalFooter<T extends ObjectSchema<any>>({
    formId,
    onCancel,
    submitPending,
    cancelLabel,
    submitLabel,
}: Pick<
    FormModalProps<T>,
    "onCancel" | "submitPending" | "cancelLabel" | "submitLabel"
> & { formId: string }) {
    const form = useContext(FormContext) as FormState<T> | undefined;

    if (!form) return null;

    return (
        <ModalFooter>
            <button className="secondary" onClick={onCancel}>
                {cancelLabel}
            </button>
            <button
                className="inline-flex gap-2"
                disabled={!form.canSubmit}
                type="submit"
                form={formId}
            >
                {submitLabel}
                {submitPending && <Spinner />}
            </button>
        </ModalFooter>
    );
}
