import React from "react";
import { IconProps } from "./types";

const CircleFileCheckIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#92ABFA" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 19.5C19 18.6716 19.6716 18 20.5 18H27.3787C27.7765 18 28.158 18.158 28.4393 18.4393L32.5607 22.5607C32.842 22.842 33 23.2235 33 23.6213V32.5C33 33.3284 32.3284 34 31.5 34H20.5C19.6716 34 19 33.3284 19 32.5V19.5ZM29.8566 25.1911C30.1002 24.8561 30.0261 24.3871 29.6911 24.1434C29.3561 23.8998 28.8871 23.9739 28.6434 24.3089L25.1597 29.099L23.2803 27.2197C22.9874 26.9268 22.5126 26.9268 22.2197 27.2197C21.9268 27.5126 21.9268 27.9874 22.2197 28.2803L24.7197 30.7803C24.8748 30.9354 25.09 31.0149 25.3087 30.9977C25.5273 30.9805 25.7275 30.8685 25.8566 30.6911L29.8566 25.1911Z"
                        fill="#2A313F"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleFileCheckIcon;
