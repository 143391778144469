import { ApiClient } from "../types/client.types";
import { AppContext } from "../types/context.types";
import { QueryFunction } from "../types/query.types";
import { createApiClient } from "./createApiClient";

/**
 * This function spies on the api call within a queryFn and returns the
 * url that would be called. This is used to build the query key for
 * react-query.
 * @param queryFn: the query function that makes use of the api passed via context
 * @param context: the current context that would be passed to the queryFn if it were called
 */
export function getCacheKey<T>(
    queryFn: QueryFunction<T>,
    context: AppContext,
    scope?: string,
): string[] {
    const key: string[] = scope ? [scope] : [];
    const spy = (url: string) => {
        if (url) key.push(url);
        throw new Error("abort");
    };
    const apiSpy = {
        ...createApiClient(),
        get: spy,
        post: spy,
        put: spy,
        delete: spy,
        patch: spy,
        isMock: true,
    } as any as ApiClient;

    try {
        queryFn({ ...context, api: apiSpy });
    } catch (e) {
        return key.length > 0 ? key : ["no-cache-key"];
    } finally {
        // eslint-disable-next-line no-unsafe-finally
        return key.length > 0 ? key : ["no-cache-key"];
    }
}
