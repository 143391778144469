import React from "react";
import { IconProps } from "./types";

const CircleHandIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#92ABFA" />
                    <path
                        d="M22.8661 18.849C22.5899 18.3707 21.9783 18.2068 21.5 18.483C21.0217 18.7591 20.8579 19.3707 21.134 19.849L24.384 25.4782C24.5221 25.7173 24.4401 26.0231 24.201 26.1612C23.9618 26.2992 23.6561 26.2173 23.518 25.9782L20.768 21.215C20.4918 20.7367 19.8802 20.5728 19.402 20.849C18.9237 21.1251 18.7598 21.7367 19.0359 22.215L21.7859 26.9782C21.924 27.2173 21.8421 27.5231 21.6029 27.6612C21.3638 27.7992 21.058 27.7173 20.9199 27.4782L19.1699 24.4471C18.8938 23.9688 18.2822 23.8049 17.8039 24.081C17.3256 24.3572 17.1617 24.9688 17.4379 25.4471L20.9379 31.5092C22.8709 34.8573 27.152 36.0044 30.5 34.0714C33.8481 32.1384 34.9952 27.8573 33.0622 24.5092L31.0622 21.0451C30.7861 20.5668 30.1745 20.403 29.6962 20.6791C29.2179 20.9553 29.054 21.5668 29.3302 22.0451L31.0802 25.0762C31.2182 25.3154 31.1363 25.6212 30.8971 25.7592C30.658 25.8973 30.3522 25.8154 30.2141 25.5762L25.9641 18.215C25.688 17.7367 25.0764 17.5728 24.5981 17.849C24.1198 18.1251 23.9559 18.7367 24.2321 19.215L26.9821 23.9782C27.1202 24.2173 27.0382 24.5231 26.7991 24.6612C26.5599 24.7992 26.2541 24.7173 26.1161 24.4782L22.8661 18.849Z"
                        fill="#2A313F"
                    />
                    <path
                        d="M30.4863 16.8633C30.4863 16.8633 31.1325 17.1556 32.2404 18.2635C33.3483 19.3714 34.0825 21.087 34.0825 21.087"
                        stroke="#2A313F"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleHandIcon;
