import React from "react";
import { IconProps } from "./types";

const HumConnectIcon = ({
    className,
    width = "140",
    height = "140",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <circle
                        cx="23.5149"
                        cy="116.561"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="4.30847"
                        cy="70.5146"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="52.05"
                        cy="110.526"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="110.647"
                        cy="88.4336"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="39.7"
                        cy="39.8896"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="23.5149"
                        cy="23.6543"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="70.0144"
                        cy="4.66504"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="116.209"
                        cy="23.71"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="135.653"
                        cy="70.3037"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="116.209"
                        cy="116.561"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <circle
                        cx="69.8596"
                        cy="135.942"
                        r="1.27068"
                        fill="#3E4758"
                        stroke="#3E4758"
                        strokeWidth="1.45863"
                    />
                    <line
                        x1="4.09783"
                        y1="70.9908"
                        x2="23.2236"
                        y2="23.8074"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="4.52049"
                        y1="70.3024"
                        x2="70.2264"
                        y2="135.941"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="70.2524"
                        y1="136.416"
                        x2="116.09"
                        y2="116.496"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="116.316"
                        y1="116.956"
                        x2="115.916"
                        y2="23.9219"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="116.095"
                        y1="24.1984"
                        x2="69.7456"
                        y2="5.15376"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="23.3254"
                        y1="23.4035"
                        x2="69.9021"
                        y2="4.59752"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="23.5715"
                        y1="116.926"
                        x2="23.2149"
                        y2="23.6826"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="23.4365"
                        y1="116.842"
                        x2="116.208"
                        y2="116.471"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="135.986"
                        y1="70.7277"
                        x2="69.8031"
                        y2="5.08917"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="135.975"
                        y1="70.7272"
                        x2="70.0713"
                        y2="136.366"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="69.8967"
                        y1="136.364"
                        x2="23.5754"
                        y2="116.598"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="22.9028"
                        y1="116.193"
                        x2="4.03121"
                        y2="70.4919"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="3.94691"
                        y1="70.3022"
                        x2="69.8025"
                        y2="4.66368"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="23.6748"
                        y1="23.3895"
                        x2="116.617"
                        y2="23.6218"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="115.597"
                        y1="23.57"
                        x2="135.928"
                        y2="70.3951"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="135.93"
                        y1="70.6304"
                        x2="116.493"
                        y2="117.108"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="39.8839"
                        y1="39.9753"
                        x2="70.0441"
                        y2="70.1127"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="69.7133"
                        y1="70.3824"
                        x2="110.768"
                        y2="88.3701"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="69.8677"
                        y1="70.7775"
                        x2="52.3249"
                        y2="110.857"
                        stroke="#3E4758"
                        strokeWidth="0.6"
                    />
                    <line
                        x1="70.2198"
                        y1="70.5169"
                        x2="69.8639"
                        y2="125.207"
                        stroke="#F1F5F9"
                    />
                    <line
                        x1="70.0414"
                        y1="70.7722"
                        x2="15.1584"
                        y2="125.134"
                        stroke="#F1F5F9"
                    />
                    <line
                        opacity="0.5"
                        x1="69.787"
                        y1="70.785"
                        x2="49.8392"
                        y2="79.1865"
                        stroke="#F1F5F9"
                    />
                    <line
                        x1="69.4305"
                        y1="70.8343"
                        x2="10.4597"
                        y2="44.7831"
                        stroke="#F1F5F9"
                    />
                    <line
                        x1="69.7479"
                        y1="70.5059"
                        x2="69.7479"
                        y2="27.9204"
                        stroke="#F1F5F9"
                    />
                    <line
                        x1="69.4693"
                        y1="70.3023"
                        x2="96.1903"
                        y2="10.3548"
                        stroke="#F1F5F9"
                    />
                    <line
                        opacity="0.5"
                        x1="69.7341"
                        y1="70.0441"
                        x2="90.0759"
                        y2="61.5899"
                        stroke="#F1F5F9"
                    />
                    <line
                        x1="69.926"
                        y1="70.3037"
                        x2="123.107"
                        y2="70.3037"
                        stroke="#F1F5F9"
                    />
                    <line
                        opacity="0.5"
                        x1="70.285"
                        y1="70.0992"
                        x2="85.5981"
                        y2="85.8959"
                        stroke="#F1F5F9"
                    />
                    <circle cx="19.8931" cy="92.2793" r="2" fill="#F5F7FB" />
                    <circle cx="15.1609" cy="125.205" r="2" fill="#F5F7FB" />
                    <circle cx="69.926" cy="125.205" r="2" fill="#F5F7FB" />
                    <circle cx="116.216" cy="116.56" r="2" fill="#F5F7FB" />
                    <circle cx="124.558" cy="70.5137" r="2" fill="#F5F7FB" />
                    <circle cx="130.259" cy="44.0244" r="2" fill="#F5F7FB" />
                    <circle cx="96.311" cy="10.1904" r="2" fill="#F5F7FB" />
                    <circle cx="69.8596" cy="27.3447" r="2" fill="#F5F7FB" />
                    <circle cx="9.92212" cy="44.3262" r="2" fill="#F5F7FB" />
                    <g clipPath="url(#clip0_1839_17222)">
                        <rect
                            x="48.0358"
                            y="48.5361"
                            width="43.9572"
                            height="43.9572"
                            rx="21.9786"
                            fill="#151C23"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M91.9929 70.5147C91.9929 82.6532 82.1528 92.4933 70.0143 92.4933C57.8759 92.4933 48.0358 82.6532 48.0358 70.5147C48.0358 58.3763 57.8759 48.5361 70.0143 48.5361C82.1528 48.5361 91.9929 58.3763 91.9929 70.5147ZM84.1173 63.7991V63.0054H72.3343C70.747 63.0054 69.4649 63.4938 68.9154 64.3485L68.0607 65.9969C65.6677 64.1248 62.7341 63.0756 59.6966 63.0054H59.4524L65.5576 70.82L59.5135 82.664L60.185 83.0303L60.8566 81.7482C62.0166 79.6114 63.1766 77.7799 65.7407 76.681C72.6979 73.5956 73.8401 70.0907 74.7514 67.2947L74.7764 67.218C74.8818 66.8187 75.0249 66.4303 75.2037 66.058C75.8753 64.1043 76.73 63.8601 79.7826 63.7991H84.1173ZM72.2831 64.5463C72.4365 64.5573 72.5826 64.6162 72.7006 64.7148C72.8216 64.8046 72.9145 64.9268 72.9685 65.0674C73.0226 65.2079 73.0355 65.3609 73.0059 65.5085C72.9811 65.6266 72.9297 65.7374 72.8557 65.8326C72.7816 65.9278 72.6868 66.0049 72.5785 66.058L72.2122 66.1801C72.1227 66.1837 72.0334 66.1693 71.9495 66.1379C71.8656 66.1064 71.7889 66.0585 71.7238 65.9969C71.6076 65.8898 71.519 65.7561 71.4659 65.6072C71.4127 65.4583 71.3965 65.2987 71.4185 65.1422C71.4427 65.0313 71.4946 64.9284 71.5693 64.843C71.644 64.7575 71.7392 64.6925 71.8459 64.6538C71.9768 64.573 72.1297 64.5354 72.2831 64.5463Z"
                            fill="#FCFDFE"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1839_17222">
                            <rect
                                x="48.0358"
                                y="48.5361"
                                width="43.9572"
                                height="43.9572"
                                rx="21.9786"
                                fill="white"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default HumConnectIcon;
