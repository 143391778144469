import { countries } from "countries-list";
import { US_STATES, USStates } from "@/constants";

const getStateValue = (state: string) => {
    if (state && state in US_STATES) {
        return US_STATES[state];
    }
    return null;
};

const getCountryValue = (country: string | undefined) => {
    if (country && country in countries) {
        return countries[country].name;
    }
    return null;
};

export const getStateCodeByName = (stateName: string) =>
    Object.keys(US_STATES).find((key) => US_STATES[key] === stateName);

export const getCountryCodeByName = (countryName: string) =>
    Object.keys(countries).find((key) => countries[key].name === countryName);

export const getLocation = (state: string, country: string | undefined) => {
    return getStateValue(state) || getCountryValue(country);
};

export const getLocationOrCountry = (data: {
    state: USStates;
    country: string;
}) => {
    if (data.state === USStates.OutsideTheUS) {
        return data.country ? countries[data.country].name : "N/A";
    }
    return US_STATES[data.state];
};
