import { Input, MutableList, Section, TextArea } from "@/ui";
import { PlusIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { ddActions } from "@/utils";
import { ProfileHero } from "@/entities/CompanyProfile";
import clsx from "clsx";

export type HeroFormProps = {
    inputs: ProfileHero;
    onChange: (data: any) => void;
};

export const HeroForm = () => {
    return (
        <Section title="About your company">
            <p
                className={clsx(
                    "-mt-4 pb-6",
                    "border-default border-b",
                    "body-s",
                )}
            >
                Share information about this deal below. You may share
                identifying information in the red Private Teaser zone below.
                All other information outside of this zone must be anonymized as
                it will appear on the Public Teaser page.
            </p>

            <div className="my-8">
                <h5 className="heading-l">Public Teaser</h5>
                <p className="body-s">
                    The information you share in the Public Teaser must be
                    anonymized. You may share data like industry, location and
                    revenue model, but cannot share identifying information like
                    your company name or team members.
                </p>
            </div>
            <Input
                parent="hero"
                label="Public Headline"
                name="publicHeadline"
                placeholder="E.g: Manufacturer of athleisure performance apparel."
            />

            <TextArea
                parent="hero"
                label="Public Paragraph Description"
                name="publicDescription"
                placeholder="E.g: Manufacturer of athleisure performance apparel."
            />
            <div className={clsx("my-8 p-4", "rounded border border-red-600")}>
                <h5 className="heading-l">Private Teaser</h5>
                <p className="body-s mb-8">
                    The text in this section will be privately shown to
                    investors once they have logged in. You can name your
                    company, team members and share any other identifying
                    information. The headline and paragraph descriptions below
                    are the only areas of this page where you may share
                    identifying information.
                </p>
                <Input
                    parent="hero"
                    label="Private Headline"
                    name="privateHeadline"
                    placeholder="E.g: Nike athleisure performance apparel."
                />

                <TextArea
                    parent="hero"
                    label="Private Paragraph Description"
                    name="privateDescription"
                    placeholder="E.g: Manufacturer of athleisure performance apparel."
                />
            </div>
            <MutableList name="hero.callouts">
                {({ items, addItem }) => (
                    <div>
                        <div
                            className={clsx(
                                "mt-8",
                                "grid grid-cols-1",
                                "gap-3",
                                "md:grid-cols-3",
                            )}
                        >
                            {items.map(({ parent, remove }, index) => (
                                <div
                                    key={parent}
                                    data-testid={parent}
                                    className="relative mt-4 p-4"
                                >
                                    <XMarkIcon
                                        data-testid={`delete-callout-${index + 1}`}
                                        onClick={remove}
                                        width={20}
                                        height={20}
                                        className="absolute right-0 top-5 cursor-pointer"
                                        dd-action={
                                            ddActions.profile.hero.callout
                                                .delete
                                        }
                                    />
                                    <Input
                                        parent={parent}
                                        name="title"
                                        placeholder="Label, eg. LTM Revenue"
                                        label={`Key info ${index + 1}`}
                                    />
                                    <Input
                                        parent={parent}
                                        name="value"
                                        placeholder="Value, eg. $10M"
                                        label={`Key value ${index + 1}`}
                                    />
                                </div>
                            ))}
                        </div>
                        <button
                            onClick={addItem}
                            dd-action={ddActions.profile.hero.callout.add}
                            className="secondary mt-8 inline-flex gap-2"
                        >
                            <PlusIcon className="mr-2 size-4" />
                            <p className="text-button">Add key info item</p>
                        </button>
                    </div>
                )}
            </MutableList>
        </Section>
    );
};
