import {
    ReactNode,
    createContext,
    Children,
    cloneElement,
    isValidElement,
} from "react";
import { InferType, ObjectSchema } from "yup";
import { FormState, NativeFormProps } from "./Form.types";
import { useForm } from "./useForm";
import { FormModal } from "./FormModal";

export const FormContext = createContext<
    FormState<ObjectSchema<any>> | undefined
>(undefined);

export type FormProps<T extends ObjectSchema<any>> = {
    id: string;
    children: ReactNode;
    schema: T;
    onSubmit?: (values: InferType<T>) => void;
    onChange?: (values: FormState<T>) => void;
    defaultValues?: InferType<T>;
} & Omit<
    NativeFormProps,
    "onSubmit" | "onChange" | "defaultValue" | "defaultChecked"
>;

export function Form<T extends ObjectSchema<any>>({
    id,
    schema,
    onSubmit,
    onChange,
    children,
    defaultValues,
    ...props
}: FormProps<T>) {
    type FormValues = InferType<typeof schema>;
    const form = useForm({ schema, onChange, defaultValues });
    const { data, isValid } = form;

    return (
        <FormContext.Provider value={form}>
            <form
                {...props}
                onSubmit={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    onSubmit && onSubmit(data as FormValues);
                }}
                id={id}
                data-testid={id}
            >
                {Children.map(children, (child) => {
                    if (isValidElement(child) && child.type === "button") {
                        const { disabled, ...otherProps } =
                            child.props as React.ButtonHTMLAttributes<HTMLButtonElement>;
                        const isSubmitButton = child.props.type === "submit";
                        return cloneElement(child, {
                            ...otherProps,
                            disabled: (isSubmitButton && !isValid) || disabled,
                        } as React.ButtonHTMLAttributes<HTMLButtonElement>);
                    }
                    return child;
                })}
            </form>
        </FormContext.Provider>
    );
}

Form.Modal = FormModal;
