import { UserRole } from "@/entities/User/User.model";
import { Onboarding } from "@/features/Onboarding";
import { Spinner } from "@/ui";
import { asPage } from "@/utils";
import { useAuth0 } from "@auth0/auth0-react";
import clsx from "clsx";
import { useState } from "react";

function SignupPage() {
    const { loginWithRedirect } = useAuth0();
    const [isRedirectingTo, setIsRedirecting] = useState<
        null | "company" | "fund"
    >(null);

    // TODO: If you are authenticated this page should redirect.
    return (
        <Onboarding.Layout centered>
            <div className="w-full md:w-[400px]">
                <Onboarding.Header title="What is your reason for signing up with Hum, today?" />
            </div>
            <div className="w-full md:w-[400px]">
                <div
                    className={clsx(
                        "flex flex-col",
                        "items-center justify-center gap-6",
                    )}
                >
                    <button
                        className="secondary xl w-full"
                        disabled={!!isRedirectingTo}
                        onClick={() => {
                            setIsRedirecting("company");
                            loginWithRedirect({
                                authorizationParams: {
                                    redirect_uri:
                                        process.env.AUTH0_LOGIN_REDIRECT_URI +
                                        `/post-signup/${UserRole.COMPANY}`,
                                    screen_hint: "signup",
                                },
                            });
                        }}
                    >
                        I’m looking to fundraise
                        {isRedirectingTo === "company" && <Spinner />}
                    </button>
                    <button
                        className="secondary xl w-full"
                        disabled={!!isRedirectingTo}
                        onClick={() => {
                            setIsRedirecting("fund");
                            loginWithRedirect({
                                authorizationParams: {
                                    redirect_uri:
                                        process.env.AUTH0_LOGIN_REDIRECT_URI +
                                        `/post-signup/${UserRole.FUND}`,
                                    screen_hint: "signup",
                                },
                            });
                        }}
                    >
                        I’m looking to invest
                        {isRedirectingTo === "fund" && <Spinner />}
                    </button>
                </div>
            </div>
        </Onboarding.Layout>
    );
}

export default asPage(SignupPage);
