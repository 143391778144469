import React from "react";
import { IconProps } from "./types";

const CircleSearchIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="40" height="40" rx="20" fill="#92ABFA" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19 13.5C15.9624 13.5 13.5 15.9624 13.5 19C13.5 22.0376 15.9624 24.5 19 24.5C20.519 24.5 21.893 23.8852 22.8891 22.8891C23.8852 21.893 24.5 20.519 24.5 19C24.5 15.9624 22.0376 13.5 19 13.5ZM12 19C12 15.134 15.134 12 19 12C22.866 12 26 15.134 26 19C26 20.6625 25.4197 22.1906 24.4517 23.3911L27.7803 26.7197C28.0732 27.0126 28.0732 27.4874 27.7803 27.7803C27.4874 28.0732 27.0126 28.0732 26.7197 27.7803L23.3911 24.4517C22.1906 25.4197 20.6625 26 19 26C15.134 26 12 22.866 12 19Z"
                        fill="#0C1014"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleSearchIcon;
