import { useContext, useState } from "react";
import { RawCheckbox } from "./RawCheckbox";
import { FormContext } from "./Form";
import { InferType, ObjectSchema } from "yup";
import { FormState } from "./Form.types";
import { isRequired } from "./utils";

type CheckboxProps = {
    name: string;
    label: string;
    parent?: string;
    index?: number;
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
};

export function Checkbox<T extends ObjectSchema<any>>({
    parent = "",
    ...props
}: CheckboxProps) {
    const [touched, setTouched] = useState(false);
    const name = parent ? `${parent}.${props.name}` : props.name;
    const form = useContext(FormContext) as FormState<T> | undefined;

    if (!form) return null;

    const error = form.errors?.[name] || "";
    const showError =
        touched && error && !error.toLowerCase().includes("required");

    return (
        <RawCheckbox
            {...props}
            name={name}
            onChange={(e) => {
                const value = e.target.checked;
                setTouched(true);
                form.updateValue(name as InferType<T>, value);
                props.onChange?.(e);
            }}
            checked={form.values[name] || false}
            required={isRequired(form.schema, name)}
            errorMessage={showError ? form.errors?.[name] : undefined}
            showErrorRing={Boolean(touched && error)}
        />
    );
}
