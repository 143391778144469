import { Widget } from "@/ui/organisms/Widget";
import clsx from "clsx";

export function StatWidget({
    label,
    stat,
    accented = false,
}: {
    label: string;
    stat: string;
    accented?: boolean;
}) {
    return (
        <Widget accented={accented}>
            <div
                className={clsx(
                    "grid h-full grid-cols-2",
                    "items-center gap-10 rounded",
                )}
            >
                <p className="subheading subdued leading-snug">{label}</p>
                <p className="display-s">{stat}</p>
            </div>
        </Widget>
    );
}
