import clsx from "clsx";

export const Steps = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            className={clsx(
                "border-y border-slate-700",
                "flex-col",
                "items-start justify-start",
                "divide-y divide-slate-700",
                "hidden lg:flex",
            )}
        >
            {children}
        </div>
    );
};
