export const List = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="120"
            height="109"
            viewBox="0 0 120 109"
            fill="none"
            className={className}
        >
            <g filter="url(#filter0_d_454_5299)">
                <rect x="4.5" width="102" height="90" rx="5" fill="#2042F6" />
                <g filter="url(#filter1_bd_454_5299)">
                    <rect
                        x="12.5"
                        y="7"
                        width="103"
                        height="94"
                        rx="5"
                        fill="white"
                        fillOpacity="0.95"
                        shapeRendering="crispEdges"
                    />
                </g>
                <rect
                    x="36.5"
                    y="17"
                    width="70"
                    height="5"
                    rx="2.5"
                    fill="#788598"
                />
                <rect
                    x="36.5"
                    y="27"
                    width="32"
                    height="5"
                    rx="2.5"
                    fill="#788598"
                />
                <g filter="url(#filter2_b_454_5299)">
                    <circle cx="26.5" cy="25" r="5" fill="#77F1A1" />
                </g>
                <g filter="url(#filter3_b_454_5299)">
                    <circle cx="26.5" cy="55" r="5" fill="#77F1A1" />
                </g>
                <g opacity="0.6" filter="url(#filter4_b_454_5299)">
                    <circle cx="26.5" cy="85" r="5" fill="#CBD5E1" />
                </g>
                <rect
                    x="36.5"
                    y="47"
                    width="70"
                    height="5"
                    rx="2.5"
                    fill="#788598"
                />
                <rect
                    x="36.5"
                    y="57"
                    width="70"
                    height="5"
                    rx="2.5"
                    fill="#788598"
                />
                <g opacity="0.6">
                    <rect
                        x="36.5"
                        y="77"
                        width="70"
                        height="5"
                        rx="2.5"
                        fill="#CBD5E1"
                    />
                    <rect
                        x="36.5"
                        y="87"
                        width="51"
                        height="5"
                        rx="2.5"
                        fill="#CBD5E1"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_d_454_5299"
                    x="0.5"
                    y="0"
                    width="119"
                    height="109"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_454_5299"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_454_5299"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_bd_454_5299"
                    x="2.5"
                    y="-3"
                    width="123"
                    height="114"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_454_5299"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_454_5299"
                        result="effect2_dropShadow_454_5299"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_454_5299"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_b_454_5299"
                    x="17.5"
                    y="16"
                    width="18"
                    height="18"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_454_5299"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_454_5299"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_b_454_5299"
                    x="17.5"
                    y="46"
                    width="18"
                    height="18"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_454_5299"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_454_5299"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_b_454_5299"
                    x="17.5"
                    y="76"
                    width="18"
                    height="18"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_454_5299"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_454_5299"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
