import React from "react";
import { IconProps } from "./types";

const UserIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.1692 10.25C13.826 10.25 15.1692 8.90685 15.1692 7.25C15.1692 5.59315 13.826 4.25 12.1692 4.25C10.5123 4.25 9.16919 5.59315 9.16919 7.25C9.16919 8.90685 10.5123 10.25 12.1692 10.25Z"
                        fill="#788598"
                    />
                    <path
                        d="M5.63436 16.7435C5.43948 17.2516 5.61354 17.821 6.04339 18.1546C7.73575 19.468 9.86121 20.25 12.1693 20.25C14.4798 20.25 16.6072 19.4664 18.3004 18.1506C18.73 17.8167 18.9037 17.2471 18.7085 16.7392C17.6993 14.1135 15.1534 12.25 12.1723 12.25C9.18951 12.25 6.64248 14.1156 5.63436 16.7435Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default UserIcon;
