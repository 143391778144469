import { datadogLogs } from "@datadog/browser-logs";

// Usage: log.info('Button clicked', { name: 'buttonName', id: 123 })
type LogContext = Record<string, unknown>;

export const log = {
    info: (message: string, context: LogContext) =>
        datadogLogs.logger.info(message, context),
    error: (message: string, context: LogContext) =>
        datadogLogs.logger.error(message, context),
    warn: (message: string, context: LogContext) =>
        datadogLogs.logger.warn(message, context),
    debug: (message: string, context: LogContext) =>
        datadogLogs.logger.debug(message, context),
};
