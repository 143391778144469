import { Onboarding } from "@/features/Onboarding";
import { asPage } from "@/utils";
import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import { NavLink } from "react-router-dom";

function LoginErrorPage() {
    return (
        <Onboarding.Layout centered>
            <div className="flex max-w-xl flex-col gap-10">
                <ExclamationTriangleIcon className="mx-auto size-20" />
                <h2 className="text-center">Login Error</h2>
                <p className="body-l subdued text-center">
                    We encountered a network error while trying to log you in.
                    Please try again. If the problem persists, contact our
                    support team for assistance.
                </p>
                <div className="flex items-center justify-center gap-8">
                    <NavLink to="/logout">
                        <button className="md w-[118px]">Retry Login</button>
                    </NavLink>
                    <a
                        href="https://info.humcapital.com/hum-help-center/kb-tickets/new"
                        target="_blank"
                        rel="noreferrer"
                    >
                        Talk to our team
                    </a>
                </div>
            </div>
        </Onboarding.Layout>
    );
}

export default asPage(LoginErrorPage);
