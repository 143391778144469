import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import { Widget } from "./Widget";

export function ExpandableWidget({
    layoutId,
    children,
    accented = false,
}: {
    layoutId: string;
    children: React.FC<{
        isExpanded: boolean;
        expand: () => void;
        collapse: () => void;
    }>;
    accented?: boolean;
}) {
    const [isExpanded, setIsExpanded] = useState(false);

    return (
        <Widget accented={accented}>
            <AnimatePresence>
                <motion.div
                    layout
                    layoutId={layoutId}
                    exit={{ y: -10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                >
                    {children({
                        isExpanded,
                        expand: () => setIsExpanded(true),
                        collapse: () => setIsExpanded(false),
                    })}
                </motion.div>
            </AnimatePresence>
        </Widget>
    );
}
