import { ObjectSchema, SchemaDescription, isSchema } from "yup";

type FieldDescription = SchemaDescription & {
    fields?: Record<string, FieldDescription>;
    innerType?: FieldDescription;
    optional?: boolean;
};

type KeyValueObject = Record<string, any>;

export function unflattenObject(flatObject: KeyValueObject): KeyValueObject {
    const nestedObject: KeyValueObject = {};

    for (const key in flatObject) {
        if (Object.prototype.hasOwnProperty.call(flatObject, key)) {
            const value = flatObject[key];
            const keys = key.split(/\.|\[|\]\.?/).filter(Boolean);

            keys.reduce((acc, k, i) => {
                if (i === keys.length - 1) {
                    acc[k] = value;
                } else {
                    if (!acc[k]) {
                        acc[k] = isNaN(Number(keys[i + 1])) ? {} : [];
                    }
                }
                return acc[k];
            }, nestedObject);
        }
    }

    return nestedObject;
}

export function flattenObject(
    nestedObject: KeyValueObject,
    parentKey = "",
    result: KeyValueObject = {},
): KeyValueObject {
    for (const key in nestedObject) {
        if (Object.prototype.hasOwnProperty.call(nestedObject, key)) {
            const newKey = parentKey ? `${parentKey}.${key}` : key;
            if (
                typeof nestedObject[key] === "object" &&
                nestedObject[key] !== null &&
                !Array.isArray(nestedObject[key])
            ) {
                flattenObject(nestedObject[key], newKey, result);
            } else if (Array.isArray(nestedObject[key])) {
                nestedObject[key].forEach((item, index) => {
                    flattenObject(item, `${newKey}[${index}]`, result);
                });
                if (nestedObject[key].length === 0) {
                    result[newKey] = [];
                }
            } else {
                result[newKey] = nestedObject[key];
            }
        }
    }
    return result;
}

export function findLength(obj, key) {
    const regex = new RegExp(`${key}\\[(\\d+)\\]`);
    let maxIndex: number | null = null;

    for (const objKey in obj) {
        if (objKey.startsWith(key) && regex.test(objKey)) {
            const match = objKey.match(regex);
            const index = parseInt(match![1], 10);
            if (maxIndex === null || index > maxIndex) {
                maxIndex = index;
            }
        }
    }

    return maxIndex === null ? maxIndex : maxIndex + 1;
}

const getArraySegment = (segment: string): string => {
    const arrayMatch = segment.match(/(.+)\[(\d+)\]/);
    return arrayMatch ? arrayMatch[1] : segment;
};

const getFieldByPath = (
    schemaDescription: FieldDescription,
    path: string[],
): FieldDescription | null => {
    let currentSchema: FieldDescription | undefined = schemaDescription;

    for (const segment of path) {
        const arraySegment = getArraySegment(segment);

        if (
            currentSchema &&
            currentSchema.fields &&
            currentSchema.fields[arraySegment]
        ) {
            currentSchema = currentSchema.fields[arraySegment];
            if (currentSchema.type === "array") {
                if (segment === arraySegment) {
                    break;
                } else {
                    currentSchema = currentSchema.innerType;
                }
            }
        } else {
            return null;
        }
    }

    return currentSchema || null;
};

export const isRequired = (
    schema: ObjectSchema<any>,
    fieldName: string,
): boolean => {
    if (!isSchema(schema)) return true;

    const path = fieldName.split(".");
    const schemaDescription = schema.describe() as FieldDescription;
    const fieldSchema = getFieldByPath(schemaDescription, path);

    return fieldSchema ? !fieldSchema.optional : false;
};

export const hasRequiredField = (schema: ObjectSchema<any>) => {
    const { fields } = schema.describe();
    for (const key in fields) {
        //@ts-expect-error yup lib typing
        if (!fields[key].optional) return true;
    }

    return false;
};

// Example usage
// const values = {
//   "callouts[0].name": "Name 1",
//   "callouts[0].value": 0,
//   "callouts[1].name": "Name 2",
// };

// console.log(findMaxIndex(values, "callouts")); // Output: 1
// console.log(findMaxIndex(values, "highlights")); // Output: 0
// console.log(findMaxIndex(values, "name")); // Output: null

// const values = {
//   "callouts[0].name": "Name 1",
//   "callouts[0].value": 0,
//   "callouts[1].name": "Name 2",
//   "highlights[0].icon": "dollar",
//   name: "Deal name",
// };

// const nestedValues = {
//   callouts: [{ name: "Name 1", value: 0 }, { name: "Name 2" }],
//   highlights: [{ icon: "dollar" }],
//   name: "Deal name",
// };

// console.log(
//   JSON.stringify(unflattenObject(values)) === JSON.stringify(nestedValues)
// );
// console.log(
//   JSON.stringify(flattenObject(nestedValues)) === JSON.stringify(values)
// );
