import { atomWithStorage } from "jotai/utils";

function atomWithLocalStorage<T>(key: string, initialValue: T) {
    const getInitialValue = (): T => {
        const storedValue = localStorage.getItem(key);
        if (storedValue) {
            try {
                return JSON.parse(storedValue) as T;
            } catch (e) {
                console.warn(`Error parsing localStorage key "${key}":`, e);
                localStorage.removeItem(key);
            }
        }
        return initialValue;
    };

    return atomWithStorage<T>(key, getInitialValue());
}

export const emailSentAtom = atomWithLocalStorage<boolean>("emailSent", false);
