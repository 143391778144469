import React from "react";
import { IconProps } from "./types";

const ExternalLinkIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                >
                    <path
                        d="M21.1477 14.75C21.5619 14.75 21.8977 14.4142 21.8977 14C21.8977 13.5858 21.5619 13.25 21.1477 13.25V14.75ZM26.3977 18.5C26.3977 18.0858 26.0619 17.75 25.6477 17.75C25.2335 17.75 24.8977 18.0858 24.8977 18.5H26.3977ZM14.6174 23.9697C14.3245 24.2626 14.3245 24.7374 14.6174 25.0303C14.9103 25.3232 15.3851 25.3232 15.678 25.0303L14.6174 23.9697ZM28.6477 11H29.3977C29.3977 10.8011 29.3187 10.6103 29.178 10.4697C29.0374 10.329 28.8466 10.25 28.6477 10.25V11ZM23.3977 10.25C22.9835 10.25 22.6477 10.5858 22.6477 11C22.6477 11.4142 22.9835 11.75 23.3977 11.75V10.25ZM27.8977 16.25C27.8977 16.6642 28.2335 17 28.6477 17C29.0619 17 29.3977 16.6642 29.3977 16.25H27.8977ZM23.3977 28.25H12.8977V29.75H23.3977V28.25ZM11.3977 26.75V16.25H9.89771V26.75H11.3977ZM12.8977 14.75H21.1477V13.25H12.8977V14.75ZM24.8977 18.5V26.75H26.3977V18.5H24.8977ZM12.8977 28.25C12.0693 28.25 11.3977 27.5784 11.3977 26.75H9.89771C9.89771 28.4069 11.2409 29.75 12.8977 29.75V28.25ZM23.3977 29.75C25.0546 29.75 26.3977 28.4069 26.3977 26.75H24.8977C24.8977 27.5784 24.2261 28.25 23.3977 28.25V29.75ZM11.3977 16.25C11.3977 15.4216 12.0693 14.75 12.8977 14.75V13.25C11.2409 13.25 9.89771 14.5931 9.89771 16.25H11.3977ZM15.678 25.0303L29.178 11.5303L28.1174 10.4697L14.6174 23.9697L15.678 25.0303ZM28.6477 10.25L23.3977 10.25V11.75L28.6477 11.75V10.25ZM27.8977 11V16.25H29.3977V11H27.8977Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ExternalLinkIcon;
