export enum USStates {
    Alabama = "AL",
    Alaska = "AK",
    AmericanSamoa = "AS",
    Arizona = "AZ",
    Arkansas = "AR",
    California = "CA",
    Colorado = "CO",
    Connecticut = "CT",
    Delaware = "DE",
    DistrictOfColumbia = "DC",
    FederatedStatesOfMicronesia = "FM",
    Florida = "FL",
    Georgia = "GA",
    Guam = "GU",
    Hawaii = "HI",
    Idaho = "ID",
    Illinois = "IL",
    Indiana = "IN",
    Iowa = "IA",
    Kansas = "KS",
    Kentucky = "KY",
    Louisiana = "LA",
    Maine = "ME",
    MarshallIslands = "MH",
    Maryland = "MD",
    Massachusetts = "MA",
    Michigan = "MI",
    Minnesota = "MN",
    Mississippi = "MS",
    Missouri = "MO",
    Montana = "MT",
    Nebraska = "NE",
    Nevada = "NV",
    NewHampshire = "NH",
    NewJersey = "NJ",
    NewMexico = "NM",
    NewYork = "NY",
    NorthCarolina = "NC",
    NorthDakota = "ND",
    NorthernMarianaIslands = "MP",
    Ohio = "OH",
    Oklahoma = "OK",
    Oregon = "OR",
    Palau = "PW",
    Pennsylvania = "PA",
    PuertoRico = "PR",
    RhodeIsland = "RI",
    SouthCarolina = "SC",
    SouthDakota = "SD",
    Tennessee = "TN",
    Texas = "TX",
    Utah = "UT",
    Vermont = "VT",
    VirginIslands = "VI",
    Virginia = "VA",
    Washington = "WA",
    WestVirginia = "WV",
    Wisconsin = "WI",
    Wyoming = "WY",
    OutsideTheUS = "..",
}

export const US_STATES = {
    [USStates.Alabama]: "Alabama",
    [USStates.Alaska]: "Alaska",
    [USStates.AmericanSamoa]: "American Samoa",
    [USStates.Arizona]: "Arizona",
    [USStates.Arkansas]: "Arkansas",
    [USStates.California]: "California",
    [USStates.Colorado]: "Colorado",
    [USStates.Connecticut]: "Connecticut",
    [USStates.Delaware]: "Delaware",
    [USStates.DistrictOfColumbia]: "District of Columbia",
    [USStates.FederatedStatesOfMicronesia]: "Federated States of Micronesia",
    [USStates.Florida]: "Florida",
    [USStates.Georgia]: "Georgia",
    [USStates.Guam]: "Guam",
    [USStates.Hawaii]: "Hawaii",
    [USStates.Idaho]: "Idaho",
    [USStates.Illinois]: "Illinois",
    [USStates.Indiana]: "Indiana",
    [USStates.Iowa]: "Iowa",
    [USStates.Kansas]: "Kansas",
    [USStates.Kentucky]: "Kentucky",
    [USStates.Louisiana]: "Louisiana",
    [USStates.Maine]: "Maine",
    [USStates.MarshallIslands]: "Marshall Islands",
    [USStates.Maryland]: "Maryland",
    [USStates.Massachusetts]: "Massachusetts",
    [USStates.Michigan]: "Michigan",
    [USStates.Minnesota]: "Minnesota",
    [USStates.Mississippi]: "Mississippi",
    [USStates.Missouri]: "Missouri",
    [USStates.Montana]: "Montana",
    [USStates.Nebraska]: "Nebraska",
    [USStates.Nevada]: "Nevada",
    [USStates.NewHampshire]: "New Hampshire",
    [USStates.NewJersey]: "New Jersey",
    [USStates.NewMexico]: "New Mexico",
    [USStates.NewYork]: "New York",
    [USStates.NorthCarolina]: "North Carolina",
    [USStates.NorthDakota]: "North Dakota",
    [USStates.NorthernMarianaIslands]: "Northern Mariana Islands",
    [USStates.Ohio]: "Ohio",
    [USStates.Oklahoma]: "Oklahoma",
    [USStates.Oregon]: "Oregon",
    [USStates.Palau]: "Palau",
    [USStates.Pennsylvania]: "Pennsylvania",
    [USStates.PuertoRico]: "Puerto Rico",
    [USStates.RhodeIsland]: "Rhode Island",
    [USStates.SouthCarolina]: "South Carolina",
    [USStates.SouthDakota]: "South Dakota",
    [USStates.Tennessee]: "Tennessee",
    [USStates.Texas]: "Texas",
    [USStates.Utah]: "Utah",
    [USStates.Vermont]: "Vermont",
    [USStates.VirginIslands]: "Virgin Islands",
    [USStates.Virginia]: "Virginia",
    [USStates.Washington]: "Washington",
    [USStates.WestVirginia]: "West Virginia",
    [USStates.Wisconsin]: "Wisconsin",
    [USStates.Wyoming]: "Wyoming",
};

export const SENSITIVE_COUNTRIES = [
    "AF", // Afghanistan
    "BY", // Belarus
    "BA", // Bosnia and Herzegovina
    "AL", // Albania
    "XK", // Kosovo
    "BG", // Bulgaria
    "CD", // Democratic Republic of the Congo
    "CF", // Central African Republic
    "CN", // China
    "CU", // Cuba
    "ET", // Ethiopia
    "HK", // Hong Kong
    "HR", // Croatia
    "IR", // Iran
    "IQ", // Iraq
    "LB", // Lebanon
    "LY", // Libya
    "ML", // Mali
    "MM", // Myanmar
    "MK", // North Macedonia
    "ME", // Montenegro
    "NI", // Nicaragua
    "KP", // North Korea
    "RU", // Russia
    "RO", // Romania
    "RS", // Serbia
    "SI", // Slovenia
    "SO", // Somalia
    "SD", // Sudan
    "SY", // Syria
    "VE", // Venezuela
    "YE", // Yemen
    "ZW", // Zimbabwe
];
