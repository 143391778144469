import React from "react";
import { IconProps } from "./types";

const BuildingIcon = ({
    className,
    width = "30",
    height = "30",
}: IconProps) => {
    return (
        <React.Suspense fallback={null}>
            <svg
                role="img"
                width={width}
                height={height}
                viewBox={`0 0 ${width} ${height}`}
                fill="none"
                className={className}
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M8.25 2.5C7.14543 2.5 6.25 3.39543 6.25 4.5V26.25H13.125V22.25C13.125 21.6977 13.5727 21.25 14.125 21.25H15.875C16.4273 21.25 16.875 21.6977 16.875 22.25V26.25H23.75V4.5C23.75 3.39543 22.8546 2.5 21.75 2.5H8.25ZM11 5C10.4477 5 10 5.44772 10 6V7.75C10 8.30228 10.4477 8.75 11 8.75H12.75C13.3023 8.75 13.75 8.30228 13.75 7.75V6C13.75 5.44772 13.3023 5 12.75 5H11ZM16.25 6C16.25 5.44772 16.6977 5 17.25 5H19C19.5523 5 20 5.44772 20 6V7.75C20 8.30228 19.5523 8.75 19 8.75H17.25C16.6977 8.75 16.25 8.30228 16.25 7.75V6ZM11 10C10.4477 10 10 10.4477 10 11V12.75C10 13.3023 10.4477 13.75 11 13.75H12.75C13.3023 13.75 13.75 13.3023 13.75 12.75V11C13.75 10.4477 13.3023 10 12.75 10H11ZM10 16C10 15.4477 10.4477 15 11 15H12.75C13.3023 15 13.75 15.4477 13.75 16V17.75C13.75 18.3023 13.3023 18.75 12.75 18.75H11C10.4477 18.75 10 18.3023 10 17.75V16ZM17.25 15C16.6977 15 16.25 15.4477 16.25 16V17.75C16.25 18.3023 16.6977 18.75 17.25 18.75H19C19.5523 18.75 20 18.3023 20 17.75V16C20 15.4477 19.5523 15 19 15H17.25ZM16.25 11C16.25 10.4477 16.6977 10 17.25 10H19C19.5523 10 20 10.4477 20 11V12.75C20 13.3023 19.5523 13.75 19 13.75H17.25C16.6977 13.75 16.25 13.3023 16.25 12.75V11Z"
                    fill="#29303D"
                />
                <path
                    d="M3.75 28.7351C3.75 27.3626 4.86261 26.25 6.23509 26.25H23.7649C25.1374 26.25 26.25 27.3626 26.25 28.7351C26.25 28.7433 26.2433 28.75 26.2351 28.75H3.76491C3.75668 28.75 3.75 28.7433 3.75 28.7351Z"
                    fill="#677283"
                />
            </svg>
        </React.Suspense>
    );
};

export default BuildingIcon;
