import React from "react";
import { IconProps } from "../types";

const FreshbooksIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_4569)">
                        <path
                            d="M14.6289 9.80273H28.1738V29.9644H14.6289V9.80273Z"
                            fill="white"
                        />
                        <path
                            d="M17.976 7C11.9042 7 7 11.9042 7 17.976V33H22.024C28.0958 33 33 28.0958 33 22.024V7H17.976ZM26.8503 11.5928C26.8503 13.6168 25.2156 15.1737 23.2695 15.1737H19.3772V18.2874H24.5928V21.8683H19.4551V29.3413H15.1737V10.6587H19.3772V14.006C19.5329 12.1377 21.0898 10.6587 22.9581 10.6587H26.8503V11.5928Z"
                            fill="#0075DD"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_4569">
                            <rect
                                width="26"
                                height="26"
                                fill="white"
                                transform="translate(7 7)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default FreshbooksIcon;
