import { ObjectSchema, ValidationError } from "yup";
import { ValidationState } from "./Form.types";
import { unflattenObject } from "./utils";

export function validateSchema(
    value: any,
    schema: ObjectSchema<any>,
): ValidationState {
    try {
        schema.validateSync(unflattenObject(value), { abortEarly: false });
    } catch (err) {
        if (err instanceof ValidationError) {
            return {
                isValid: false,
                errors: err.inner.reduce((acc, cur) => {
                    if (cur.path) acc[cur.path] = cur.message;
                    return acc;
                }, {}),
            };
        }
        // Handle unexpected errors
        console.error("Unexpected error:", err);
    }

    return { isValid: true, errors: null };
}
