export enum NAICSCode {
    AgricultureForestry = "agriculture_forestry_fishing_and_hunting",
    Mining = "mining",
    Utilities = "utilities",
    Construction = "construction",
    Manufacturing = "manufacturing",
    WholesaleTrade = "wholesale_trade",
    RetailTrade = "retail_trade",
    TransportationWarehousing = "transportation_warehousing",
    Information = "information",
    FinanceInsurance = "finance_insurance",
    RealEstateRentalAndLeasing = "real_estate_rental_and_leasing",
    ProfessionalScientificTechnicalServices = "professional_scientific_technical_services",
    ManagementCompaniesAndEnterprises = "management_companies_and_enterprises",
    AdministrativeWasteManagementServices = "administrative_waste_management_services",
    EducationalServices = "educational_services",
    HealthCareAndSocialAssistance = "health_care_and_social_assistance",
    ArtsEntertainmentRecreation = "arts_entertainment_recreation",
    AccommodationFoodServices = "accommodation_food_services",
    OtherServices = "other_services",
    PublicAdministration = "public_administration",
}

export const NAICS_INDUSTRY_GROUP_OPTIONS = {
    [NAICSCode.AgricultureForestry]:
        "Agriculture, Forestry, Fishing and Hunting",
    [NAICSCode.Mining]: "Mining",
    [NAICSCode.Utilities]: "Utilities",
    [NAICSCode.Construction]: "Construction",
    [NAICSCode.Manufacturing]: "Manufacturing",
    [NAICSCode.WholesaleTrade]: "Wholesale Trade",
    [NAICSCode.RetailTrade]: "Retail Trade",
    [NAICSCode.TransportationWarehousing]: "Transportation and Warehousing",
    [NAICSCode.Information]: "Information",
    [NAICSCode.FinanceInsurance]: "Finance and Insurance",
    [NAICSCode.RealEstateRentalAndLeasing]: "Real Estate Rental and Leasing",
    [NAICSCode.ProfessionalScientificTechnicalServices]:
        "Professional, Scientific, and Technical Services",
    [NAICSCode.ManagementCompaniesAndEnterprises]:
        "Management of Companies and Enterprises",
    [NAICSCode.AdministrativeWasteManagementServices]:
        "Administrative and Waste Management Services",
    [NAICSCode.EducationalServices]: "Educational Services",
    [NAICSCode.HealthCareAndSocialAssistance]:
        "Health Care and Social Assistance",
    [NAICSCode.ArtsEntertainmentRecreation]:
        "Arts, Entertainment, and Recreation",
    [NAICSCode.AccommodationFoodServices]: "Accommodation and Food Services",
    [NAICSCode.OtherServices]: "Other Services",
    [NAICSCode.PublicAdministration]: "Public Administration",
};
