import React from "react";
import { IconProps } from "./types";

const StarIcon = ({
    className,
    width = "18",
    height = "16",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M9.86804 0.622956C9.54694 -0.149059 8.45329 -0.149059 8.13219 0.622956L6.3014 5.02472L1.54932 5.4057C0.715863 5.47251 0.377908 6.51263 1.01291 7.05658L4.63349 10.158L3.52735 14.7952C3.33334 15.6085 4.21812 16.2514 4.93167 15.8155L9.00011 13.3305L13.0686 15.8155C13.7821 16.2514 14.6669 15.6085 14.4729 14.7952L13.3667 10.158L16.9873 7.05658C17.6223 6.51263 17.2844 5.47251 16.4509 5.4057L11.6988 5.02472L9.86804 0.622956Z"
                        fill="#1D8427"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default StarIcon;
