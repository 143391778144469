export function abbreviate(s: string): string {
    let num = parseInt(s, 10);
    const isNegative = num < 0;
    const prefix = isNegative ? "-" : "";
    num = Math.abs(num);
    if (num >= 1_000_000_000_000) {
        return (
            prefix +
            (num / 1_000_000_000_000).toFixed(0).replace(/\.0$/, "") +
            "T"
        );
    } else if (num >= 1_000_000_000) {
        return (
            prefix + (num / 1_000_000_000).toFixed(0).replace(/\.0$/, "") + "B"
        );
    } else if (num >= 1_000_000) {
        return prefix + (num / 1_000_000).toFixed(0).replace(/\.0$/, "") + "M";
    } else if (num >= 1_000) {
        return (
            prefix +
            (num / 1_000).toFixed(num > 100_000 ? 0 : 1).replace(/\.0$/, "") +
            "k"
        );
    } else {
        return prefix + num.toString();
    }
}
