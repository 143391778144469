import { QueryClientProvider } from "@tanstack/react-query";
import { Route, Routes } from "react-router-dom";
import { queryClient } from "@/api";
import { ToastProvider } from "@/ui";
import {
    CompanyProfilePage,
    LoginErrorPage,
    LoginPage,
    LogoutPage,
    PostSignupPage,
    SignupCompletePage,
    SignupPage,
    VerifyEmailPage,
} from "./pages";
import { SessionController } from "./SessionController";
import { datadogRum } from "@datadog/browser-rum";
import flagsmith from "flagsmith";
import { useEffect } from "react";
import { FlagsmithProvider } from "flagsmith/react";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Provider } from "jotai";
import { Auth0Provider } from "@auth0/auth0-react";
import { V1RedirectWithPath } from "@/utils";

const flagsmithOptions = {
    environmentID: process.env.FLAGSMITH_CLIENT_SIDE_ENVIRONMENT_KEY || "",
    api: process.env.FLAGSMITH_API_URL || "",
    datadogRum: {
        client: datadogRum,
        trackTraits: true,
    },
};

export function RootContainer() {
    useEffect(() => {
        if (process.env.NODE_ENV === "staging") {
            // @ts-expect-error This is expected to be a production build
            window.getQueryClient = () => queryClient;
        }
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <Provider>
                <FlagsmithProvider
                    options={flagsmithOptions}
                    flagsmith={flagsmith}
                >
                    <Auth0Provider
                        domain={process.env.AUTH0_DOMAIN || ""}
                        clientId={process.env.AUTH0_CLIENT_ID || ""}
                        authorizationParams={{
                            audience: process.env.AUTH0_AUDIENCE || "",
                        }}
                    >
                        <Routes>
                            <Route path="/login" element={<LoginPage />} />
                            <Route
                                path="/raise-or-invest"
                                element={<SignupPage />}
                            />
                            <Route
                                path="/post-signup/:role"
                                element={<PostSignupPage />}
                            />
                            <Route
                                path="/signup-complete"
                                element={<SignupCompletePage />}
                            />
                            <Route
                                path="/verify-email"
                                element={<VerifyEmailPage />}
                            />
                            <Route path="/error" element={<LoginErrorPage />} />
                            <Route path="/logout" element={<LogoutPage />} />
                            <Route
                                path="/public/:token"
                                element={<CompanyProfilePage />}
                            />
                            <Route
                                path="/legacy"
                                element={<V1RedirectWithPath />}
                            />
                            <Route path="*" element={<SessionController />} />
                        </Routes>
                    </Auth0Provider>
                    <ToastProvider />
                </FlagsmithProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </Provider>
        </QueryClientProvider>
    );
}
