export type FlagValue = string | number | Record<string, any> | null;

export type IUseFlags = {
    get: (
        flagNames: string[],
        defaults?: Record<string, boolean>,
    ) => Record<string, boolean>;
    values: (
        flagNames: string[],
        defaults?: Record<string, FlagValue>,
    ) => Record<string, FlagValue>;
    enabled: (
        flagNames: string | string[],
        defaults?: Record<string, boolean>,
    ) => boolean;
    value: (flagName: string, def?: FlagValue) => FlagValue;
    ready: boolean;
    error: boolean;
    loading: boolean;
};

export interface IFlagObject {
    enabled: boolean;
    feature_state_value: string | number | boolean | null;
    feature: {
        id: number;
        name: string;
        type: string;
    };
}
