import React from "react";
import { IconProps } from "../types";

const SageIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_4132)">
                        <path
                            d="M30.3668 16C28.207 16 26.7979 17.4789 26.7979 19.9239C26.7979 22.9605 28.9563 23.828 30.5048 23.828C31.6682 23.828 32.7724 23.3548 33.5703 22.5161L32.5752 21.521C32.0428 21.9943 31.4316 22.2808 30.5246 22.2808C29.3218 22.2808 28.5133 21.5815 28.5133 20.614H33.8766C33.8661 20.6048 34.8034 16 30.3668 16ZM28.5133 19.2929C28.5133 17.9915 29.4992 17.3711 30.5535 17.3711C31.6077 17.3711 32.5647 18.0112 32.5647 19.2929H28.5133Z"
                            fill="#00DC06"
                        />
                        <path
                            d="M10.6189 19.1654C9.92877 19.0773 7.94645 19.1562 7.94645 18.2492C7.94645 17.7168 8.78381 17.421 9.54361 17.421C10.352 17.4105 11.1408 17.6379 11.8112 18.0717L12.8457 17.0464C12.5421 16.7992 11.4076 16 9.54492 16C7.84786 16 6.32037 16.8873 6.32037 18.307C6.32037 20.4852 8.95338 20.4852 10.0471 20.5943C10.7175 20.664 11.3182 20.7034 11.3182 21.3541C11.3182 21.9654 10.3915 22.3006 9.67243 22.3006C8.49855 22.3006 7.79922 21.9154 7.10909 21.2949L6.00488 22.4084C6.96186 23.3351 8.25274 23.8373 9.58436 23.828C11.5562 23.828 12.9864 22.9605 12.9864 21.2148C12.9864 20.0606 12.2069 19.3718 10.6202 19.1641"
                            fill="#00DC06"
                        />
                        <path
                            d="M16.2776 16.0002C15.1839 15.9897 14.1086 16.3643 13.2515 17.0453L14.2873 18.0811C14.8697 17.6473 15.569 17.4212 16.2881 17.4304C17.6 17.4304 17.9943 17.9523 17.9943 18.5452V19.2839H15.6571C14.7895 19.2839 13.0938 19.5008 13.0938 21.4043C13.0938 22.8042 13.8917 23.8296 15.8056 23.8296C16.7626 23.8296 17.4922 23.5246 17.9943 22.9225V23.6824H19.6901V18.6621C19.6888 16.9072 18.6148 16.0002 16.2776 16.0002ZM18.0022 20.9389C18.0022 22.3875 16.9664 22.3087 16.0501 22.3087C15.1339 22.3087 14.5226 22.0628 14.5226 21.4108C14.5226 20.8101 15.0064 20.6024 16.012 20.6024H18.0035V20.9376L18.0022 20.9389Z"
                            fill="#00DC06"
                        />
                        <path
                            d="M24.9245 16.1577V16.9964C24.3422 16.3352 23.6521 16 22.7161 16C21.1084 16 20.2803 16.8978 20.0042 17.864C19.8951 18.2189 19.8662 18.8999 19.8662 19.9055C19.8662 20.8519 19.8767 21.7195 20.2014 22.3794C20.6641 23.3062 21.7789 23.828 22.7148 23.828C23.6508 23.828 24.3908 23.4731 24.9232 22.8619V23.3351C24.9232 23.8872 24.7352 24.2513 24.3816 24.5379C24.0267 24.8231 23.5837 25.0019 22.9619 25.0019C22.3993 25.0019 21.8682 24.697 21.562 24.4801L20.4183 25.6237C21.1571 26.2244 22.0352 26.6293 22.9909 26.6385C23.9071 26.6582 24.8049 26.3625 25.524 25.7814C26.2036 25.2491 26.6387 24.2329 26.6387 23.1682V16.1577H24.9232H24.9245ZM24.9245 19.9042C24.9245 20.4852 24.914 20.919 24.8654 21.1464C24.7563 21.6301 24.2042 22.2703 23.1999 22.2703C22.9435 22.2703 21.6619 22.1218 21.5133 21.0084C21.4739 20.6442 21.4542 20.2696 21.4634 19.9042C21.4634 19.0366 21.5028 18.7605 21.5422 18.5436C21.6303 18.1098 22.2035 17.4591 23.1986 17.4591C24.4119 17.4591 24.8062 18.2873 24.8641 18.6133C24.914 19.0379 24.9324 19.4704 24.9232 19.9042"
                            fill="#00DC06"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_4132">
                            <rect
                                width="27.947"
                                height="10.6385"
                                fill="white"
                                transform="translate(6.00488 16)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default SageIcon;
