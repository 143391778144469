import React, { useRef, Fragment } from "react";
import { Popover, Transition } from "@headlessui/react";
import clsx from "clsx";

export enum TooltipSize {
    XS = "w-[80px]",
    SM = "w-[120px]",
    MD = "w-[160px]",
    LG = "w-[220px]",
    XL = "w-[550px]",
}

type TooltipProps = {
    testId?: string;
    content: string;
    children: React.ReactNode;
    size?: TooltipSize;
    position?: "top" | "bottom";
};

export function Tooltip({
    testId,
    content,
    size,
    children,
    position,
}: TooltipProps) {
    const buttonRef = useRef<HTMLButtonElement>(null);
    const timeoutDuration = 200;
    let timeout;

    const closePopover = () => {
        return buttonRef.current?.dispatchEvent(
            new KeyboardEvent("keydown", {
                key: "Escape",
                bubbles: true,
                cancelable: true,
            }),
        );
    };

    const onMouseEnter = (open) => {
        clearTimeout(timeout);
        if (open) return;
        return buttonRef.current?.click();
    };
    const onMouseLeave = (open) => {
        if (!open) return;
        timeout = setTimeout(() => closePopover(), timeoutDuration);
    };

    return (
        <div data-testid={testId}>
            <Popover className="relative">
                {({ open }) => (
                    <div>
                        <Popover.Button
                            as="div"
                            className="outline-0"
                            ref={buttonRef}
                            onMouseEnter={onMouseEnter.bind(null, open)}
                            onMouseLeave={onMouseLeave.bind(null, open)}
                        >
                            {children}
                        </Popover.Button>
                        <Transition
                            as={Fragment}
                            enter="transition ease-out duration-500"
                            enterFrom="opacity-0 translate-y-1"
                            enterTo="opacity-100 translate-y-0"
                            leave="transition ease-in duration-450"
                            leaveFrom="opacity-100 translate-y-0"
                            leaveTo="opacity-0 translate-y-1"
                        >
                            <Popover.Panel
                                className={clsx(
                                    position === "top"
                                        ? "top-[-40px]"
                                        : "bottom-[-10px]",
                                    "shadow-boxShadow rounded-cta absolute z-10 min-w-[128px] bg-white px-4 py-2",
                                    size,
                                )}
                            >
                                <p className="body-s text-center text-black">
                                    {content}
                                </p>
                            </Popover.Panel>
                        </Transition>
                    </div>
                )}
            </Popover>
        </div>
    );
}
