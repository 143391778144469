import clsx from "clsx";
import { Card, Spinner } from "../../atoms";

export type Detail = {
    label: string;
    value?: string | number;
    isLoading?: boolean;
};

type DetailCardProps = {
    details: Detail[];
    title?: string;
    children?: React.ReactNode;
    testId?: string;
    className?: string;
    transparent?: boolean;
};

export function DetailCard({
    title,
    details,
    children,
    testId,
    className,
    transparent = false,
}: DetailCardProps) {
    return (
        <Card
            className={clsx(
                "flex flex-col md:flex-row",
                "h-full p-8 md:min-h-44",
                "border-default border",
                transparent && "bg-transparent",
                className,
            )}
            testId={testId}
        >
            {title && (
                <div
                    className={clsx(
                        "w-full md:w-[136px] md:max-w-[136px]",
                        "max-md:pb-3 md:pr-8",
                        "box-content flex items-center",
                        "border-default max-md:border-b md:border-r",
                    )}
                >
                    <h4>{title}</h4>
                </div>
            )}

            <div
                className={clsx(
                    "grid grid-cols-2",
                    "flex-1 gap-4",
                    "max-md:mt-4 md:px-8",
                )}
            >
                {details.map((detail) => (
                    <div
                        key={detail.label}
                        className="col-span-2 md:col-span-1"
                    >
                        <p className="body-micro subdued">{detail.label}</p>
                        {!detail.isLoading && (
                            <p className="body-s">{detail.value}</p>
                        )}
                        {detail.isLoading && <Spinner />}
                    </div>
                ))}
            </div>

            {children && (
                <div
                    className={clsx(
                        "flex flex-col",
                        "gap-5 gap-y-3",
                        "items-center justify-center",
                        "max-md:mt-4",
                    )}
                >
                    {children}
                </div>
            )}
        </Card>
    );
}
