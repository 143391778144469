import React from "react";
import { IconProps } from "./types";

const CircleUserIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#92ABFA" />
                    <path
                        d="M25.6689 24.25C27.3258 24.25 28.6689 22.9069 28.6689 21.25C28.6689 19.5931 27.3258 18.25 25.6689 18.25C24.0121 18.25 22.6689 19.5931 22.6689 21.25C22.6689 22.9069 24.0121 24.25 25.6689 24.25Z"
                        fill="#2A313F"
                    />
                    <path
                        d="M19.1341 30.7435C18.9392 31.2516 19.1133 31.821 19.5431 32.1546C21.2355 33.468 23.361 34.25 25.6691 34.25C27.9795 34.25 30.107 33.4664 31.8002 32.1506C32.2298 31.8167 32.4035 31.2471 32.2083 30.7392C31.199 28.1135 28.6531 26.25 25.672 26.25C22.6893 26.25 20.1422 28.1156 19.1341 30.7435Z"
                        fill="#2A313F"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleUserIcon;
