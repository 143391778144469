import clsx from "clsx";
import ReactMarkdown from "react-markdown";

type MarkdownProps = {
    testId?: string;
    className?: string;
    children: string;
};

export function Markdown({ testId, className, children }: MarkdownProps) {
    return (
        <ReactMarkdown
            className={clsx(
                className,
                "text-defaultTextColor",
                "font-body",
                "font-bodyWeight",
                "text-bodyMSize",
                "leading-bodyM",
                "tracking-body",
                "cursor-default",
            )}
            data-testid={testId}
        >
            {children}
        </ReactMarkdown>
    );
}
