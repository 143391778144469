import clsx from "clsx";
import { AnimatePresence, motion } from "framer-motion";

export const Layout = ({
    children,
    centered,
}: {
    children: React.ReactNode;
    centered?: boolean;
}) => {
    return (
        <AnimatePresence>
            <motion.main
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                className={clsx(
                    "flex h-screen flex-col items-center lg:flex-row",
                    "gap-5 lg:gap-[100px] xl:gap-[120px]",
                    !centered &&
                        "px-10 pt-20 lg:px-[100px] xl:px-[180px] 2xl:px-[300px]",
                    !centered &&
                        "first:flex first:w-full first:flex-col first:gap-3",
                    !centered && "lg:first:w-[400px]",
                    !centered && "last:w-full lg:last:max-w-[500px]",
                    centered && "justify-center px-10 lg:px-0",
                )}
            >
                {children}
            </motion.main>
        </AnimatePresence>
    );
};
