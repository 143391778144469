import clsx from "clsx";
import React from "react";

export function Card({
    children,
    className,
    testId,
    onClick,
}: {
    children?: React.ReactNode;
    className?: string;
    testId?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}) {
    return (
        <div
            data-testid={testId}
            className={clsx("bg-secondary", "", "p-6", "rounded-lg", className)}
            onClick={onClick}
        >
            {children}
        </div>
    );
}
