import React from "react";
import { IconProps } from "./types";

const PdfFileIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={"0 0 50 50"}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.1786 2.5H28.6029C28.9519 2.5 29.29 2.62168 29.5589 2.8441L41.456 12.6834C41.8005 12.9684 42 13.3922 42 13.8393V42.2833C42 46.2225 41.954 46.5 37.8214 46.5H10.1786C6.04601 46.5 6 46.2225 6 42.2833V6.71667C6 2.77747 6.04601 2.5 10.1786 2.5Z"
                        fill="#2A313F"
                    />
                    <path
                        d="M10.2155 32.5C10.0965 32.5 10 32.4035 10 32.2845V22.7155C10 22.5965 10.0965 22.5 10.2155 22.5H14.3275C15.1594 22.5 15.8681 22.6449 16.4537 22.9346C17.0393 23.221 17.4856 23.6198 17.7927 24.1309C18.1033 24.6387 18.2586 25.2246 18.2586 25.8887C18.2586 26.5527 18.1015 27.1387 17.7873 27.6465C17.4731 28.1543 17.0179 28.5498 16.4216 28.833C15.8289 29.1162 15.1112 29.2578 14.2686 29.2578H11.5103V27.5635H13.8936C14.34 27.5635 14.7077 27.4935 14.9969 27.3535C15.2897 27.2103 15.5075 27.0133 15.6503 26.7627C15.7967 26.5088 15.8699 26.2174 15.8699 25.8887C15.8699 25.5566 15.7967 25.2669 15.6503 25.0195C15.5075 24.7689 15.2897 24.5752 14.9969 24.4385C14.7042 24.2985 14.3328 24.2285 13.8829 24.2285H12.3191V32.2845C12.3191 32.4035 12.2226 32.5 12.1036 32.5H10.2155Z"
                        fill="#788598"
                    />
                    <path
                        d="M23.6532 32.5H19.9804C19.8614 32.5 19.7649 32.4035 19.7649 32.2845V22.7155C19.7649 22.5965 19.8614 22.5 19.9804 22.5H23.6853C24.7886 22.5 25.7384 22.7002 26.5346 23.1006C27.3308 23.4977 27.9432 24.069 28.3717 24.8145C28.8037 25.5599 29.0197 26.4518 29.0197 27.4902C29.0197 28.5319 28.8037 29.4271 28.3717 30.1758C27.9432 30.9245 27.3273 31.499 26.5239 31.8994C25.7241 32.2998 24.7672 32.5 23.6532 32.5ZM22.084 30.6885H23.5568C24.2423 30.6885 24.819 30.5778 25.2867 30.3564C25.758 30.1318 26.1115 29.7852 26.3472 29.3164C26.5864 28.8444 26.706 28.2357 26.706 27.4902C26.706 26.7513 26.5864 26.1475 26.3472 25.6787C26.1115 25.21 25.7598 24.8649 25.2921 24.6436C24.8243 24.4222 24.2477 24.3115 23.5622 24.3115H22.084V30.6885Z"
                        fill="#788598"
                    />
                    <path
                        d="M30.953 32.5C30.834 32.5 30.7376 32.4035 30.7376 32.2845V22.7155C30.7376 22.5965 30.834 22.5 30.953 22.5H37.7845C37.9035 22.5 38 22.5965 38 22.7155V24.0277C38 24.1467 37.9035 24.2432 37.7845 24.2432H33.0566V26.626H37.3025C37.4215 26.626 37.518 26.7224 37.518 26.8414V28.1537C37.518 28.2727 37.4215 28.3691 37.3025 28.3691H33.0566V32.2845C33.0566 32.4035 32.9602 32.5 32.8412 32.5H30.953Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default PdfFileIcon;
