import clsx from "clsx";
import React from "react";

export function SectionCard({
    children,
    className,
    testId,
}: {
    children?: React.ReactNode;
    className?: string;
    testId?: string;
}) {
    return (
        <div
            className={clsx(
                "bg-surface-dark",
                "border-default border",
                "p-8",
                className,
            )}
            data-testid={testId}
        >
            {children}
        </div>
    );
}
