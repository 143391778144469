import clsx from "clsx";
import { twMerge } from "tailwind-merge";

type SearchResultsBodyProps = {
    testId?: string;
    className?: string;
    children: React.ReactNode;
};

export function SearchResultsBody({
    testId,
    className,
    children,
}: SearchResultsBodyProps) {
    return (
        <div
            className={clsx(
                twMerge(
                    `custom-scrollbar bg-onSurface-active shadow-400 h-[300px] overflow-y-scroll rounded-lg
                p-2`,
                    className,
                ),
            )}
            data-testid={testId}
        >
            {children}
        </div>
    );
}
