import { ArrowTopRightOnSquareIcon } from "@heroicons/react/24/outline";
import { useParentSize } from "@visx/responsive";
import { clsx } from "clsx";
import { useState } from "react";

export function ChartSnapshotContainer({
    title,
    children,
    statKey,
    statValue,
    onClick,
}: {
    title: string;
    statKey: string;
    statValue: string;
    children: React.FC<{
        width: number;
        height: number;
    }>;
    onClick: () => void;
}) {
    const {
        parentRef: containerRef,
        width: containerWidth,
        height: containerHeight,
    } = useParentSize({ debounceTime: 15 });
    const { parentRef: headerRef, height: headerHeight } = useParentSize({
        ignoreDimensions: ["width"],
    });
    const containerPadding = 32;
    const width = containerWidth - 16;
    const height = containerHeight - headerHeight - containerPadding;
    const [isLinkHovered, setLinkHovered] = useState<boolean>(false);

    return (
        <div className={clsx("flex h-full flex-col")}>
            <div
                className={clsx(
                    "bg-secondary p-6",
                    "flex h-full flex-col text-clip rounded",
                )}
                ref={containerRef}
            >
                <div className="header mb-4" ref={headerRef}>
                    <div className="flex items-start justify-between">
                        <h1 className="heading-m mb-4">{title}</h1>
                        <a
                            onMouseOver={() => setLinkHovered(true)}
                            onMouseLeave={() => setLinkHovered(false)}
                            className="stroke-brand-300 hover:stroke-brand-400"
                            onClick={onClick}
                        >
                            View all analytics{" "}
                            <ArrowTopRightOnSquareIcon
                                width={16}
                                height={16}
                                className={clsx(
                                    isLinkHovered
                                        ? "stroke-brand-400"
                                        : "stroke-brand-300",
                                )}
                            />
                        </a>
                    </div>
                    <p className="subheading">{statKey}</p>
                    <p className="display-m">{statValue}</p>
                </div>
                <div className="flex shrink grow items-center">
                    {children({ width, height })}
                </div>
            </div>
        </div>
    );
}
