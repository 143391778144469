import clsx from "clsx";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";

type NativeTextAreaProps = React.DetailedHTMLProps<
    React.TextareaHTMLAttributes<HTMLTextAreaElement>,
    HTMLTextAreaElement
>;

export function RawTextArea({
    name,
    label,
    errorMessage,
    showErrorRing,
    required,
    placeholder,
    ...props
}: {
    name: string;
    label: string;
    errorMessage?: string;
    showErrorRing?: boolean;
    placeholder?: string;
} & Omit<NativeTextAreaProps, "name" | "label">) {
    const describedBy = props["aria-describedby"] || `${name}-error`;

    return (
        <div className="mb-3 flex flex-col">
            <Label name={name} label={label} required={required} />
            <textarea
                id={name}
                data-testid={`textarea-${name}`}
                name={name}
                placeholder={placeholder}
                className={clsx(
                    "mb-1 mt-2 w-full bg-slate-900 p-2 pl-3 text-white",
                    "border-default rounded border outline-none",
                    "placeholder:text-slate-500",
                    "focus:ring-transparent focus:placeholder:text-transparent",
                    (errorMessage || showErrorRing) && "ring-1 ring-red-600",
                )}
                aria-required={required}
                required={required}
                {...props}
            />
            <div className="h-6">
                {errorMessage && (
                    <ErrorMessage id={describedBy} message={errorMessage} />
                )}
            </div>
        </div>
    );
}
