import { Card, Section, Checkbox, TextArea } from "@/ui";
import { DownloadsSection } from "@/entities/CompanyProfile";

export type DownloadsProps = {
    inputs: DownloadsSection;
    onChange: (data: any) => void;
};

export const DownloadsForm = () => {
    return (
        <Section title="Downloads">
            <TextArea
                parent="downloads"
                label="Downloads Description"
                name="description"
            />
            <Card>
                <p className="ui-element subdued">
                    Hum Standardized Data & Chartbook
                </p>
            </Card>
            <Checkbox
                parent="downloads"
                label="Do not show any files/folders/chartbooks."
                name="isVisible"
            />
        </Section>
    );
};
