import { ChevronRightIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Link } from "react-router-dom";

type Page = {
    name: string;
    href?: string;
};

export function Breadcrumbs({
    pages,
    className,
}: {
    pages: Page[];
    className?: string;
}) {
    return (
        <nav className={clsx("flex", className)} aria-label="Breadcrumb">
            <ol role="list" className="flex items-center space-x-4">
                {pages.map((page, i) => (
                    <li key={page.name}>
                        <div className="flex items-center">
                            {i > 0 && (
                                <ChevronRightIcon
                                    className="size-5 shrink-0 text-gray-400"
                                    aria-hidden="true"
                                />
                            )}
                            {page.href && (
                                <Link
                                    to={page.href}
                                    className={clsx("link", i < 0 && "ml-4")}
                                    aria-current={"page"}
                                >
                                    {page.name}
                                </Link>
                            )}
                            {!page.href && (
                                <p className="ui-element subdued ml-4">
                                    {page.name}
                                </p>
                            )}
                        </div>
                    </li>
                ))}
            </ol>
        </nav>
    );
}
