import type {
    User as Auth0User,
    Auth0ContextInterface,
} from "@auth0/auth0-react";
import { UserRole } from "../User/User.model";
import { createApiClient } from "@/api/utils/createApiClient";
import { handleErrors } from "@/api/utils/createApiClient/errorHandlers";

// Unique query definitions, only queries
// that uses react query directly and does not
// get wrapped with createQuery. All other queries
// are wrapped with createQuery and are passed the session user object.

export function getAccessToken(auth0Session: Auth0ContextInterface<Auth0User>) {
    return {
        queryKey: ["auth0/getAccessTokenSilently"],
        queryFn: async () => {
            return await auth0Session.getAccessTokenSilently();
        },
    };
}

export function getIdentitySessionQuery(token?: string) {
    return {
        queryKey: ["/session"],
        queryFn: async () => {
            if (!token) throw new Error("No token");
            const api = await createApiClient({ token });
            api.interceptors.response.use(undefined, (error) => {
                if (error.response?.status === 401) {
                    window.location.assign(`${window.location.origin}/logout`);
                } else {
                    handleErrors(error);
                }
            });

            try {
                const { embedded } = await api.get(`/identity/session`);

                return {
                    ...embedded.user,
                    isAdmin: embedded.user.userRole === UserRole.ADMIN,
                    isFund: embedded.user.userRole === UserRole.FUND,
                    isCompany: embedded.user.userRole === UserRole.COMPANY,
                };
            } catch (e) {
                console.error("Error in getIdentitySessionQuery:", e);
                throw e;
            }
        },
    };
}
