import { createQuery } from "@/api/utils";
import { isoDateFormat } from "@/utils";
import {
    PrivateCompanyProfile,
    PublicCompanyProfile,
} from "./CompanyProfile.types";
import { BulkDownloadStatus } from "@/entities/Company";
import { HIGHLIGHT_LABELS } from "./CompanyProfile.constants";

export const getPublicProfile = createQuery<PublicCompanyProfile>(
    ({ api, router }) =>
        api
            .get("/backend/v1/companies/public_teaser_profile", {
                headers: { Authorization: `Bearer ${router.params.token}` },
            })
            .then((res) => {
                const month = res.financialHighlights.lastUpdated.split(" ")[0];
                const year = res.financialHighlights.lastUpdated.split(" ")[1];
                const isoLastUpdated = isoDateFormat(month, year);

                return {
                    ...res,
                    financialHighlights: {
                        ...res.financialHighlights,
                        month: month,
                        year: year,
                        lastUpdated: isoLastUpdated,
                        highlights: res.financialHighlights.highlights.map(
                            (highlight) => ({
                                ...highlight,
                                title: HIGHLIGHT_LABELS[highlight.title]
                                    ? HIGHLIGHT_LABELS[highlight.title]
                                    : highlight.title,
                            }),
                        ),
                    },
                    downloads: {
                        isVisible: res.showFilesSection,
                        description: res.filesSectionDescription,
                    },
                };
            }),
);

export const getPrivateProfile = createQuery<PrivateCompanyProfile>(
    ({ api, session, router, params }) => {
        const { user } = session;
        const isCompanyUser = user?.isCompany;

        const companyId = isCompanyUser
            ? user.organizationId
            : router.params.companyId ?? params.companyId;
        const apiVersion = isCompanyUser ? "v2" : "v1";
        const path = `/backend/${apiVersion}/companies/${companyId}/teaser_profile`;

        return api.get(path).then((res) => {
            const month = res.financialHighlights.lastUpdated.split(" ")[0];
            const year = res.financialHighlights.lastUpdated.split(" ")[1];
            const isoLastUpdated = isoDateFormat(month, year);

            return {
                ...res,
                hero: {
                    ...res.hero,
                    privateDescription: res.hero.privateDescription || "",
                    publicDescription: res.hero.publicDescription || "",
                },
                financialHighlights: {
                    ...res.financialHighlights,
                    description: res.financialHighlights.description || "",
                    month: month,
                    year: year,
                    lastUpdated: isoLastUpdated,
                    highlights: res.financialHighlights.highlights.map(
                        (highlight) => ({
                            ...highlight,
                            title: HIGHLIGHT_LABELS[highlight.title]
                                ? HIGHLIGHT_LABELS[highlight.title]
                                : highlight.title,
                        }),
                    ),
                },
                downloads: {
                    isVisible: res.showFilesSection,
                    description: res.filesSectionDescription || "",
                },
            };
        });
    },
);

export const getProfileDownloadStatus = createQuery<
    { fileUrl?: string; state: BulkDownloadStatus },
    { taskId: string }
>(({ api, params }) =>
    api.get(`/backend/v1/public_analytics_download_tasks/${params.taskId}`),
);
