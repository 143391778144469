import clsx from "clsx";

export const MobileStep = ({ active }: { active?: boolean }) => {
    return (
        <div
            className={clsx(
                active ? "w-3/5 bg-brand-50" : "w-1/5 bg-slate-600",
                "rounded py-1 transition-all ease-in-out",
            )}
        ></div>
    );
};

export const MobileSteps = ({ children }: { children: React.ReactNode }) => {
    return (
        <div className={clsx("mb-5 mt-3 w-full", "flex gap-4", "lg:hidden")}>
            {children}
        </div>
    );
};
