import clsx from "clsx";
import { Icon } from "../../atoms/Icon";
import { ProgressBar } from "../../atoms";

type DownloadFinancialsBarProps = {
    name?: string;
    testId?: string;
    className?: string;
    onClickDownload: () => void;
    isDownloadingFile?: boolean;
};

export function DownloadFinancialsBar({
    name,
    testId,
    className,
    onClickDownload,
    isDownloadingFile,
}: DownloadFinancialsBarProps) {
    return (
        <div
            className={clsx(
                "block",
                "my-8",
                "box-border",
                "rounded-default",
                "p-5",
                "bg-gray-700",
                "cursor-pointer",
                "shadow-default",
                className,
            )}
        >
            <div
                className="flex items-center justify-between"
                onClick={onClickDownload}
                data-testid={testId}
            >
                <div className="flex items-center">
                    <Icon.CsvFile />
                    <p className="subdued body-m ml-2">
                        {name
                            ? `${name} Company Financials`
                            : "Standardized financials and chartbook"}
                    </p>
                </div>
                <div className="flex items-center justify-center">
                    <Icon.Download />
                </div>
            </div>
            {isDownloadingFile && <ProgressBar />}
        </div>
    );
}
