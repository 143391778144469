import { debounce } from "lodash";
import { useCallback, useEffect } from "react";

export const useDebounce = (callback, delay) => {
    const debouncedCallback = useCallback(debounce(callback, delay), [
        callback,
        delay,
    ]);

    useEffect(() => {
        return () => {
            debouncedCallback.cancel();
        };
    }, [debouncedCallback]);

    return debouncedCallback;
};
