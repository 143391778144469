import { Toggle } from "@/ui";

export function PrivacyToggle({
    onChange,
    isPrivate,
}: {
    onChange: () => void;
    isPrivate: boolean;
}) {
    return (
        <div className="flex items-center">
            <p className="text-button mr-2">Public View</p>
            <Toggle
                checked={isPrivate}
                onChange={onChange}
                testId="privacy-toggle"
            />
            <p className="text-button ml-2">Private View</p>
        </div>
    );
}
