import clsx from "clsx";

export function MaintenanceBanner({
    message = "We are planning scheduled maintenance at 10PM EST. We expect it to last ~1 hour.",
    title = "Scheduled Maintenance",
}: {
    message: string;
    title: string;
}) {
    return (
        <div
            className={clsx(
                "bg-cta-primary-default z-[1000] w-full px-6 py-2.5",
                "sticky top-0 flex items-center justify-between gap-x-6",
                "sm:pr-3.5 lg:pl-8",
            )}
        >
            <p className="text-sm leading-6 text-white">
                <a href="#">
                    <strong className="font-semibold">{title}</strong>
                    <svg
                        viewBox="0 0 2 2"
                        className={clsx("mx-2 size-0.5", "inline fill-current")}
                        aria-hidden="true"
                    >
                        <circle cx={1} cy={1} r={1} />
                    </svg>
                    {message}
                </a>
            </p>
        </div>
    );
}
