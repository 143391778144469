import { useCallback, useState } from "react";

export function useModalState(initial = false) {
    const [showModal, setShowModal] = useState(initial);

    const toggle = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal, setShowModal]);

    const close = useCallback(() => {
        setShowModal(false);
    }, [showModal, setShowModal]);

    const open = useCallback(() => {
        setShowModal(true);
    }, [showModal, setShowModal]);

    return {
        isOpen: showModal,
        isClosed: !showModal,
        toggle,
        close,
        open,
    };
}
