export const Graphics = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="152"
            height="120"
            viewBox="0 0 152 120"
            fill="none"
            className={className}
        >
            <g filter="url(#filter0_d_255_3142)">
                <rect
                    x="18"
                    y="0.321777"
                    width="130"
                    height="102"
                    rx="5"
                    fill="#4A66F4"
                />
                <g filter="url(#filter1_bd_255_3142)">
                    <rect
                        x="4"
                        y="24.3218"
                        width="103.448"
                        height="87.3563"
                        rx="5"
                        fill="#EBF1FF"
                    />
                </g>
                <path
                    d="M13.1955 34.0919V101.908H98.2529"
                    stroke="#AFB5EB"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
                <path
                    d="M17.7932 82.9425L37.474 70.8736L57.1548 74.8966L73.9413 54.7816L98.2529 42.7126"
                    stroke="#77F1A1"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
                <path
                    d="M17.7932 95.5862L39.7893 93.1521H60.6279L82.0452 85.2413L98.2529 86.4584"
                    stroke="#09168E"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
                <path
                    d="M17.7932 93.2873L37.0296 81.2625L59.1807 83.3845L79.583 69.9451L99.4023 65.701"
                    stroke="#2042F6"
                    strokeWidth="3"
                    strokeLinecap="round"
                />
                <g filter="url(#filter2_bd_255_3142)">
                    <rect
                        x="57"
                        y="10.3218"
                        width="80"
                        height="53"
                        rx="5"
                        fill="white"
                        fillOpacity="0.9"
                        shapeRendering="crispEdges"
                    />
                </g>
                <rect
                    x="69"
                    y="24.3218"
                    width="51"
                    height="5"
                    rx="2.5"
                    fill="#7E899A"
                />
                <rect
                    x="69"
                    y="34.3218"
                    width="56"
                    height="5"
                    rx="2.5"
                    fill="#7E899A"
                />
                <rect
                    x="69"
                    y="44.3218"
                    width="38"
                    height="5"
                    rx="2.5"
                    fill="#7E899A"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_255_3142"
                    x="0"
                    y="0.321777"
                    width="152"
                    height="119.356"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_255_3142"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_255_3142"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_bd_255_3142"
                    x="-6"
                    y="14.3218"
                    width="123.448"
                    height="107.356"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_255_3142"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_255_3142"
                        result="effect2_dropShadow_255_3142"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_255_3142"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_bd_255_3142"
                    x="47"
                    y="0.321777"
                    width="100"
                    height="73"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_255_3142"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_255_3142"
                        result="effect2_dropShadow_255_3142"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_255_3142"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
