import { useApiMutation, useApiPolling } from "@/api";
import { startProfileDownload, getProfileDownloadStatus } from ".";
import { datadogRum } from "@datadog/browser-rum";
import { BulkDownloadStatus } from "@/entities/Company";
import { ddActions } from "@/utils/monitoring";

export const useProfileDownloads = (token: string) => {
    const {
        data: task,
        isPolling,
        startPolling,
        stopPolling,
        isError,
    } = useApiPolling(getProfileDownloadStatus, {
        onPollSuccess(data) {
            if (data?.state === BulkDownloadStatus.Failure) {
                stopPolling();
                datadogRum.addAction(ddActions.profile.downloadChartbook.fail, {
                    company_token: token,
                });
            }
            if (data?.fileUrl && data?.state === BulkDownloadStatus.Success) {
                stopPolling();
                datadogRum.addAction(
                    ddActions.profile.downloadChartbook.success,
                    {
                        company_token: token,
                    },
                );
                window.location.assign(data.fileUrl);
            }
        },
    });
    const { mutate: startDownload } = useApiMutation(startProfileDownload, {
        onSuccess(data) {
            if (data.taskId) {
                startPolling(data);
                datadogRum.addAction(
                    ddActions.profile.downloadChartbook.start,
                    {
                        company_token: token,
                    },
                );
            }
        },
    });

    return {
        task,
        isPolling,
        startDownload: () => startDownload({ token }),
        stopDownload: stopPolling,
        isError,
    };
};
