import React from "react";
import { IconProps } from "./types";

const NoteIcon = ({ className, width = "40", height = "40" }: IconProps) => {
    return (
        <svg
            role="img"
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="currentColor"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect width="40" height="40" rx="20" fill="#1F2630" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M14 11C12.3431 11 11 12.3431 11 14V23C11 24.6569 12.3431 26 14 26H26.0249L28.3178 28.2929C28.9478 28.9229 30.0249 28.4767 30.0249 27.5858V23L30 23.0098C30 23.0065 30 23.0033 30 23V14C30 12.3431 28.6569 11 27 11H14Z"
                fill="#788598"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M15.5 20C14.6716 20 14 19.3284 14 18.5C14 17.6716 14.6716 17 15.5 17C16.3284 17 17 17.6716 17 18.5C17 19.3284 16.3284 20 15.5 20ZM20.5 20C19.6716 20 19 19.3284 19 18.5C19 17.6716 19.6716 17 20.5 17C21.3284 17 22 17.6716 22 18.5C22 19.3284 21.3284 20 20.5 20ZM25.5 20C24.6716 20 24 19.3284 24 18.5C24 17.6716 24.6716 17 25.5 17C26.3284 17 27 17.6716 27 18.5C27 19.3284 26.3284 20 25.5 20Z"
                fill="#3E4758"
            />
        </svg>
    );
};

export default NoteIcon;
