import React, { ReactElement } from "react";
import { IconProps } from "../../atoms";
import { LottieProps } from "lottie-react-web";

export type ModalProps = {
    isOpen: boolean;
    testId?: string;
    title?: string;
    icon?: ReactElement<IconProps | LottieProps>;
    description?: string;
    children?: React.ReactNode;
    onClose?: () => void;
    titleClassName?: string;
    withTransitions?: boolean;
    wide?: boolean;
    preventClose?: boolean;
    className?: string;
};
