import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { Controller, Control, FieldValues } from "react-hook-form";
import React, { useState } from "react";

type SelectFieldProps = {
    testId?: string;
    name: string;
    className?: string;
    control: Control<FieldValues>;
    options: any[];
    placeholder?: string;
};

export function SelectField({
    testId,
    name,
    className,
    control,
    options,
    placeholder,
}: SelectFieldProps) {
    const [selected, setSelected] = useState("");

    return (
        <Controller
            control={control}
            name={name}
            defaultValue=""
            render={({ field }) => (
                <Listbox
                    {...field}
                    value={field.value}
                    onChange={(e) => {
                        field.onChange(e);
                        setSelected(e);
                    }}
                >
                    {({ open }) => (
                        <>
                            <div className="relative">
                                <Listbox.Button
                                    className={clsx(
                                        "focus:ring-interactive bg-secondary relative w-full cursor-pointer rounded-md py-3 pl-4 pr-10 text-left ring-1 ring-inset ring-slate-300 focus:border-blue-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 dark:ring-slate-700",
                                        className,
                                    )}
                                    data-testid={testId}
                                >
                                    <p className="body-s subdued cursor-pointer">
                                        {field.value ||
                                            selected ||
                                            placeholder ||
                                            "Select an option"}
                                    </p>
                                    <span
                                        className={clsx(
                                            "pr-2",
                                            "pointer-events-none absolute inset-y-0 right-0 flex items-center",
                                        )}
                                    >
                                        <ChevronUpDownIcon
                                            className="size-5 text-gray-400"
                                            aria-hidden="true"
                                        />
                                    </span>
                                </Listbox.Button>
                                <Transition
                                    show={open}
                                    as={React.Fragment}
                                    enter="transition ease-out duration-100"
                                    enterFrom="transform opacity-0 scale-95"
                                    enterTo="transform opacity-100 scale-100"
                                    leave="transition ease-in duration-75"
                                    leaveFrom="transform opacity-100 scale-100"
                                    leaveTo="transform opacity-0 scale-95"
                                >
                                    <Listbox.Options
                                        static
                                        className={clsx(
                                            "absolute z-10 mt-1 max-h-60 w-full py-1",
                                            "rounded-md ring-1 ring-inset ring-slate-300 focus:outline-none dark:ring-slate-700",
                                            "custom-scrollbar bg-secondary overflow-auto",
                                        )}
                                    >
                                        {options.map(({ label, value }) => (
                                            <Listbox.Option
                                                key={value}
                                                className={({ active }) =>
                                                    clsx(
                                                        active
                                                            ? "bg-slate-800"
                                                            : "bg-secondary",
                                                        "relative cursor-pointer select-none py-2 pl-10 pr-4",
                                                    )
                                                }
                                                value={value}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        <p className="body-s subdued block cursor-pointer">
                                                            {label}
                                                        </p>
                                                        {selected ? (
                                                            <span
                                                                className={clsx(
                                                                    active
                                                                        ? "subdued"
                                                                        : "subdued",
                                                                    "absolute inset-y-0 left-0 flex items-center pl-3",
                                                                )}
                                                            >
                                                                <CheckIcon
                                                                    className="size-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </Listbox.Options>
                                </Transition>
                            </div>
                        </>
                    )}
                </Listbox>
            )}
        />
    );
}
