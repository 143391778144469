import { useState, useCallback, useMemo, useEffect, useRef } from "react";
import { ObjectSchema } from "yup";
import { validateSchema } from "./validateSchema";
import {
    FormState,
    InferedKey,
    InferedPartial,
    InferedValue,
} from "./Form.types";
import { flattenObject, unflattenObject } from "./utils";
import { isEqual } from "lodash";

export function useForm<T extends ObjectSchema<any>>({
    schema,
    defaultValues,
    onChange,
}: {
    schema: T;
    defaultValues?: InferedPartial<T>;
    onChange?: (values: FormState<T>) => void;
}) {
    const flattenedDefaultValues = flattenObject(defaultValues || {});
    const [values, setValues] = useState<InferedPartial<T>>(
        flattenedDefaultValues,
    );

    const [isDirty, setIsDirty] = useState<boolean>(false);
    const prevState = useRef<FormState<T>>();
    const updateValue = useCallback(
        (key: InferedKey<T>, value: InferedValue<T>) => {
            setValues((prevValues) => ({ ...prevValues, [key]: value }));
            setIsDirty(true);
        },
        [setValues, setIsDirty, schema],
    );

    const mergeValues = useCallback(
        (vals: InferedPartial<T>) => {
            const flattenedVals = flattenObject(vals);
            setValues((prevValues) => ({ ...prevValues, ...flattenedVals }));
            setIsDirty(true);
        },
        [setValues, setIsDirty, schema],
    );

    const replaceValues = useCallback(
        (vals: InferedPartial<T>) => {
            const flattenedVals = flattenObject(vals);
            setValues(flattenedVals);
            setIsDirty(true);
        },
        [setValues, setIsDirty, schema],
    );

    const validate = useCallback(
        () => validateSchema(values, schema),
        [values, schema],
    );

    const state: FormState<T> = useMemo(() => {
        const { isValid, errors } = validate();

        return {
            isValid,
            errors,
            isDirty,
            isPristine: !isDirty,
            canSubmit: isDirty && isValid,
            values: values,
            data: unflattenObject(values),
            updateValue,
            mergeValues,
            replaceValues,
            schema,
        };
    }, [values, isDirty, schema]);

    useEffect(() => {
        if (
            prevState.current &&
            !isEqual(prevState.current.values, state.values)
        ) {
            onChange && onChange(state);
        }
        prevState.current = state;
    }, [onChange, state]);

    return { ...state, validate };
}
