import { createMutation } from "@/api";
import { Company, CompanySignupPayload } from "./Company.types";

export const createCompany = createMutation<
    Company & { companyRole: string; uuid: string }
>(
    ({ api, payload }) => {
        return api.post(`/backend/v2/companies`, payload);
    },
    {
        mutationKey: ["createCompany"],
        invalidates: ["/companies"],
    },
);

export const acceptCompanyAgreements = createMutation<{
    organizationId: string;
}>(
    ({ api, payload }) => {
        return api.post(
            `/backend/v2/companies/${payload.organizationId}/accept_agreements`,
            {},
        );
    },
    {
        mutationKey: ["acceptCompanyAgreements"],
        invalidates: ["/companies"],
    },
);

export const updateCompany = createMutation<Company, CompanySignupPayload>(
    ({ api, payload, session }) => {
        return api.patch(
            `/backend/v2/companies/${session.user?.organizationId}`,
            payload,
        );
    },
    {
        mutationKey: ["updateCompany"],
        errorToast: { message: "Failed to update company" },
        invalidates: ["/companies"],
    },
);

export const acceptCompanyMNDA = createMutation<{
    firstName: string;
    lastName: string;
    position: string;
}>(
    ({ api, payload, session }) => {
        return api.post(
            `/backend/v2/companies/${session.user?.organizationId}/onboarding/mnda`,
            {
                firstName: payload.firstName,
                lastName: payload.lastName,
                role: payload.position,
            },
        );
    },
    {
        mutationKey: ["acceptCompanyMNDA"],
        errorToast: { message: "Failed to sign MNDA" },
        invalidates: ["/companies"],
    },
);
