import type {
    QueryConfig,
    QueryConfigOptions,
    QueryFunction,
} from "../types/query.types";

export function createQuery<R, P = any>(
    queryFn: QueryFunction<R, P>,
    options: QueryConfigOptions = {},
): QueryConfig<R, P> {
    return {
        ...{
            ...options,
            waitForSession:
                options.waitForSession === undefined
                    ? true
                    : options.waitForSession,
        },
        queryFn,
    };
}
