import clsx from "clsx";
import React from "react";

export const ToastContainer = ({ children }: { children: React.ReactNode }) => {
    return (
        <div
            aria-live="assertive"
            className={clsx(
                "z-[1000] px-8 py-6 pt-20",
                "pointer-events-none fixed inset-0 flex items-start",
            )}
        >
            <div
                className={clsx(
                    "w-full",
                    "flex flex-col",
                    "items-center space-y-4",
                    "sm:items-end",
                )}
            >
                {children}
            </div>
        </div>
    );
};
