import React from "react";
import clsx from "clsx";
import { autoCapitalize } from "@/utils";

interface StatBoxProps {
    title: string;
    value: string;
    description?: string;
    secondary?: boolean;
    icon?: React.ReactNode;
    testId?: string;
    truncate?: boolean;
    className?: string;
}

export function StatBox({
    title,
    value,
    description,
    secondary,
    icon,
    testId,
    truncate = true,
    className,
}: StatBoxProps) {
    return (
        <div
            className={clsx(
                !truncate ? "max-w-72" : "max-w-60",
                "h-auto px-5 py-4",
                {
                    "border-default rounded-lg border bg-slate-800": !secondary,
                },
                className,
            )}
            data-testid={testId}
        >
            <div className={clsx(icon && "flex items-center gap-6")}>
                {icon && <div>{icon}</div>}
                <div>
                    <p className="body-s">{title}</p>
                    <div className="mt-2 flex items-start">
                        <h4
                            className={clsx(
                                truncate ? "truncate" : "whitespace-normal",
                                "mr-2",
                                secondary ? "text-color" : "text-brand-300",
                            )}
                            data-testid={`${testId}:value`}
                        >
                            {autoCapitalize(value, true)}
                        </h4>
                        <p
                            className="body-micro subdued"
                            data-testid={`${testId}:description`}
                        >
                            {description}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    );
}
