import type { AppContext, PaginationParams } from "@/api/types/context.types";
import { QueryConfig, QueryConfigOptions } from "@/api/types/query.types";
import {
    useQuery,
    useQueryClient,
    UseQueryOptions,
} from "@tanstack/react-query";
import omit from "lodash/omit";
import { useCallback, useState } from "react";
import { getCacheKey } from "@/api/utils";
import { useRouter } from "@/utils";
import { useSession } from "@/entities/Session/useSession";
import { useApiClient } from "../useApiClient/useApiClient";

export type UseApiQueryOptions<R, P> = {
    params?: P; // extra fields to pass to the queryFn
    toastsEnabled?: boolean;
    pagination?: PaginationParams;
} & Partial<UseQueryOptions<R>> &
    Partial<QueryConfigOptions>;

// Custom hook to wrap react query and inject api and session
export function useApiQuery<R, P = any>(
    query: QueryConfig<R, P>,
    hookOptions?: UseApiQueryOptions<R, P>,
) {
    const options: UseApiQueryOptions<R, P> = {
        toastsEnabled: true,
        ...omit(query, "queryFn"),
        ...hookOptions,
    };
    const [pagination, setPagination] = useState<PaginationParams | undefined>(
        options.pagination,
    );
    const queryClient = useQueryClient();
    const session = useSession();
    const router = useRouter();
    const api = useApiClient(options.pagination?.currentUrl);
    const appContext = {
        api,
        session: session,
        params: options.params as P,
        router,
        queryClient,
        pagination,
    } as AppContext;

    const isWaitingForSession = options.waitForSession && !session.user;

    const result = useQuery({
        ...options,
        queryKey:
            options.key ||
            query.key ||
            getCacheKey(
                query.queryFn,
                appContext,
                options.scope || query.scope,
            ),
        queryFn: (): Promise<R> | R => query.queryFn(appContext),
        enabled: (options?.enabled ?? true) && !isWaitingForSession,
    } as UseQueryOptions<R>);

    return {
        ...result,
        isLoading:
            result.isLoading || (options.waitForSession && session.isLoading),
        session,
        params: options.params,
        router,
        queryClient,
        setPaginationUrl: useCallback(
            (currentUrl: string) =>
                setPagination({ ...(pagination || {}), currentUrl }),
            [],
        ),
    };
}
