import { ProfileHero } from "@/entities/CompanyProfile";
import { StatBox, Section } from "@/ui";
import clsx from "clsx";

type HeroProps = {
    disclaimer?: string;
    isPrivate: boolean;
} & ProfileHero;

export const Hero = ({
    publicHeadline,
    publicDescription,
    privateHeadline,
    privateDescription,
    disclaimer,
    callouts,
    isPrivate,
}: HeroProps) => {
    const description = isPrivate ? privateDescription : publicDescription;
    return (
        <Section
            title={isPrivate ? privateHeadline : publicHeadline}
            className="pb-0"
        >
            <div className="mb-8 lg:mt-8">
                {description && (
                    <p className="body-m subdued">
                        {isPrivate
                            ? privateDescription || publicDescription
                            : publicDescription}
                    </p>
                )}
                {disclaimer && (
                    <p className="body-micro subdued">{disclaimer}</p>
                )}
            </div>
            {callouts && (
                <div
                    className={clsx(
                        "my-10",
                        "flex flex-col flex-wrap",
                        "gap-5",
                        "md:flex-row",
                    )}
                >
                    {Object.values(callouts).map(
                        ({ title, value }) =>
                            value !== "" &&
                            title !== "" && (
                                <StatBox
                                    className="px-0"
                                    secondary
                                    title={title}
                                    key={title}
                                    value={value}
                                    truncate={false}
                                />
                            ),
                    )}
                </div>
            )}
        </Section>
    );
};
