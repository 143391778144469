import React from "react";
import { IconProps } from "../types";

const PandleIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.04 22.4572L19.9989 27.6304L28.9595 22.4572V12.1126L19.9989 6.93945L11.04 12.1126V22.4572ZM17.6641 15.9353L19.9989 14.5865L22.3354 15.9353V18.6346L19.9989 19.9833L17.6641 18.6346V15.9353ZM15.7132 29.0143V31.7118L13.3766 33.0606L11.04 31.7118V29.0143L13.3766 27.6655L15.7132 29.0143Z"
                        fill="#03A9F5"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default PandleIcon;
