import clsx from "clsx";

type TableDataProps = {
    children: React.ReactNode;
    testId?: string;
    className?: string;
    subdued?: boolean;
    hideOnMobile?: boolean;
};

export function TableData({
    children,
    testId,
    className,
    subdued = false,
    hideOnMobile = false,
}: TableDataProps) {
    return (
        <td
            className={clsx(
                "whitespace-nowrap py-5 pl-4 pr-3 sm:pl-0",
                hideOnMobile && "hidden sm:table-cell",
                className,
            )}
            data-testid={testId}
        >
            <div
                className={clsx(
                    "grid",
                    "font-body",
                    "font-bodyWeight",
                    "text-bodySSize",
                    "leading-bodyS",
                    "tracking-body",
                    "cursor-default",
                    "text-defaultTextColor",
                    subdued && "subdued",
                )}
            >
                {children}
            </div>
        </td>
    );
}
