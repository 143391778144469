import React from "react";
import { IconProps } from "./types";

const Hum = ({
    className,
    width = "24",
    height = "24",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M32 16C32 24.8366 24.8366 32 16 32C7.16344 32 0 24.8366 0 16C0 7.16344 7.16344 0 16 0C24.8366 0 32 7.16344 32 16ZM26.2667 11.1066V10.5288H17.6889C16.5333 10.5288 15.6 10.8844 15.2 11.5066L14.5778 12.7066C12.8357 11.3438 10.7001 10.5799 8.48889 10.5288H8.31111L12.7556 16.2177L8.35555 24.84L8.84444 25.1066L9.33333 24.1733C10.1778 22.6177 11.0222 21.2844 12.8889 20.4844C17.9535 18.2383 18.7851 15.6868 19.4484 13.6514C19.4545 13.6327 19.4606 13.6141 19.4667 13.5955C19.5434 13.3048 19.6476 13.0221 19.7778 12.7511C20.2667 11.3288 20.8889 11.1511 23.1111 11.1066H26.2667ZM17.6516 11.6506C17.7633 11.6586 17.8696 11.7015 17.9556 11.7733C18.0436 11.8386 18.1112 11.9276 18.1506 12.0299C18.1899 12.1322 18.1994 12.2436 18.1778 12.3511C18.1597 12.437 18.1223 12.5177 18.0684 12.587C18.0145 12.6563 17.9455 12.7124 17.8667 12.7511L17.6 12.84C17.5348 12.8426 17.4698 12.8321 17.4087 12.8092C17.3477 12.7863 17.2918 12.7514 17.2444 12.7066C17.1598 12.6286 17.0954 12.5313 17.0567 12.4229C17.018 12.3145 17.0061 12.1984 17.0222 12.0844C17.0398 12.0037 17.0776 11.9287 17.132 11.8665C17.1864 11.8044 17.2557 11.757 17.3333 11.7288C17.4286 11.67 17.5399 11.6426 17.6516 11.6506Z"
                        fill="white"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};
export default Hum;
