import type {
    MutationConfig,
    MutationConfigOptions,
    MutationFunction,
} from "../types/mutation.types";

export function createMutation<TReturn, TPayload = TReturn, TParams = any>(
    mutationFn: MutationFunction<TReturn, TPayload, TParams>,
    options: MutationConfigOptions = {},
): MutationConfig<TReturn, TPayload> {
    return {
        ...{
            ...options,
            waitForSession:
                options.waitForSession === undefined
                    ? true
                    : options.waitForSession,
        },
        mutationFn,
    };
}
