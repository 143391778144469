import React from "react";
import { IconProps } from "./types";

const ArrowRightIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.75 10L16.25 10M16.25 10L10.625 4.375M16.25 10L10.625 15.625"
                        stroke="#151C23"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ArrowRightIcon;
