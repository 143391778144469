export const VerifyEmail = ({ className }: { className?: string }) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="142"
            height="115"
            viewBox="0 0 142 115"
            fill="none"
            className={className}
        >
            <g filter="url(#filter0_d_36200_4699)">
                <rect
                    x="25"
                    y="18.0791"
                    width="103.648"
                    height="69.3586"
                    rx="5"
                    fill="#09168E"
                />
                <g filter="url(#filter1_b_36200_4699)">
                    <rect
                        x="33.5724"
                        y="26.6514"
                        width="104.428"
                        height="69.3586"
                        rx="5"
                        fill="#DDE5FE"
                        fillOpacity="0.85"
                    />
                </g>
                <rect
                    x="64.7449"
                    y="87.4375"
                    width="19.4828"
                    height="19.4828"
                    rx="3"
                    fill="#2042F6"
                />
                <rect
                    x="88.9034"
                    y="66.3965"
                    width="20.2621"
                    height="40.5241"
                    rx="3"
                    fill="#2042F6"
                />
                <rect
                    x="113.841"
                    y="45.355"
                    width="19.4828"
                    height="61.5655"
                    rx="3"
                    fill="#2042F6"
                />
                <g filter="url(#filter2_bd_36200_4699)">
                    <rect
                        x="4"
                        y="4.0791"
                        width="96"
                        height="49"
                        rx="5"
                        fill="white"
                    />
                </g>
                <path
                    d="M30.7049 26.4447C29.5614 26.4447 28.6344 25.5177 28.6344 24.3742V14.0218C28.6344 12.8783 29.5667 11.9357 30.6987 12.0972C37.0512 13.003 42.0761 18.0279 42.9819 24.3804C43.1434 25.5124 42.2008 26.4447 41.0573 26.4447H30.7049Z"
                    fill="#77F1A1"
                />
                <path
                    d="M22.4291 16.2382C23.5611 16.0767 24.4934 17.0192 24.4934 18.1627V28.5152C24.4934 29.6587 25.4204 30.5857 26.5639 30.5857H36.9164C38.0599 30.5857 39.0024 31.518 38.8409 32.65C37.8389 39.6767 31.7971 45.0791 24.4934 45.0791C16.4889 45.0791 10 38.5902 10 30.5857C10 23.282 15.4024 17.2402 22.4291 16.2382Z"
                    fill="#00057F"
                />
                <rect
                    x="52"
                    y="21.0791"
                    width="39"
                    height="5"
                    rx="2.5"
                    fill="#7E899A"
                />
                <rect
                    x="59"
                    y="28.0791"
                    width="24"
                    height="5"
                    rx="2.5"
                    fill="#7E899A"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_36200_4699"
                    x="0"
                    y="4.0791"
                    width="142"
                    height="110.841"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_36200_4699"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_36200_4699"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_b_36200_4699"
                    x="29.5724"
                    y="22.6514"
                    width="112.428"
                    height="77.3584"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_36200_4699"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_36200_4699"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_bd_36200_4699"
                    x="-6"
                    y="-5.9209"
                    width="116"
                    height="69"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_36200_4699"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="1"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect2_dropShadow_36200_4699"
                    />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_36200_4699"
                        result="effect2_dropShadow_36200_4699"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_36200_4699"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
