import clsx from "clsx";
import { TableBody } from "./TableBody";
import { TableHead } from "./TableHead";
import { TableRow } from "./TableRow";
import { TableData } from "./TableData";

export type TableProps = {
    children: React.ReactNode;
    testId?: string;
    className?: string;
};

export function Table({ testId, className, children }: TableProps) {
    return (
        <table
            className={clsx(
                "min-w-full divide-y divide-slate-300 dark:divide-slate-700",
                className,
            )}
            data-testid={testId}
        >
            {children}
        </table>
    );
}

Table.Head = TableHead;
Table.Body = TableBody;
Table.Row = TableRow;
Table.Data = TableData;
