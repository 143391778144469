import React from "react";
import { IconProps } from "./types";

const InfoIcon = ({ className, width = "25", height = "24" }: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox="0 0 25 24"
            fill="currentColor"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M4.5 16L2.5 22.5L9 20.5L4.5 16Z"
                fill="#7E899A"
            />
            <path
                d="M3.5 12C3.5 6.47715 7.97715 2 13.5 2C19.0228 2 23.5 6.47715 23.5 12C23.5 17.5228 19.0228 22 13.5 22C7.97715 22 3.5 17.5228 3.5 12Z"
                fill="#788598"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M7.66793 13.5547C7.36158 13.0952 7.48576 12.4743 7.94528 12.1679C8.40481 11.8616 9.02568 11.9858 9.33203 12.4453C10.4891 14.1808 11.8543 15 13.5 15C15.1457 15 16.5109 14.1808 17.6679 12.4453C17.9743 11.9858 18.5952 11.8616 19.0547 12.1679C19.5142 12.4743 19.6384 13.0952 19.332 13.5547C17.8224 15.8191 15.8543 17 13.5 17C11.1457 17 9.17757 15.8191 7.66793 13.5547Z"
                fill="#7E899A"
            />
        </svg>
    );
};

export default InfoIcon;
