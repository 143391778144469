import { SearchHeader } from "./SearchHeader";
import { SearchError } from "./SearchError";
import { SearchLoading } from "./SearchLoading";
import { SearchResultsBody } from "./SearchResultsBody";
import { SearchRadioResult } from "./SearchRadioResult";
import { SearchCheckboxResult } from "./SearchCheckboxResult";
import { SearchBar } from "./SearchBar";

export const Search = {
    Header: SearchHeader,
    Error: SearchError,
    Loading: SearchLoading,
    Results: SearchResultsBody,
    RadioResult: SearchRadioResult,
    CheckboxResult: SearchCheckboxResult,
    Bar: SearchBar,
};
