export enum UserRole {
    ADMIN = "hum_admin",
    COMPANY = "company_user",
    COMPANY_ADMIN = "company_admin",
    FUND = "fund_user",
    FUND_ADMIN = "fund_admin",
}

export type User = {
    id: string;
    legacy: false;
    role: UserRole;
    isAdmin: boolean;
    isCompany: boolean;
    isCompanyAdmin: boolean;
    isFund: boolean;
    isFundAdmin: boolean;
    accessToken?: string;
    auth0Id: string;
    email: string;
    firstName: string;
    lastName: string;
    position?: string;
    phone?: string;
    organizationId: string;
    acceptedCommunications: boolean;
    emailVerified: boolean;
};
