import React from "react";
import clsx from "clsx";

type DescriptionListProps = {
    testId?: string;
    className?: string;
    children: React.ReactNode;
};

export function DescriptionList({
    testId,
    className,
    children,
}: DescriptionListProps) {
    return (
        <dl
            className={clsx(
                "grid",
                "gap-4",
                "grid-cols-1",
                "[&>dt]:white-space-nowrap",
                "[&>dt]:col-span-1",
                "[&>dd]:col-span-2",
                "[&>dd]:break-words",
                "md:grid-cols-3",
                className,
            )}
            data-testid={testId}
        >
            {children}
        </dl>
    );
}

DescriptionList.Item = ({
    label,
    description,
    forceCapitalization,
}: {
    label: string;
    description: string;
    forceCapitalization?: boolean;
}) => (
    <>
        <dt className="white-space-nowrap col-span-1">
            <p className="body-m subdued">{label}</p>
        </dt>
        <dd className="col-span-2 break-words">
            <p className={clsx("body-m", forceCapitalization && "capitalize")}>
                {description}
            </p>
        </dd>
    </>
);
