import React from "react";
import { IconProps } from "./types";

const VerifyEmail = ({
    className,
    width = "200",
    height = "200",
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="currentColor"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <circle cx="142.4" cy="80.8" r="27.2" fill="#343E4F" />
            <circle cx="49.1996" cy="161.2" r="11.6" fill="#343E4F" />
            <circle cx="30.7993" cy="44.4" r="6" fill="#343E4F" />
            <circle cx="92.225" cy="100" r="61.6" fill="#1F2630" />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.4212 111.083C91.9499 112.482 94.2942 112.482 95.8229 111.083L125.446 83.9656C126.721 82.7988 127.001 81.1785 126.556 79.7959C127.89 81.2253 128.707 83.1442 128.707 85.2538V116.541C128.707 120.959 125.125 124.541 120.707 124.541H65.5376C61.1193 124.541 57.5376 120.959 57.5376 116.541V85.2538C57.5376 83.1442 58.3542 81.2253 59.6886 79.7959C59.2428 81.1785 59.523 82.7988 60.7977 83.9656L90.4212 111.083Z"
                fill="#404D64"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M90.5318 111.082C92.0605 112.482 94.4048 112.482 95.9335 111.082L125.557 83.9654C126.832 82.7986 127.112 81.1783 126.666 79.7957C125.206 78.2316 123.126 77.2537 120.817 77.2537H65.6482C63.3396 77.2537 61.2594 78.2316 59.7992 79.7957C59.3534 81.1784 59.6336 82.7986 60.9083 83.9654L90.5318 111.082Z"
                fill="#546686"
            />
            <circle cx="60.7997" cy="82.4" r="9.6" fill="#2042F6" />
            <path
                d="M62.3439 78.0909V87.4H59.8167V80.4182H59.7621L57.7257 81.6364V79.4909L60.0167 78.0909H62.3439Z"
                fill="white"
            />
        </svg>
    );
};

export default VerifyEmail;
