import { createApiClient } from "@/api/utils";
import { useSession } from "@/entities/Session/useSession";

export function useApiClient(baseURLOverride?: string) {
    const session = useSession();

    return createApiClient({
        baseURL: baseURLOverride,
        token: session.token.data || undefined,
    });
}
