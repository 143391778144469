import type { AppContext } from "@/api/types/context.types";
import type { PredicateFunction } from "@/api/types/mutation.types";
import type { QueryConfig } from "@/api/types/query.types";
import { getCacheKey } from "@/api/utils";

export function invalidateQueries(
    invalidationList: (QueryConfig<any> | string | PredicateFunction)[],
    appContext: AppContext,
) {
    const { queryClient } = appContext;

    for (const invalidation of invalidationList) {
        if (typeof invalidation === "function") {
            queryClient.invalidateQueries({
                predicate: invalidation,
            });
        } else if (typeof invalidation === "string") {
            queryClient.invalidateQueries({
                predicate: (query) => {
                    let invalidate = false;
                    query.queryKey.forEach((key) => {
                        if (
                            typeof key === "string" &&
                            key.includes(invalidation)
                        )
                            invalidate = true;
                    });
                    return invalidate;
                },
                exact: false,
            });
        } else {
            const query = invalidation as QueryConfig<any>;
            const queryKey = getCacheKey(
                query.queryFn,
                appContext,
                query.scope,
            );
            if (queryKey)
                queryClient.invalidateQueries({
                    queryKey: queryKey,
                    exact: true,
                });
        }
    }
}
