import { FinancialHighlights } from "./FinancialHighlights";
import { Footer } from "./Footer";
import { Hero } from "./Hero";
import { HeroForm } from "./HeroForm";
import { OpportunityCTA } from "./OpportunityCTA";
import { Controls } from "./Controls";
import { FinancialHighlightsForm } from "./FinancialHighlightsForm";
import { DownloadsForm } from "./DownloadsForm";
import { Downloads } from "./Downloads";
import { Content } from "./Content";
import { LinkCTA } from "./LinkCTA";
import { PrivacyToggle } from "./PrivacyToggle";

export const Profile = {
    Hero,
    HeroForm,
    DownloadsForm,
    Downloads,
    FinancialHighlights,
    FinancialHighlightsForm,
    Footer,
    Content,
    OpportunityCTA,
    LinkCTA,
    Controls,
    PrivacyToggle,
};
