import { PrimitiveAtom, useAtom } from "jotai";
import { useCallback } from "react";

export function useFilters<T>(atom: PrimitiveAtom<Partial<T>>): {
    filters: Awaited<Partial<T>>;
    setFilters: (next: Partial<T>) => void;
} {
    const [filters, setFilters] = useAtom(atom);

    const set = useCallback(
        (next: Partial<T>) => {
            setFilters({ ...filters, ...next });
        },
        [setFilters, filters],
    );

    return {
        filters,
        setFilters: set,
    };
}
