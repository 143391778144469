import React from "react";
import { IconProps } from "./types";

const RefreshIcon = ({
    className,
    width = "24",
    height = "24",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M15.6705 9.34838H20.6631V9.3466M2.63184 19.6444V14.6517M2.63184 14.6517L7.62448 14.6517M2.63184 14.6517L5.81273 17.8347C6.80325 18.827 8.06031 19.58 9.51206 19.969C13.9132 21.1482 18.4369 18.5364 19.6162 14.1353M3.67843 9.86481C4.8577 5.46371 9.38148 2.85191 13.7826 4.03118C15.2343 4.42017 16.4914 5.17309 17.4819 6.16547L20.6631 9.3466M20.6631 4.35577V9.3466"
                        stroke="#CBD5E1"
                        strokeWidth="1.5"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default RefreshIcon;
