import clsx from "clsx";

export const TextBlock = ({
    title,
    description,
    className,
}: {
    title: string;
    description: string;
    className?: string;
}) => {
    return (
        <section className={clsx("mb-4 text-pretty", className)}>
            <h3 className="heading-m mb-1">{title}</h3>
            <p className="body-s">{description}</p>
        </section>
    );
};
