export const HumLogo = ({ className }: { className?: string }) => {
    return (
        <svg
            width="108"
            height="46"
            viewBox="0 0 108 46"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <path
                d="M43.0759 0.077484V1.44482H35.5928C30.3018 1.48198 28.8082 1.94271 27.5672 5.32385C27.3294 5.96292 27.1139 6.6317 26.891 7.34508C25.3081 12.2867 23.3389 18.4323 11.1371 23.8124C6.65615 25.789 4.7092 28.9695 2.65079 32.6479L1.49898 34.855L0.287712 34.2382L10.8398 13.6838L0.280273 0.0700684H0.629535C8.07548 0.0997927 13.3144 3.80047 15.2167 5.19751C15.2167 5.19751 16.3611 2.85671 16.7178 2.3291C17.6616 0.924626 19.8686 0.0849224 22.6255 0.0849224C23.4355 0.0849224 43.0759 0.077484 43.0759 0.077484ZM23.272 3.03506C22.8485 2.69323 22.2986 2.62636 21.7933 2.85672C21.4068 3.02764 21.1244 3.3769 21.0353 3.78561C20.9164 4.32808 21.0947 4.85568 21.5183 5.19751C21.7709 5.40558 22.0756 5.50962 22.3952 5.50962C22.5958 5.50962 22.8039 5.46502 23.0045 5.37585C23.3909 5.20493 23.6733 4.85569 23.7625 4.43955C23.8665 3.90451 23.6882 3.37689 23.272 3.03506Z"
                fill="white"
            />
            <path
                d="M42.2133 44.3519H49.5031V33.6511C49.5031 20.9737 36.744 24.823 36.744 24.823V14.0925H29.4541V44.3519H36.744V34.3274C36.744 32.8189 37.9701 31.5927 39.4786 31.5927C40.9871 31.5927 42.2133 32.8189 42.2133 34.3274V44.3519Z"
                fill="white"
            />
            <path
                d="M99.9894 44.3445H107.279V34.3199C107.279 28.7912 102.784 24.2954 97.2548 24.2954C94.9512 24.2954 92.7887 25.0534 90.9904 26.495L90.8789 26.5842L90.7674 26.495C88.9691 25.0534 86.8067 24.2954 84.503 24.2954C78.9743 24.2954 74.4785 28.7912 74.4785 34.3199V44.3445H81.7684V34.3199C81.7684 32.8114 82.9945 31.5853 84.503 31.5853C86.0115 31.5853 87.2377 32.8114 87.2377 34.3199V44.3445H94.5276V34.3199C94.5276 32.8114 95.7537 31.5853 97.2622 31.5853C98.7707 31.5853 99.9968 32.8114 99.9968 34.3199V44.3445H99.9894Z"
                fill="white"
            />
            <path
                d="M51.9629 35.1151L51.9703 35.2191C52.0149 37.8571 53.0776 40.3317 54.9651 42.1895C56.8525 44.0472 59.3494 45.0653 61.9948 45.0653C64.6403 45.0653 67.1372 44.0398 69.0247 42.1895C70.9047 40.3391 71.9674 37.8646 72.0194 35.2191L72.0268 35.0408V25.1946H64.7221V35.0408C64.7221 36.5493 63.4959 37.7754 61.9874 37.7754C60.4789 37.7754 59.2528 36.5493 59.2528 35.0408V25.1946H51.9629V35.1151Z"
                fill="white"
            />
        </svg>
    );
};
