/* eslint-disable react/no-unknown-property */
/* eslint-disable tailwindcss/migration-from-tailwind-2 */
import {
    ChevronDoubleLeftIcon,
    ListBulletIcon,
} from "@heroicons/react/20/solid";
import clsx from "clsx";
import { LayoutGroup, motion } from "framer-motion";
import { atom, useAtom } from "jotai";

// if true drawer is open
export const dashboardDrawerAtom = atom<boolean>(false);

export function Dashboard({
    children,
}: {
    children: [React.ReactNode, React.ReactNode];
}) {
    const [menuOpen, setMenuOpen] = useAtom(dashboardDrawerAtom);

    return (
        <LayoutGroup>
            <div className={clsx("h-screen px-4 pt-8", "flex overflow-clip")}>
                <div className="h-full pb-6">
                    <motion.div
                        className={clsx(
                            "h-full p-6",
                            "flex flex-col",
                            menuOpen ? "w-[320px]" : "w-fit",
                            "border-default bg-slate-50 bg-opacity-[6%]",
                        )}
                        style={{ borderRadius: "6px", padding: "24px" }}
                        layout="size"
                    >
                        <motion.div
                            className={clsx(
                                "flex w-full",
                                menuOpen
                                    ? "justify-between"
                                    : "items-center justify-center",
                            )}
                            layout
                        >
                            <motion.div layout className={clsx("mb-6 ")}>
                                <svg
                                    width="26"
                                    height="26"
                                    viewBox="0 0 26 26"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <g clip-path="url(#clip0_36708_843)">
                                        <g clip-path="url(#clip1_36708_843)">
                                            <path
                                                fill-rule="evenodd"
                                                clip-rule="evenodd"
                                                d="M26 13C26 20.1797 20.1797 26 13 26C5.8203 26 0 20.1797 0 13C0 5.8203 5.8203 0 13 0C20.1797 0 26 5.8203 26 13ZM21.3417 9.0278V8.55836H14.3722C13.4333 8.55836 12.675 8.84725 12.35 9.3528L11.8444 10.3278C10.429 9.22048 8.69385 8.59987 6.89723 8.55836H6.75278L10.3639 13.1806L6.78889 20.1861L7.18612 20.4028L7.58334 19.6445C8.26945 18.3806 8.95556 17.2972 10.4722 16.6472C14.5872 14.8223 15.2629 12.7492 15.8019 11.0954L15.8167 11.05C15.8791 10.8138 15.9637 10.5841 16.0694 10.3639C16.4667 9.20836 16.9722 9.06392 18.7778 9.0278H21.3417ZM14.3419 9.46979C14.4327 9.47628 14.5191 9.51115 14.5889 9.56947C14.6604 9.62253 14.7154 9.69485 14.7473 9.77797C14.7793 9.8611 14.787 9.9516 14.7695 10.0389C14.7548 10.1087 14.7244 10.1743 14.6806 10.2306C14.6368 10.2869 14.5807 10.3325 14.5167 10.3639L14.3 10.4361C14.2471 10.4383 14.1942 10.4298 14.1446 10.4112C14.095 10.3926 14.0496 10.3642 14.0111 10.3278C13.9424 10.2644 13.89 10.1853 13.8586 10.0973C13.8271 10.0092 13.8175 9.91484 13.8306 9.82225C13.8449 9.75666 13.8755 9.69576 13.9197 9.64524C13.9639 9.59472 14.0202 9.55623 14.0833 9.53336C14.1607 9.48555 14.2512 9.46331 14.3419 9.46979Z"
                                                fill="white"
                                            />
                                        </g>
                                    </g>
                                    <defs>
                                        <clipPath id="clip0_36708_843">
                                            <rect
                                                width="26"
                                                height="26"
                                                fill="white"
                                            />
                                        </clipPath>
                                        <clipPath id="clip1_36708_843">
                                            <rect
                                                width="26"
                                                height="26"
                                                fill="white"
                                            />
                                        </clipPath>
                                    </defs>
                                </svg>
                            </motion.div>
                            {menuOpen && (
                                <motion.div
                                    initial={{ opacity: 0 }}
                                    animate={{ y: 0, opacity: 1 }}
                                    exit={{ opacity: 0 }}
                                    transition={{ duration: 0.4 }}
                                    onClick={() => setMenuOpen(false)}
                                    className="cursor-pointer"
                                >
                                    <ChevronDoubleLeftIcon
                                        height={24}
                                        width={24}
                                        className="fill-gray-300 hover:fill-white"
                                    />
                                </motion.div>
                            )}
                        </motion.div>
                        {!menuOpen && (
                            <motion.div
                                layout="size"
                                className={clsx(
                                    "bg-secondary border-default size-8 h-8 cursor-pointer",
                                    "border-default flex items-center justify-center border",
                                )}
                                style={{
                                    borderRadius: "80px",
                                }}
                                onClick={() => setMenuOpen(true)}
                            >
                                <ListBulletIcon width={18} height={18} />
                            </motion.div>
                        )}
                        {menuOpen && children[0]}
                    </motion.div>
                </div>
                <motion.div
                    layout
                    layoutScroll
                    className={clsx(
                        "size-full px-8 pb-8",
                        "scrollbar scrollbar-track-transparent scrollbar-thumb-slate-600",
                        "overflow-auto",
                    )}
                >
                    {children[1]}
                </motion.div>
            </div>
        </LayoutGroup>
    );
}
