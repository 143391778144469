import React from "react";
import { IconProps } from "../types";

const NetsuiteIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_3944)">
                        <path
                            d="M9.7521 14.8662H14.1758V25.3755H16.3781V29.4818H9.75098V14.8662H9.7521ZM30.229 24.5356H25.8053V14.0263H23.603V9.91992H30.2301V24.5356H30.229Z"
                            fill="#BACCDB"
                        />
                        <path
                            d="M8.72461 8.91211H22.7055V20.915L17.3298 13.9896H8.72461V8.91211ZM31.2367 30.5093H17.2558V18.5064L22.6315 25.4318H31.2367"
                            fill="#125580"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_3944">
                            <rect
                                width="28"
                                height="28"
                                fill="white"
                                transform="translate(6 6)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default NetsuiteIcon;
