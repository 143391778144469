export function randomlySelectEnum<T extends Record<string, never>>(
    anEnum: T,
): T[keyof T] {
    const enumValues = Object.keys(anEnum)
        .map((n) => Number.parseInt(n))
        .filter((n) => !Number.isNaN(n)) as unknown as T[keyof T][];
    const randomIndex = Math.floor(Math.random() * enumValues.length);
    const randomEnumValue = enumValues[randomIndex];
    return randomEnumValue;
}

export function randomlySelectObjectValue<T extends Record<string, never>>(
    object: T,
): T[keyof T] {
    const keys = Object.keys(object);
    const randomKey = keys[Math.floor(Math.random() * keys.length)];
    return (object as any)[randomKey];
}
