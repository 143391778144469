import { Provider, atom, createStore, useAtom } from "jotai";
import { ElementType, useCallback, useEffect, useMemo, useState } from "react";
import { NavBar } from "@/ui";
import { useRouter } from "@/utils";
import { useSession } from "@/entities/Session/useSession/useSession";
import { INavLink, NavBarState } from ".";

export const userLinksAtom = atom<{
    showSettings?: boolean;
    showLogout?: boolean;
}>({});
export const navStore = createStore();

export function NavigationProvider({
    children,
    showSettings = false,
    showLogout = false,
}: {
    children: React.ReactNode;
    showSettings?: boolean;
    showLogout?: boolean;
}) {
    useEffect(() => {
        navStore.set(userLinksAtom, { showSettings, showLogout });
    }, [showSettings, showLogout]);

    return <Provider store={navStore}>{children}</Provider>;
}

export function withNavigation(
    Page: ElementType,
    config: Partial<NavBarState> = {},
) {
    return function (props) {
        const [userLinks, setUserLinks] = useAtom(userLinksAtom);
        const [navState, setState] = useState<NavBarState>({
            navIsVisible:
                config.navIsVisible === undefined ? true : config.navIsVisible,
            navLinks: config.navLinks || [],
            showLogout: userLinks.showLogout,
            showSettings: userLinks.showSettings,
        });

        useEffect(() => {
            setState((prevState) => ({
                ...prevState,
                showLogout: userLinks.showLogout,
                showSettings: userLinks.showSettings,
            }));
        }, [userLinks]);

        const router = useRouter();
        const { user } = useSession();

        const setNavState = useCallback(
            (config: Partial<NavBarState>) => {
                setState((prevState) => ({ ...prevState, ...config }));
                if (config.showLogout)
                    setUserLinks({
                        ...userLinks,
                        showLogout: config.showLogout,
                    });
                if (config.showSettings)
                    setUserLinks({
                        ...userLinks,
                        showSettings: config.showSettings,
                    });
            },
            [setUserLinks, setState],
        );

        const resolvedNavLinks = useMemo(
            () =>
                config.navLinks?.map((link) => {
                    if (typeof link === "function") {
                        return link({ router, user });
                    } else {
                        return link;
                    }
                }),
            [user, router, config.navLinks],
        );

        const firstLetter = useMemo(
            () => user?.firstName?.charAt(0).toUpperCase(),
            [user],
        );

        console.log(navState);

        return (
            <>
                {navState.navIsVisible && (
                    <NavBar
                        links={
                            resolvedNavLinks?.filter(
                                (link) => !!link,
                            ) as INavLink[]
                        }
                        showSettings={navState.showSettings}
                        showLogout={navState.showLogout}
                        letter={firstLetter}
                        fixedBar
                    />
                )}
                <Page
                    {...props}
                    navState={navState}
                    setNavState={setNavState}
                />
            </>
        );
    };
}
