import React, { ReactElement } from "react";
import clsx from "clsx";
import { Badge, IconProps } from "../../atoms";

export interface ConnectorItemProps {
    title: string;
    description?: string;
    icon: ReactElement<IconProps>;
    className?: string;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    testId?: string;
    selected?: boolean;
    beta?: boolean;
    connected?: boolean;
    disabled?: boolean;
}

export function ConnectorItem({
    title,
    description,
    icon,
    className,
    onClick,
    testId,
    selected = false,
    beta = false,
    connected = false,
    disabled = false,
}: ConnectorItemProps) {
    return (
        <div
            role="button"
            key={"connector-item:" + title}
            className={clsx(
                "border",
                "rounded",
                "h-16 px-4 py-3",
                "flex items-center justify-start",
                disabled ? "cursor-not-allowed" : "cursor-pointer",
                "bg-surface-subdued",
                selected ? "border-cta-primary-default" : "border-default",
                disabled ? "opacity-50" : "",
                className,
            )}
            onClick={onClick}
            data-testid={testId}
        >
            {React.cloneElement(icon, { className: "mr-4" })}
            <div className="grow overflow-auto ">
                <p
                    className={clsx(
                        "body-s truncate text-gray-100",
                        disabled ? "cursor-not-allowed" : "cursor-pointer",
                    )}
                >
                    {title}
                </p>
                <p
                    className={clsx(
                        "lozenge truncate text-gray-300",
                        disabled ? "cursor-not-allowed" : "cursor-pointer",
                    )}
                >
                    {description}
                </p>
            </div>
            <div className="flex gap-1">
                {beta && (
                    <Badge className="bg-icon-disabled flex h-4 items-center">
                        <p className="lozenge text-[#FCFDFE]">Beta</p>
                    </Badge>
                )}
                {connected && (
                    <Badge className="flex h-4 items-center">
                        <p className="lozenge text-[#151C23]">Connected</p>
                    </Badge>
                )}
            </div>
        </div>
    );
}
