import { CompanyProfile } from "@/entities/CompanyProfile";
import { Profile } from ".";

export const Content = ({
    hero,
    financialHighlights,
    downloads,
    isPrivate,
    publicToken,
}: CompanyProfile) => {
    return (
        <>
            <Profile.Hero {...hero} isPrivate={isPrivate} />
            <Profile.FinancialHighlights {...financialHighlights} />
            <Profile.Downloads {...downloads} publicToken={publicToken} />
            <Profile.Footer />
        </>
    );
};
