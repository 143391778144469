import clsx from "clsx";
import { Spinner } from "../../atoms";

export function SearchLoading({ className }: { className?: string }) {
    return (
        <div className={clsx("flex items-center", className)}>
            <Spinner className="mr-4" />
            <p className="text-button subdued">Searching...</p>
        </div>
    );
}
