import { ddActions } from "@/utils";
import { Modal, ModalFooter } from "@/ui";

export const DiscardModal = ({
    onDiscard,
    onCancel,
}: {
    onDiscard: () => void;
    onCancel: () => void;
}) => {
    return (
        <Modal
            isOpen
            title="Discard changes?"
            description="Are you sure you want to discard your changes?"
        >
            <ModalFooter>
                <button
                    className="secondary"
                    onClick={onCancel}
                    dd-action={ddActions.profile.edit.discard.cancel}
                >
                    Go back
                </button>
                <button
                    onClick={onDiscard}
                    dd-action={ddActions.profile.edit.discard.confirm}
                >
                    Discard
                </button>
            </ModalFooter>
        </Modal>
    );
};
