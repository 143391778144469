import { useFlagsmith, useFlagsmithLoading } from "flagsmith/react";
import { FlagValue, IUseFlags } from "./flagsmith.types";

export const normalizeFlagName = (name: string) =>
    name.replace(/_/g, "-").replace(/direct/g, "capital");

export const useFlags = (): IUseFlags => {
    const flagsmith = useFlagsmith();
    const loadingState = useFlagsmithLoading();
    const ready =
        !!loadingState &&
        !loadingState.error &&
        !loadingState.isFetching &&
        !loadingState.isLoading &&
        ["CACHE", "SERVER"].includes(loadingState.source);

    const get = (
        flagNames: string[],
        defaults: Record<string, boolean> = {},
    ) => {
        const normalizedFlagNames = flagNames.map(normalizeFlagName);
        const normalizedDefaults = Object.entries(defaults).reduce(
            (defs, [k, v]) => ({
                [normalizeFlagName(k)]: v,
                ...defs,
            }),
            {},
        );

        return normalizedFlagNames.reduce(
            (results, flag) => ({
                ...results,
                [flag]: ready
                    ? flagsmith.hasFeature(flag)
                    : normalizedDefaults[flag] || false,
            }),
            {},
        );
    };

    const values = (
        flagNames: string[],
        defaults: Record<string, FlagValue> = {},
    ) => {
        const normalizedDefaults = Object.entries(defaults).reduce(
            (defs, [k, v]) => ({
                [normalizeFlagName(k)]: v,
                ...defs,
            }),
            {},
        );

        return flagNames.map(normalizeFlagName).reduce(
            (results, flag) => ({
                ...results,
                [flag]: flagsmith.getValue(flag, {
                    fallback: normalizedDefaults[flag] || null,
                }),
            }),
            {},
        );
    };

    const enabled = (
        flagNames: string | string[],
        defaults: Record<string, boolean> = {},
    ) => {
        if (typeof flagNames === "string") {
            return get([flagNames], defaults)[normalizeFlagName(flagNames)];
        } else {
            return Object.values(get(flagNames, defaults)).every((f) => f);
        }
    };

    const value = (flagName: string, def: FlagValue = null) => {
        const value = flagsmith.getValue(normalizeFlagName(flagName), {
            fallback: def,
        });

        if (typeof value === "string") {
            try {
                return JSON.parse(value);
            } catch (e) {
                return value;
            }
        }
    };

    return {
        get,
        values,
        enabled,
        value,
        ready,
        error: !!loadingState?.error,
        loading: !!loadingState?.isLoading,
    };
};
