import React from "react";
import { twMerge } from "tailwind-merge";

export function Badge({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div
            className={twMerge(
                "w-max rounded-[14px] bg-green-200 px-2 py-1 uppercase",
                className,
            )}
        >
            {children}
        </div>
    );
}
