import { lazy, Suspense, useMemo } from "react";
import { IUseFlags } from "@/utils";
import { User } from "@/entities/User/User.model";
import React from "react";
import { Spinner } from "@/ui";

export const RoleController = React.memo(
    function ({ user, flags }: { user?: User | null; flags: IUseFlags }) {
        const isAdmin = useMemo(() => user?.isAdmin, [user?.isAdmin]);
        const isCompany = useMemo(() => user?.isCompany, [user?.isCompany]);
        const isFund = useMemo(() => user?.isFund, [user?.isFund]);

        // Apps
        const AdminApp = lazy(() => import("@/apps/AdminApp"));
        const CompanyApp = lazy(() => import("@/apps/CompanyApp"));
        const FundApp = lazy(() => import("@/apps/FundApp"));

        // Demo Apps
        const CreditAnalystDemo = lazy(
            () => import("@/demos/CreditAnalystDemo"),
        );
        const CompanyDemo = lazy(() => import("@/demos/CompanyDemo"));
        const PortfolioInvestorDemo = lazy(
            () => import("@/demos/PortfolioInvestorDemo"),
        );

        const isCreditAnalystDemo = flags.enabled("demo:credit-analyst");
        const isCompanyDemo = flags.enabled("demo:company");
        const isPortfolioInvestorDemo = flags.enabled(
            "demo:portfolio-investor",
        );
        const isDemo =
            isCreditAnalystDemo || isCompanyDemo || isPortfolioInvestorDemo;

        if (isDemo) {
            return (
                <Suspense
                    fallback={<Spinner fullScreen testId="fallback-spinner" />}
                >
                    {isCreditAnalystDemo && <CreditAnalystDemo />}
                    {isCompanyDemo && <CompanyDemo />}
                    {isPortfolioInvestorDemo && <PortfolioInvestorDemo />}
                </Suspense>
            );
        }

        return (
            <Suspense
                fallback={<Spinner fullScreen testId="fallback-spinner" />}
            >
                {isAdmin && <AdminApp />}
                {isCompany && <CompanyApp />}
                {isFund && <FundApp />}
            </Suspense>
        );
    },
    (prev, next) => {
        return (
            prev.user?.isAdmin === next.user?.isAdmin &&
            prev.user?.isCompany === next.user?.isCompany &&
            prev.user?.isFund === next.user?.isFund &&
            prev.flags.enabled("demo:credit-analyst") ===
                next.flags.enabled("demo:credit-analyst") &&
            prev.flags.enabled("demo:company") ===
                next.flags.enabled("demo:company") &&
            prev.flags.enabled("demo:portfolio-investor") ===
                next.flags.enabled("demo:portfolio-investor")
        );
    },
);
