import { Switch } from "@headlessui/react";
import clsx from "clsx";

type ToggleProps = {
    testId?: string;
    className?: string;
    checked: boolean;
    onChange: (checked: boolean) => void;
};

export function Toggle({
    testId,
    className,
    checked = false,
    onChange,
}: ToggleProps) {
    return (
        <Switch
            as="div"
            data-testid={testId}
            checked={checked}
            onChange={onChange}
            className={clsx(
                checked ? "bg-brand-500" : "bg-slate-600 dark:bg-slate-900",
                `relative inline-flex h-6 w-11 shrink-0 cursor-pointer rounded-full border-2 border-transparent
                transition-colors duration-200 ease-in-out focus:outline-none`,
                className,
            )}
        >
            <span
                aria-hidden="true"
                className={clsx(
                    checked ? "translate-x-5" : "translate-x-0",
                    `pointer-events-none inline-block size-5 rounded-full bg-white shadow ring-0
                    transition duration-200 ease-in-out`,
                )}
            />
        </Switch>
    );
}
