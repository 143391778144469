export enum HighlightsIcons {
    BarChartUp = "BarChartUp",
    LineChartUp = "LineChartUp",
    Percent = "Percent",
    Tag = "Tag",
    CircleDollar = "CircleDollar",
    DollarBill = "DollarBill",
}

export const HIGHLIGHT_LABELS = {
    ltmRevenue: "LTM Revenue",
    lastMonthEBITDA: "Last Month EBITDA",
    yoyRevenueGrowth: "YoY Revenue Growth",
    ltmGrossMargin: "LTM Gross Margin",
    runRateRevenue: "Run Rate Revenue",
    qoqRevenueGrowth: "QoQ Revenue Growth",
    totalEquityRaised: "Total Equity Raised",
};
