import { PlusIcon, XMarkIcon } from "@heroicons/react/20/solid";
import { Icon, Input, MutableList, Section, Select, TextArea } from "@/ui";
import { ddActions } from "@/utils";
import { MONTHS } from "@/constants";
import {
    HighlightsIcons,
    HighlightsSection,
    HighlightsSectionForm,
} from "@/entities/CompanyProfile";
import clsx from "clsx";

export type FinancialHighlightsFormProps = {
    inputs: Partial<HighlightsSectionForm>;
    onChange: (data: Partial<HighlightsSection>) => void;
};

export const FinancialHighlightsForm = () => {
    const monthsArray = MONTHS.map((month) => ({ label: month, value: month }));
    const currentYear = new Date().getFullYear();
    const highlightsIcons = [
        {
            label: <Icon.BarChartup className="ml-10 size-7" />,
            value: HighlightsIcons.BarChartUp,
        },
        {
            label: <Icon.LineChartup className="ml-10 size-7" />,
            value: HighlightsIcons.LineChartUp,
        },
        {
            label: <Icon.Percent className="ml-10 size-7" />,
            value: HighlightsIcons.Percent,
        },
        {
            label: <Icon.Tag className="ml-10 size-7" />,
            value: HighlightsIcons.Tag,
        },
        {
            label: <Icon.CircleDollar className="ml-10 size-7" />,
            value: HighlightsIcons.CircleDollar,
        },
        {
            label: <Icon.DollarBill className="ml-10 size-7" />,
            value: HighlightsIcons.DollarBill,
        },
    ];

    return (
        <Section title="Financial data" secondaryBg>
            <div className="hum-container px-4 pb-20 pt-8">
                <Input
                    parent="financialHighlights"
                    label="Name of this section"
                    name="title"
                    placeholder="E.g: Financial Highlights"
                />
                <div className="my-8">
                    <p className="body-micro subdued">Last updated date</p>
                    <div className="w-xl mt-2 flex gap-4">
                        <Select
                            parent="financialHighlights"
                            label="Month"
                            name="month"
                            options={monthsArray}
                        />
                        <Select
                            parent="financialHighlights"
                            label="Year"
                            name="year"
                            options={[...Array(10)].map((_, i) => ({
                                label: (currentYear - i).toString(),
                                value: (currentYear - i).toString(),
                            }))}
                        />
                    </div>
                </div>
                <TextArea
                    parent="financialHighlights"
                    label="Paragraph Description"
                    name="description"
                    placeholder="E.g: Manufacturer of athleisure performance apparel."
                />
                <MutableList name="financialHighlights.highlights">
                    {({ items, addItem }) => (
                        <div>
                            <div
                                className={clsx(
                                    "mt-8",
                                    "grid grid-cols-1",
                                    "gap-3",
                                    "md:grid-cols-3",
                                )}
                            >
                                {items.map(({ parent, remove }, index) => (
                                    <div
                                        key={parent}
                                        data-testid={parent}
                                        className="relative mt-4 p-4"
                                    >
                                        <XMarkIcon
                                            data-testid={`delete-highlight-${index + 1}`}
                                            onClick={remove}
                                            width={20}
                                            height={20}
                                            className="absolute right-0 top-5 cursor-pointer"
                                            dd-action={
                                                ddActions.profile
                                                    .financialHighlights.item
                                                    .delete
                                            }
                                        />
                                        <Input
                                            parent={parent}
                                            name="title"
                                            placeholder="Label, eg. LTM Revenue"
                                            label={`Financial Highlight ${index + 1}`}
                                        />
                                        <Input
                                            parent={parent}
                                            name="value"
                                            placeholder="Value, eg. $10M"
                                            label={`Value ${index + 1}`}
                                        />
                                        <Select
                                            parent={parent}
                                            name="icon"
                                            label="Icon"
                                            placeholder="Select an icon"
                                            options={highlightsIcons}
                                        />
                                    </div>
                                ))}
                            </div>
                            <button
                                onClick={addItem}
                                className="secondary inline-flex gap-2"
                                dd-action={
                                    ddActions.profile.financialHighlights.item
                                        .add
                                }
                            >
                                <PlusIcon className="mr-2 size-4" />
                                <span className="text-button">
                                    Add financial highlight item
                                </span>
                            </button>
                        </div>
                    )}
                </MutableList>
            </div>
        </Section>
    );
};
