import React from "react";
import { IconProps } from "./types";

const FileIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#92ABFA" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M20.4999 17.5C19.6715 17.5 18.9999 18.1716 18.9999 19V32C18.9999 32.8284 19.6715 33.5 20.4999 33.5H31.4999C32.3283 33.5 32.9999 32.8284 32.9999 32V23.1213C32.9999 22.7235 32.8418 22.342 32.5605 22.0607L28.4392 17.9393C28.1579 17.658 27.7764 17.5 27.3786 17.5H20.4999ZM22.7499 26C22.3357 26 21.9999 26.3358 21.9999 26.75C21.9999 27.1642 22.3357 27.5 22.7499 27.5H29.2499C29.6641 27.5 29.9999 27.1642 29.9999 26.75C29.9999 26.3358 29.6641 26 29.2499 26H22.7499ZM22.7499 29C22.3357 29 21.9999 29.3358 21.9999 29.75C21.9999 30.1642 22.3357 30.5 22.7499 30.5H29.2499C29.6641 30.5 29.9999 30.1642 29.9999 29.75C29.9999 29.3358 29.6641 29 29.2499 29H22.7499Z"
                        fill="#2A313F"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default FileIcon;
