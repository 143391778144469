import clsx from "clsx";

type TableHeadProps = {
    labels: string[];
    testId?: string;
    className?: string;
    hideOnMobile?: boolean;
    sticky?: boolean;
};

export function TableHead({
    labels,
    className,
    testId,
    hideOnMobile = false,
    sticky = true,
}: TableHeadProps) {
    return (
        <thead
            className={clsx(
                hideOnMobile && "hidden sm:table-header-group",
                className,
            )}
            data-testid={testId}
        >
            <tr>
                {labels.map((label) => (
                    <th
                        key={label}
                        scope="col"
                        className={clsx(
                            "bg-default py-3.5 pl-4 pr-3 sm:pl-0",
                            "text-left",
                            sticky && "z-8 sticky top-0",
                        )}
                    >
                        <h4>{label}</h4>
                    </th>
                ))}
            </tr>
        </thead>
    );
}
