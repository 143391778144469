import { NavigateFunction, Params } from "react-router-dom";

export type RouterSearch = Record<string, undefined | string | string[]>;

export type Router = {
    pathname: string;
    asPath: string;
    hash: string;
    state: unknown;
    key: string;
    search: RouterSearch;
    searchString: string;
    params: Readonly<Params<string>>;
    navigate: NavigateFunction;
    updateSearch: (key: string, value: string) => void;
    removeSearchParam: (key: string) => void;
};
