import React from "react";
import clsx from "clsx";

export function ProfileIcon({
    children,
    className,
}: {
    children: React.ReactNode;
    className?: string;
}) {
    return (
        <div
            className={clsx(
                "flex",
                "justify-center",
                "items-center",
                "bg-orange-200",
                "rounded",
                "p-1",
                "w-8",
                "h-8",
                "cursor-pointer",
                className,
            )}
        >
            <p className="body-s cursor-pointer text-orange-800">{children}</p>
        </div>
    );
}
