import React from "react";
import { IconProps } from "./types";

const ExcelFileIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={"0 0 50 50"}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M10.1786 2.5H28.6029C28.9519 2.5 29.29 2.62168 29.5589 2.8441L41.456 12.6834C41.8005 12.9684 42 13.3922 42 13.8393V42.2833C42 46.2225 41.954 46.5 37.8214 46.5H10.1786C6.04601 46.5 6 46.2225 6 42.2833V6.71667C6 2.77747 6.04601 2.5 10.1786 2.5Z"
                        fill="#2A313F"
                    />
                    <path
                        d="M14.1779 22.633C14.2499 22.633 14.3163 22.6717 14.3518 22.7342L16.1781 25.9489H16.2511L18.0864 22.7339C18.122 22.6715 18.1883 22.633 18.2601 22.633H20.0254C20.1799 22.633 20.2761 22.8008 20.1979 22.9341L17.5236 27.4976L20.2568 32.0594C20.3367 32.1927 20.2407 32.3622 20.0853 32.3622H18.2823C18.2108 32.3622 18.1448 32.3241 18.109 32.2622L16.2511 29.0416H16.1781L14.3201 32.2622C14.2844 32.3241 14.2183 32.3622 14.1469 32.3622H12.3535C12.198 32.3622 12.102 32.1925 12.1821 32.0592L14.9237 27.4976L12.2326 22.9346C12.154 22.8013 12.2501 22.633 12.4049 22.633H14.1779Z"
                        fill="#788598"
                    />
                    <path
                        d="M21.8242 32.3622C21.7137 32.3622 21.6242 32.2727 21.6242 32.1622V22.833C21.6242 22.7226 21.7137 22.633 21.8242 22.633H23.3992C23.5096 22.633 23.5992 22.7226 23.5992 22.833V30.6663H27.4039C27.5144 30.6663 27.6039 30.7558 27.6039 30.8663V32.1622C27.6039 32.2727 27.5144 32.3622 27.4039 32.3622H21.8242Z"
                        fill="#788598"
                    />
                    <path
                        d="M34.1612 25.4311C34.0581 25.4311 33.9735 25.3522 33.9521 25.2514C33.8889 24.9536 33.7414 24.7158 33.5096 24.538C33.2329 24.3258 32.8573 24.2197 32.3829 24.2197C32.0606 24.2197 31.7885 24.2672 31.5665 24.3622C31.3445 24.4541 31.1742 24.5823 31.0556 24.747C30.9401 24.9117 30.8823 25.0986 30.8823 25.3076C30.8762 25.4818 30.9112 25.6338 30.9872 25.7637C31.0663 25.8935 31.1742 26.0059 31.3111 26.101C31.4479 26.1928 31.606 26.2736 31.7854 26.3432C31.9648 26.4097 32.1564 26.4667 32.3601 26.5143L33.1994 26.7233C33.6069 26.8183 33.9809 26.945 34.3215 27.1033C34.662 27.2617 34.957 27.4565 35.2063 27.6876C35.4557 27.9188 35.6488 28.1912 35.7856 28.5048C35.9255 28.8183 35.997 29.1778 36 29.5831C35.997 30.1785 35.851 30.6948 35.5621 31.1318C35.2763 31.5657 34.8627 31.903 34.3215 32.1437C33.7832 32.3812 33.134 32.5 32.3738 32.5C31.6197 32.5 30.9629 32.3797 30.4034 32.139C29.8469 31.8983 29.4121 31.542 29.0989 31.0701C28.8194 30.642 28.6596 30.1225 28.6197 29.5118C28.6123 29.3999 28.7031 29.3076 28.8152 29.3076H30.3346C30.44 29.3076 30.526 29.3897 30.5425 29.4938C30.5812 29.7389 30.6641 29.9492 30.7911 30.1247C30.9522 30.3401 31.1666 30.5032 31.4342 30.614C31.7049 30.7217 32.0104 30.7755 32.351 30.7755C32.6855 30.7755 32.9759 30.7249 33.2222 30.6235C33.4716 30.5222 33.6646 30.3812 33.8015 30.2007C33.9383 30.0202 34.0067 29.8127 34.0067 29.5784C34.0067 29.3599 33.9444 29.1762 33.8197 29.0273C33.6981 28.8785 33.5187 28.7518 33.2815 28.6473C33.0474 28.5428 32.76 28.4477 32.4194 28.3622L31.4023 28.0962C30.6147 27.8967 29.9929 27.5847 29.5367 27.1603C29.0806 26.7359 28.8541 26.1643 28.8571 25.4454C28.8541 24.8563 29.0046 24.3416 29.3087 23.9014C29.6158 23.4612 30.037 23.1176 30.5721 22.8705C31.1073 22.6235 31.7155 22.5 32.3966 22.5C33.0899 22.5 33.6951 22.6235 34.212 22.8705C34.732 23.1176 35.1364 23.4612 35.4253 23.9014C35.6799 24.2895 35.8259 24.7318 35.8632 25.2281C35.8715 25.3395 35.7808 25.4311 35.6691 25.4311H34.1612Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ExcelFileIcon;
