import lo from "lodash";

export function camelCased(data: any) {
    if (Array.isArray(data)) return data.map(camelCased);
    if (data === null) return data;
    if (typeof data !== "object") return data;
    const camelizedData = {};
    lo.mapKeys(data, (value, key) => {
        camelizedData[lo.camelCase(key)] = camelCased(value);
    });
    return camelizedData;
}
