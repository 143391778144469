import React from "react";
import { ToastContainer } from "./ToastContainer";
import {
    ToastNotification,
    ToastType,
    ToastNotificationProps,
} from "./ToastNotification";
import { createStore, atom, useAtom, Provider } from "jotai";
import { nanoid } from "nanoid";

type ToastOptions = {
    description?: string;
    onUndo?: () => void;
    closeable?: boolean;
};

export type ToastBody = {
    message: string;
    options?: ToastOptions;
};

export type Toast = {
    id: string;
    type: ToastType;
} & ToastBody;

export const toastStore = createStore();
export const toastAtom = atom<Toast[]>([]);

const pushToast = (
    type: ToastType,
    message: string,
    options?: ToastOptions,
) => {
    const toasts = toastStore.get(toastAtom);
    if (
        !toasts.find(
            (toast) => toast.type === type && toast.message === message,
        )
    ) {
        toastStore.set(toastAtom, [
            ...toasts,
            { type, message, options, id: nanoid() },
        ]);
    }
};

const clearToastById = (id: string) => {
    const toasts = toastStore.get(toastAtom);
    toastStore.set(
        toastAtom,
        toasts.filter((toast) => toast.id !== id),
    );
};

export const toast = {
    success: (message: string, options?: ToastOptions) => {
        pushToast(ToastType.SUCCESS, message, options);
    },
    error: (message: string, options?: ToastOptions) => {
        pushToast(ToastType.ERROR, message, options);
    },
    info: (message: string, options?: ToastOptions) => {
        pushToast(ToastType.INFO, message, options);
    },
    warning: (message: string, options?: ToastOptions) => {
        pushToast(ToastType.WARNING, message, options);
    },
    tip: (message: string, options?: ToastOptions) => {
        pushToast(ToastType.TIP, message, options);
    },
    generic: (args: ToastNotificationProps, options?: ToastOptions) => {
        pushToast(args.type || ToastType.SUCCESS, args.message, options);
    },
};

const ToastList = () => {
    const toasts = useAtom(toastAtom)[0];

    return (
        <>
            {toasts.map((toast) => (
                <ToastNotification
                    key={toast.id}
                    type={toast.type}
                    message={toast.message}
                    description={toast.options?.description}
                    onUndo={toast.options?.onUndo}
                    onDismiss={() => clearToastById(toast.id)}
                    closeable={toast.options?.closeable}
                />
            ))}
        </>
    );
};

export const ToastProvider = () => {
    return (
        <Provider store={toastStore}>
            <ToastContainer>
                <ToastList />
            </ToastContainer>
        </Provider>
    );
};
