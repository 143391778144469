/**
 * HTTP client factory.
 */

// eslint-disable-next-line hum/require-constants-import
import axios from "axios";

const create = axios.create.bind(axios);
const httpClient = axios;

/**
 * Override axios.create method to extend instances.
 */
httpClient.create = (config) => {
    const instance: any = create(config);

    /**
     * Extend axios instance with capability of creating derived instances.
     */
    instance.create = function (instanceConfig: any) {
        const newInstance = httpClient.create();

        newInstance.defaults = { ...newInstance.defaults, ...instanceConfig };

        // copy over current interceptors
        for (const hook of ["request", "response"]) {
            this.interceptors[hook].forEach(({ fulfilled, rejected }) => {
                newInstance.interceptors[hook].use(fulfilled, rejected);
            });
        }

        return newInstance;
    };

    return instance;
};

export { httpClient, axios };
