import React from "react";
import { IconProps } from "./types";

const DollarBillIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#3E4758" />
                    <path
                        d="M17.7195 30.4552C17.7195 30.7754 17.9329 30.9951 18.2594 30.9951H33.7407C34.0671 30.9951 34.2806 30.7754 34.2806 30.4552V21.0572C34.2806 20.7308 34.0671 20.5173 33.7407 20.5173H18.2594C17.9329 20.5173 17.7195 20.7308 17.7195 21.0572V30.4552ZM19.0441 29.3252V22.1872C19.0441 21.9549 19.1634 21.8419 19.3831 21.8419H32.6232C32.8367 21.8419 32.9622 21.9549 32.9622 22.1872V29.3252C32.9622 29.5512 32.8367 29.6705 32.6232 29.6705H19.3831C19.1634 29.6705 19.0441 29.5512 19.0441 29.3252ZM19.7347 28.8355C19.7347 28.9297 19.7849 28.9736 19.8728 28.9736H24.5498C23.9409 28.3207 23.583 27.1844 23.583 25.7468C23.583 24.3217 23.9409 23.1917 24.5436 22.5325H19.8728C19.7849 22.5325 19.7347 22.5827 19.7347 22.6706V28.8355ZM27.4377 28.9736H32.1335C32.2152 28.9736 32.2654 28.9297 32.2654 28.8355V22.6706C32.2654 22.5827 32.2152 22.5325 32.1335 22.5325H27.444C28.0655 23.1917 28.4296 24.3217 28.4296 25.7468C28.4296 27.1844 28.0592 28.3207 27.4377 28.9736ZM24.3741 25.7468C24.3741 27.6239 25.027 28.8355 25.9749 28.8355C26.9606 28.8355 27.6323 27.6239 27.6323 25.7468C27.6323 23.876 26.9606 22.6706 25.9749 22.6706C25.027 22.6706 24.3741 23.876 24.3741 25.7468Z"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default DollarBillIcon;
