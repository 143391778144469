import clsx from "clsx";

type EmptyProps = {
    testId?: string;
    className?: string;
    isBoxless?: boolean;
    message: string;
};

export function Empty({ testId, className, message, isBoxless }: EmptyProps) {
    return (
        <div
            className={clsx(
                !isBoxless &&
                    "rounded-lozenge border-default border bg-gray-600",
                "p-4",
                "text-center",
                "box-border",
                className,
            )}
            data-testid={testId}
        >
            <p className="body-s subdued leading-none">{message}</p>
        </div>
    );
}
