import { HighlightsIcons, HighlightsSection } from "@/entities/CompanyProfile";
import { Icon, Section, StatBox } from "@/ui";
import clsx from "clsx";

interface IconMap {
    [key: string]: JSX.Element;
}

const iconMappings: IconMap = {
    [HighlightsIcons.BarChartUp]: <Icon.BarChartup />,
    [HighlightsIcons.LineChartUp]: <Icon.LineChartup />,
    [HighlightsIcons.Percent]: <Icon.Percent />,
    [HighlightsIcons.Tag]: <Icon.Tag />,
    [HighlightsIcons.CircleDollar]: <Icon.CircleDollar />,
    [HighlightsIcons.DollarBill]: <Icon.DollarBill />,
};

export const FinancialHighlights = ({
    title,
    description,
    month,
    year,
    highlights,
}: HighlightsSection) => {
    return Object.keys(highlights || []).length > 0 ? (
        <Section title={title} secondaryBg>
            <p className="body-s -mt-6 mb-8">
                Last updated: {month} {year}
            </p>
            <p className="body-m mt-2">{description}</p>
            <div
                className={clsx(
                    "mt-10",
                    "grid grid-cols-1",
                    "gap-4",
                    "md:grid-cols-3",
                )}
            >
                {Object.values(highlights || []).map(
                    ({ icon, title, value }, index) => (
                        <StatBox
                            key={`${title}-${index}`}
                            icon={iconMappings[icon]}
                            title={title}
                            value={value}
                        />
                    ),
                )}
            </div>
        </Section>
    ) : null;
};
