import clsx from "clsx";
import { twMerge } from "tailwind-merge";

type SectionProps = {
    children: React.ReactNode;
    secondaryBg?: boolean;
    title?: string;
    testId?: string;
    className?: string;
};

export function Section({
    testId,
    className,
    children,
    title,
    secondaryBg,
}: SectionProps) {
    const sectionClasses = "hum-container pt-20 pb-20 px-8 lg:px-0";
    return (
        <section
            className={clsx(
                twMerge(
                    !secondaryBg && sectionClasses,
                    secondaryBg && "bg-secondary",
                    className,
                ),
            )}
            data-testid={testId}
        >
            <div className={clsx(secondaryBg && sectionClasses)}>
                {title && <h2 className="mb-8 text-left">{title}</h2>}
                {children}
            </div>
        </section>
    );
}
