import React from "react";
import { IconProps } from "../types";

const FreeAgentIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_4077)">
                        <path
                            d="M25.2522 14.458L23.2737 19.8092L25.1795 24.6244H21.5064L20.123 28.3248H26.6163L27.9065 31.5084H31.9964L25.2522 14.458Z"
                            fill="#7CC04A"
                        />
                        <path
                            d="M27.077 8.64684C23.3546 7.91333 21.5201 9.90547 20.8853 11.5302C20.7619 11.8428 20.6001 12.2664 19.7295 14.5465C19.0289 6.86448 11.7513 14.094 8.15097 10.0686C7.58335 13.5456 8.83101 13.9638 8.83101 13.9638C8.55954 15.0908 9.65501 16.3165 10.5544 16.1629C11.3579 19.9141 19.2661 14.5026 17.3973 20.6381C15.6191 25.2996 13.2554 31.4995 13.2554 31.4995H17.6976C17.6976 31.4995 23.8714 14.9701 24.2293 14.2037C24.7119 13.1741 26.2461 12.7723 27.4718 13.6361L29.065 9.23091C28.2451 8.90186 27.5788 8.74419 27.0756 8.64684H27.077ZM17.9225 16.4906C16.4993 14.4505 12.9236 17.3338 12.1558 15.0442C11.2495 15.2389 10.915 14.1927 11.1357 13.8212C11.1357 13.8212 10.383 13.7047 10.4667 12.6352C13.1964 14.445 18.4818 9.88902 17.9238 16.492L17.9225 16.4906Z"
                            fill="#007ABF"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_4077">
                            <rect
                                width="23.9934"
                                height="23.0186"
                                fill="white"
                                transform="translate(8.00293 8.49023)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default FreeAgentIcon;
