import clsx from "clsx";

export type TableRowProps = {
    children: React.ReactNode;
    testId?: string;
    className?: string;
};

export function TableRow({ children, testId, className }: TableRowProps) {
    return (
        <tr className={clsx(className)} data-testid={testId}>
            {children}
        </tr>
    );
}
