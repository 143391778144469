import React from "react";
import { IconProps } from "./types";
import clsx from "clsx";

const DownloadIcon = ({
    className,
    width = "24",
    height = "24",
    onClick,
    ...props
}: IconProps & { light?: boolean }) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined} {...props}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={"0 0 24 24"}
                    fill="none"
                    className={clsx(
                        "stroke-slate-400 hover:stroke-white",
                        className,
                    )}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M21 15V19C21 19.5304 20.7893 20.0391 20.4142 20.4142C20.0391 20.7893 19.5304 21 19 21H5C4.46957 21 3.96086 20.7893 3.58579 20.4142C3.21071 20.0391 3 19.5304 3 19V15"
                        stroke="currentStroke"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M7 10L12 15L17 10"
                        stroke="currentStroke"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M12 15V3"
                        stroke="currentStroke"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default DownloadIcon;
