export const getGreeting = () => {
    const today = new Date();
    const currentHour = today.getHours();

    if (currentHour < 12) {
        return "Good Morning";
    } else if (currentHour < 18) {
        return "Good Afternoon";
    } else {
        return "Good Evening";
    }
};
