import { useContext, useState } from "react";
import {
    GroupedOptionRawSelect,
    OptionRawSelect,
    RawSelect,
} from "./RawSelect";
import { FormContext } from "./Form";
import { InferType, ObjectSchema } from "yup";
import { FormState } from "./Form.types";
import { isRequired } from "./utils";

type SelectProps = {
    name: string;
    label: string;
    options: (OptionRawSelect | GroupedOptionRawSelect)[];
    placeholder?: string;
    className?: string;
    testId?: string;
    parent?: string;
    index?: number;
    disabled?: boolean;
    multiple?: boolean;
};

const flattenOptions = (
    options: (OptionRawSelect | GroupedOptionRawSelect)[],
) =>
    options.reduce<OptionRawSelect[]>((acc, option) => {
        if ("groupLabel" in option) {
            return [...acc, ...option.options];
        }
        return [...acc, option];
    }, []);

export function Select<T extends ObjectSchema<any>>({
    parent = "",
    multiple = false,
    ...props
}: SelectProps) {
    const [touched, setTouched] = useState(false);
    const name = parent ? `${parent}.${props.name}` : props.name;
    const form = useContext(FormContext) as FormState<T> | undefined;

    if (!form) return null;

    const flatOptions = flattenOptions(props.options);
    const selectedOptions = multiple
        ? form.values[name]
            ? flatOptions.filter((option) =>
                  form.values[name]?.includes(option.value),
              )
            : []
        : form.values[name]
          ? flatOptions.find((option) => option.value === form.values[name]) ||
            null
          : null;
    const error = form.errors?.[name] || "";
    const showError =
        touched && error && !error.toLowerCase().includes("required");

    return (
        <RawSelect
            {...props}
            name={name}
            disabled={props.disabled}
            testId={props.testId}
            className={props.className}
            label={props.label}
            placeholder={props.placeholder}
            options={props.options}
            selected={selectedOptions}
            multiple={multiple}
            onChange={(selected: OptionRawSelect | OptionRawSelect[]) => {
                if (multiple) {
                    form.updateValue(
                        name as InferType<T>,
                        (selected as OptionRawSelect[]).map(
                            (option) => option.value,
                        ),
                    );
                } else {
                    form.updateValue(
                        name as InferType<T>,
                        (selected as OptionRawSelect).value,
                    );
                }
            }}
            onBlur={() => setTouched(true)}
            errorMessage={showError ? form.errors?.[name] : undefined}
            required={isRequired(form.schema, name)}
            showErrorRing={Boolean(touched && error)}
        />
    );
}
