import { ExclamationTriangleIcon } from "@heroicons/react/24/solid";
import clsx from "clsx";

export function SearchError({ className }: { className?: string }) {
    return (
        <div className={clsx("flex items-center", className)}>
            <ExclamationTriangleIcon
                width={20}
                className="subdued mr-4"
                role="icon"
            />
            <p className="text-button subdued">Error fetching results</p>
        </div>
    );
}
