import { FilterState } from "@/utilityTypes";

export type FilterboxProps = {
    testId?: string;
    options: Option[];
    title: string;
    onChange: (state: FilterState) => void;
    className?: string;
    defaultSelections?: string[];
};

export type Option = {
    value: string;
    label: string;
};
