import React from "react";
import { IconProps } from "../types";

const KashooIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_4810)">
                        <path
                            d="M29.0817 28.9901L21.8896 19.9739L29.0557 11.0098H10.9189V28.9901H29.0817Z"
                            fill="#FFB700"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_4810">
                            <rect
                                width="18.1628"
                                height="17.9804"
                                fill="white"
                                transform="translate(10.9189 11.0098)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default KashooIcon;
