import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import { useFilterBox } from "./useFilterBox";
import { FilterboxProps } from "./Filterbox.types";
import clsx from "clsx";

const SELECT_ALL = "select-all";

export function Filterbox({
    testId,
    title,
    options,
    onChange,
    className,
    defaultSelections,
}: FilterboxProps) {
    const {
        selected,
        toggleSelectAll,
        selectAll,
        updateClickedOption,
        updateCheckedOption,
    } = useFilterBox({
        options,
        onChange,
        defaultSelections,
    });

    return (
        <div data-testid={testId} className={className}>
            <Listbox value={Array.from(selected)} multiple>
                <div className="relative mt-1">
                    <Listbox.Button className="w-full">
                        <p className="ui-element subdued cursor-pointer pr-10">
                            {title}
                        </p>

                        <span
                            className={clsx(
                                "pr-2",
                                "pointer-events-none absolute inset-y-0 right-0 flex items-center",
                            )}
                        >
                            <ChevronDownIcon
                                className="size-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition ease-in duration-100"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <Listbox.Options
                            className={clsx(
                                "bg-secondary z-10 mt-2 w-full p-2 py-1",
                                "ring-1 ring-inset ring-slate-300 dark:ring-slate-700",
                                "shadow-400 absolute right-0 min-w-[300px] overflow-auto rounded-lg",
                                "focus:outline-none sm:min-w-[348px]",
                            )}
                        >
                            <Listbox.Option
                                key={SELECT_ALL}
                                className={clsx(
                                    "p-2",
                                    "relative cursor-pointer select-none rounded",
                                    "hover:bg-default",
                                )}
                                value={SELECT_ALL}
                            >
                                <div className="flex w-full items-center justify-between">
                                    <div className="flex w-full items-center">
                                        <input
                                            data-testid={`${SELECT_ALL}-checkbox`}
                                            id={SELECT_ALL}
                                            type="checkbox"
                                            checked={selectAll}
                                            onChange={() => toggleSelectAll()}
                                            className={clsx(
                                                "bg-default",
                                                "border-2 border-gray-400",
                                                "size-4 rounded text-blue-500",
                                                "focus:ring-0 focus:ring-offset-0",
                                            )}
                                        />
                                        <label
                                            htmlFor={SELECT_ALL}
                                            className="w-full"
                                        >
                                            <p className="ui-element ml-4 block cursor-pointer">
                                                Select All
                                            </p>
                                        </label>
                                    </div>
                                </div>
                            </Listbox.Option>
                            {options.map((option) => (
                                <Listbox.Option
                                    key={option.value}
                                    className={clsx(
                                        "relative cursor-pointer select-none rounded",
                                        "hover:bg-default",
                                    )}
                                    onClick={() => updateClickedOption(option)}
                                    value={option.value}
                                >
                                    {({ selected: checked }) => (
                                        <div
                                            className={clsx(
                                                "w-full p-2",
                                                "group flex items-center justify-between",
                                            )}
                                        >
                                            <div className="flex items-center">
                                                <input
                                                    type="checkbox"
                                                    data-testid={`${option.value}-checkbox`}
                                                    checked={checked}
                                                    onChange={() =>
                                                        updateCheckedOption(
                                                            checked,
                                                            option,
                                                        )
                                                    }
                                                    className={clsx(
                                                        "bg-transparent",
                                                        "border-2 border-gray-400",
                                                        "size-4 rounded text-blue-500",
                                                        "focus:ring-0 focus:ring-offset-0",
                                                    )}
                                                />
                                                <label htmlFor={option.value}>
                                                    <p className="ui-element ml-4 block cursor-pointer">
                                                        {option.label}
                                                    </p>
                                                </label>
                                            </div>
                                            <p
                                                className={clsx(
                                                    "ui-element ml-4",
                                                    "hidden cursor-pointer",
                                                    "group-hover:block",
                                                )}
                                                data-testid={`${option.value}-ONLY`}
                                            >
                                                ONLY
                                            </p>
                                        </div>
                                    )}
                                </Listbox.Option>
                            ))}
                        </Listbox.Options>
                    </Transition>
                </div>
            </Listbox>
        </div>
    );
}
