export function Label({
    name,
    label,
    required = false,
}: {
    name: string;
    label: string;
    required?: boolean;
}) {
    return (
        <div className="flex gap-1">
            <label htmlFor={name}>{label}</label>
            {required && (
                <>
                    <p className="sr-only">{`${label} is required`}</p>
                    <p className="ui-element text-red-400">*</p>
                </>
            )}
        </div>
    );
}
