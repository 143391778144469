import React, { Fragment, useEffect } from "react";
import {
    ExclamationTriangleIcon,
    XMarkIcon,
    CheckCircleIcon,
    ExclamationCircleIcon,
    InformationCircleIcon,
    LightBulbIcon,
} from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { autoCapitalize } from "@/utils";

export enum ToastType {
    SUCCESS = "success",
    ERROR = "error",
    INFO = "info",
    WARNING = "warning",
    TIP = "tip",
}

export type ToastNotificationProps = {
    message: string;
    type?: ToastType;
    description?: string;
    onDismiss?: () => void;
    onUndo?: () => void;
    closeable?: boolean;
};

export function ToastNotification({
    type = ToastType.INFO,
    message,
    description,
    onDismiss,
    onUndo,
    closeable = true,
}: ToastNotificationProps) {
    const [show, setShow] = React.useState(false);
    const dismiss = () => {
        setShow(false);
        setTimeout(() => {
            onDismiss && onDismiss();
        }, 200);
    };
    const undo = () => {
        onUndo && onUndo();
        dismiss();
    };

    useEffect(() => {
        setShow(true);
        const timer = setTimeout(dismiss, 5000);
        return () => clearTimeout(timer);
    }, []);

    const iconByType = {
        [ToastType.SUCCESS]: (
            <CheckCircleIcon
                className="size-6 text-[#23922F]"
                aria-hidden="true"
            />
        ),
        [ToastType.ERROR]: (
            <ExclamationTriangleIcon
                className="size-6 text-[#C01F05]"
                aria-hidden="true"
            />
        ),
        [ToastType.INFO]: (
            <InformationCircleIcon
                className="size-6 text-brand-500"
                aria-hidden="true"
            />
        ),
        [ToastType.WARNING]: (
            <ExclamationCircleIcon
                className="size-6 text-[#E29036]"
                aria-hidden="true"
            />
        ),
        [ToastType.TIP]: (
            <LightBulbIcon
                className="size-6 text-[#3E4758]"
                aria-hidden="true"
            />
        ),
    };

    const textColorByType = {
        [ToastType.SUCCESS]: "text-[#23922F]",
        [ToastType.ERROR]: "text-[#EF463B]",
        [ToastType.INFO]: "text-[#2841ED]",
        [ToastType.WARNING]: "text-[#B36B27]",
        [ToastType.TIP]: "text-[#2A313F]",
    };

    return (
        <Transition
            show={show}
            as={Fragment}
            enter="transform ease-out duration-300 transition"
            enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
            enterTo="translate-y-0 opacity-100 sm:translate-x-0"
            leave="transition ease-out duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
        >
            <div
                className={clsx(
                    "w-full bg-white",
                    "ring-1 ring-black/5",
                    "shadow-default pointer-events-auto max-w-sm overflow-hidden rounded-lg",
                )}
            >
                <div className="p-4">
                    <div className="flex items-start">
                        <div className="shrink-0">{iconByType[type]}</div>
                        <div className="ml-3 w-0 flex-1 pt-0.5">
                            <h5
                                className={clsx(
                                    "heading-s",
                                    textColorByType[type],
                                )}
                            >
                                {autoCapitalize(message, true)}
                            </h5>
                            {description && (
                                <p
                                    className={clsx(
                                        "mt-1",
                                        "body-s subdued text-slate-900 first-letter:uppercase",
                                    )}
                                >
                                    {autoCapitalize(description, true)}
                                </p>
                            )}
                        </div>
                        <div className="ml-4 flex shrink-0">
                            {onUndo && type === ToastType.SUCCESS && (
                                <button
                                    type="button"
                                    className="mr-2 bg-none"
                                    onClick={undo}
                                >
                                    <p className="ui-element cursor-pointer text-[#3E4758]">
                                        Undo
                                    </p>
                                </button>
                            )}
                            {closeable && (
                                <button
                                    type="button"
                                    className="inline-flex rounded-md !bg-transparent"
                                    onClick={dismiss}
                                >
                                    <span className="sr-only">Close</span>
                                    <XMarkIcon
                                        className="size-6 fill-slate-900"
                                        aria-hidden="true"
                                    />
                                </button>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </Transition>
    );
}
