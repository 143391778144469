import { useApiMutation } from "@/api";
import { sendVerificationEmail } from "@/entities/User";
import { Onboarding } from "@/features/Onboarding";
import { Illustration, Spinner } from "@/ui";
import { useEffect } from "react";
import { useAtom } from "jotai/react";
import { emailSentAtom } from "@/utils/misc/atoms";
import { useSession } from "@/entities/Session";

export default function EmailVerificationPage() {
    const [emailSent, setEmailSent] = useAtom(emailSentAtom);
    const { mutate: verifyEmail, isPending } = useApiMutation(
        sendVerificationEmail,
    );
    const session = useSession();
    const email = session.user?.email;

    useEffect(() => {
        if (!emailSent) {
            verifyEmail();
            setEmailSent(true);
        }
    }, [emailSent]);

    return (
        <div>
            <Illustration.VerifyEmail className="mx-auto mt-0 size-48" />
            <Onboarding.TextBlock
                title="Verify your email"
                description={`We’ve sent a verification email to ${email}. Click the
                    link inside to continue.`}
            />
            <button
                className="xl w-full"
                onClick={() => verifyEmail()}
                disabled={isPending}
            >
                {isPending ? "Sending" : "Resend Email"}
                {isPending && <Spinner />}
            </button>
        </div>
    );
}
