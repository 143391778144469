import React from "react";
import { IconProps } from "./types";

const CircleStarIcon = ({
    className,
    width = "36",
    height = "36",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="36" height="36" rx="18" fill="#1F2630" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18.8679 10.623C18.5468 9.85094 17.4532 9.85094 17.1321 10.623L15.3013 15.0247L10.5492 15.4057C9.71574 15.4725 9.37779 16.5126 10.0128 17.0566L13.6334 20.158L12.5272 24.7952C12.3332 25.6085 13.218 26.2514 13.9316 25.8155L18 23.3305L22.0684 25.8155C22.782 26.2514 23.6668 25.6085 23.4728 24.7952L22.3666 20.158L25.9872 17.0566C26.6222 16.5126 26.2842 15.4725 25.4508 15.4057L20.6987 15.0247L18.8679 10.623Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleStarIcon;
