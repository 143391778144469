import clsx from "clsx";
import { twMerge } from "tailwind-merge";

type PageHeadingProps = {
    title: string;
    children?: React.ReactNode;
    testId?: string;
    className?: string;
};

export function PageHeading({
    title,
    children,
    testId,
    className,
}: PageHeadingProps) {
    return (
        <div
            className={clsx(
                twMerge(
                    "flex flex-col justify-between gap-4 sm:flex-row sm:items-center",
                    className,
                ),
            )}
            data-testid={testId}
        >
            <h4 className="w-1/2">{title}</h4>
            <div
                className={clsx(
                    "flex flex-col",
                    "gap-4",
                    "sm:flex-row sm:items-center",
                )}
            >
                {children}
            </div>
        </div>
    );
}
