export const NoResults = ({ className }: { className?: string }) => {
    return (
        <svg
            width="123"
            height="96"
            viewBox="0 0 123 96"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            className={className}
        >
            <g filter="url(#filter0_d_791_8555)">
                <rect
                    x="6"
                    y="10"
                    width="103.648"
                    height="69.3586"
                    rx="5"
                    fill="#09168E"
                />
                <g filter="url(#filter1_b_791_8555)">
                    <rect
                        x="14.5724"
                        y="18.5723"
                        width="104.428"
                        height="69.3586"
                        rx="5"
                        fill="#DDE5FE"
                        fillOpacity="0.85"
                    />
                </g>
                <rect
                    x="44"
                    y="64"
                    width="19"
                    height="18"
                    rx="3"
                    fill="#2042F6"
                />
                <rect
                    x="68"
                    y="46"
                    width="20"
                    height="36"
                    rx="3"
                    fill="#2042F6"
                />
                <rect
                    x="93"
                    y="27"
                    width="19"
                    height="55"
                    rx="3"
                    fill="#2042F6"
                />
                <g filter="url(#filter2_bd_791_8555)">
                    <rect
                        x="4"
                        y="4"
                        width="54"
                        height="49"
                        rx="5"
                        fill="white"
                    />
                </g>
                <path
                    d="M30.9843 44.611C39.8832 44.611 47.2284 37.2659 47.2284 28.3827C47.2284 19.4994 39.8675 12.1543 30.9686 12.1543C22.0854 12.1543 14.7559 19.4994 14.7559 28.3827C14.7559 37.2659 22.1011 44.611 30.9843 44.611ZM30.6076 31.6158C29.5875 31.6158 29.0225 31.1449 29.0225 30.2033V30.0306C29.0225 28.5553 29.87 27.7235 31.0314 26.9074C32.4125 25.95 33.0874 25.4321 33.0874 24.4276C33.0874 23.3447 32.2556 22.6227 30.9686 22.6227C30.0269 22.6227 29.3363 23.0935 28.8184 23.894C28.3162 24.459 28.0808 24.9455 27.1391 24.9455C26.37 24.9455 25.7422 24.4433 25.7422 23.6586C25.7422 23.3447 25.805 23.0622 25.9149 22.7796C26.4328 21.2416 28.3162 19.986 31.1255 19.986C34.0448 19.986 36.5088 21.5398 36.5088 24.2706C36.5088 26.154 35.473 27.0957 33.8407 28.1473C32.8049 28.8221 32.2085 29.3871 32.1614 30.2347C32.1614 30.2817 32.1457 30.3602 32.1457 30.423C32.0986 31.0979 31.5179 31.6158 30.6076 31.6158ZM30.5919 36.6381C29.5247 36.6381 28.6458 35.8691 28.6458 34.8332C28.6458 33.7974 29.509 33.0283 30.5919 33.0283C31.6592 33.0283 32.5224 33.7974 32.5224 34.8332C32.5224 35.8848 31.6435 36.6381 30.5919 36.6381Z"
                    fill="#92ABFA"
                />
            </g>
            <defs>
                <filter
                    id="filter0_d_791_8555"
                    x="0"
                    y="4"
                    width="123"
                    height="91.9307"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_791_8555"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_791_8555"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_b_791_8555"
                    x="10.5724"
                    y="14.5723"
                    width="112.428"
                    height="77.3584"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_791_8555"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_791_8555"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_bd_791_8555"
                    x="-6"
                    y="-6"
                    width="74"
                    height="69"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="5" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_791_8555"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="1"
                        operator="dilate"
                        in="SourceAlpha"
                        result="effect2_dropShadow_791_8555"
                    />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="2.5" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.15 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_791_8555"
                        result="effect2_dropShadow_791_8555"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_791_8555"
                        result="shape"
                    />
                </filter>
            </defs>
        </svg>
    );
};
