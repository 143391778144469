import { Layout } from "./Layout";
import { Steps } from "./Steps";
import { Step } from "./Step";
import { Header } from "./Header";
import { Video } from "./Video";
import { TextBlock } from "./TextBlock";
import { MobileStep, MobileSteps } from "./MobileSteps";

export const Onboarding = {
    Layout,
    Step,
    Steps,
    Header,
    Video,
    TextBlock,
    MobileSteps,
    MobileStep,
};
