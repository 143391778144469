import clsx from "clsx";
import React from "react";

export function StickyFooter({
    testId,
    children,
}: {
    testId?: string;
    children: React.ReactNode;
}) {
    return (
        <div
            className={clsx(
                "bg-default border-default z-30 w-full py-4",
                "border-t",
                "fixed bottom-0",
            )}
            data-testid={testId}
        >
            <div
                className={clsx(
                    "mx-4",
                    "flex flex-col",
                    "justify-center",
                    "sm:mx-0 sm:flex-row",
                )}
            >
                {children}
            </div>
        </div>
    );
}
