import React from "react";
import { IconProps } from "../types";

const QuickbooksIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_3927)">
                        <path
                            d="M7 20C7 12.8212 12.8212 7 20 7C27.1788 7 33 12.8212 33 20C33 27.1788 27.1788 33 20 33C12.8212 33 7 27.1788 7 20Z"
                            fill="#2DA44A"
                        />
                        <path
                            d="M24.3332 14.947H23.6133V16.8257H24.3332C26.0885 16.8257 27.5078 18.2518 27.5078 20.0002C27.5078 21.7555 26.0817 23.1748 24.3332 23.1748H22.5985C22.5985 23.1748 22.5985 13.5414 22.5985 13.3494C22.5985 12.3141 21.7552 11.4707 20.7198 11.4707V25.0467C20.7198 25.0467 22.6602 25.0467 24.3332 25.0467C27.1239 25.0467 29.3865 22.784 29.3865 19.9934C29.3934 17.2096 27.1239 14.947 24.3332 14.947ZM10.6064 20.0002C10.6064 22.7909 12.8691 25.0535 15.6597 25.0535H16.3797V23.1748H15.6597C13.9044 23.1748 12.4851 21.7487 12.4851 20.0002C12.4851 18.245 13.9113 16.8257 15.6597 16.8257H17.3944C17.3944 16.8257 17.3944 26.4591 17.3944 26.6511C17.3944 27.6864 18.2378 28.5298 19.2731 28.5298V14.9538C19.2731 14.9538 17.3327 14.9538 15.6597 14.9538C12.876 14.947 10.6064 17.2096 10.6064 20.0002Z"
                            fill="white"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_3927">
                            <rect
                                width="26"
                                height="26"
                                fill="white"
                                transform="translate(7 7)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default QuickbooksIcon;
