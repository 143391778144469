import React from "react";
import { IconProps } from "../types";

const ExactIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M31.0003 12.002H8.99902V18.002H31.0003V12.002ZM31.0003 21.9998H8.99902V27.9995H31.0003V21.9998Z"
                        fill="#E2161F"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ExactIcon;
