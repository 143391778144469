import React from "react";
import clsx from "clsx";

type NumberedStepProps = {
    testId?: string;
    number: number;
    children: React.ReactNode;
};

export function NumberedStep({ testId, number, children }: NumberedStepProps) {
    return (
        <div className="mb-6 flex items-start last:mb-0" data-testid={testId}>
            <p
                className={clsx(
                    "body-micro bg-primary-500 mr-4 p-4",
                    "border-default border",
                    "flex size-8 items-center justify-center rounded-full text-white",
                )}
            >
                {number}
            </p>
            {children}
        </div>
    );
}
