import clsx from "clsx";

type PillBoxProps = {
    testId?: string;
    className?: string;
    children?: React.ReactNode;
};

export function PillBox({ testId, className, children }: PillBoxProps) {
    return (
        <div
            className={clsx(
                "border-default flex rounded-full border p-6",
                className,
            )}
            data-testid={testId}
        >
            {children}
        </div>
    );
}
