import React from "react";
import { IconProps } from "./types";

const PencilIcon = ({
    className,
    width = "24",
    height = "24",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={"0 0 24 24"}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.36428 17.0123L3.10249 20.1668C2.93923 20.5749 3.34427 20.98 3.75243 20.8167L6.90689 19.5549C7.40986 19.3537 7.86671 19.0525 8.24976 18.6694L19.1689 7.75072C19.9974 6.92229 19.9974 5.57914 19.1689 4.75072C18.3405 3.92229 16.9974 3.92229 16.1689 4.75071L5.24976 15.6694C4.86671 16.0525 4.56546 16.5093 4.36428 17.0123Z"
                        fill="currentColor"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default PencilIcon;
