import { useSession } from "@/entities/Session";
import { Spinner } from "@/ui";
import { useEffect } from "react";
import { Navigate } from "react-router-dom";

function SignupCompletePage() {
    const { user } = useSession();

    useEffect(() => {
        window.location.reload();
    }, []);

    if (user) return <Navigate to="/" />;

    // TODO: add timeout
    return <Spinner fullScreen />;
}

export default SignupCompletePage;
