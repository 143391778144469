import { autoCapitalize } from "@/utils";
import clsx from "clsx";
import React from "react";

type MatchCardProps = {
    testId?: string;
    title: string;
    description?: string;
    onClick?: () => void;
    children: React.ReactNode;
};
type MatchCardContentProps = {
    location: string;
    industryVertical: string;
    targetRaise: string;
    timeFrame: string;
};

export function MatchCard({
    testId,
    title,
    description,
    children,
    onClick,
}: MatchCardProps) {
    return (
        <div
            className={clsx(
                "mt-8 h-full bg-gray-600 p-6",
                "flex flex-col",
                "rounded-lozenge box-border cursor-pointer",
                "hover:bg-[#96b2ce1f] md:min-h-44 md:flex-row",
            )}
            data-testid={testId}
            onClick={onClick}
        >
            <div
                className={clsx(
                    "w-full pt-5",
                    "border-[#677283]",
                    "max-md:border-b max-md:pb-5 md:w-[248px] md:max-w-[248px] md:border-r md:pr-6",
                )}
            >
                <h4>{title}</h4>
                {description && (
                    <p className="body-s subdued mt-2">
                        {description.length > 90
                            ? autoCapitalize(
                                  `${description.slice(0, 90)}...`,
                                  true,
                              )
                            : autoCapitalize(description, true)}
                    </p>
                )}
            </div>
            {children}
        </div>
    );
}

MatchCard.Content = ({
    location,
    industryVertical,
    targetRaise,
    timeFrame,
}: MatchCardContentProps) => (
    <div
        className={clsx(
            "grid grid-cols-2",
            "flex-1 gap-4",
            "max-md:mt-4 md:pl-7",
        )}
    >
        <div className="col-span-2 md:col-span-1 ">
            <p className="body-micro subdued">Location</p>
            <p className="body-s">{location}</p>
        </div>
        <div className="col-span-2 md:col-span-1 ">
            <p className="body-micro subdued">Industry Vertical</p>
            <p className="body-s">{industryVertical}</p>
        </div>
        <div className="col-span-2 md:col-span-1 ">
            <p className="body-micro subdued">Target raise</p>
            <p className="body-s">{targetRaise}</p>
        </div>
        <div className="col-span-2 md:col-span-1 ">
            <p className="body-micro subdued">Time frame</p>
            <p className="body-s">{timeFrame}</p>
        </div>
    </div>
);
