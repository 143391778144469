type ProgressBarProps = {
    testId?: string;
};

export function ProgressBar({ testId }: ProgressBarProps) {
    return (
        <div className="block" data-testid={testId}>
            <div className="progress-bar"></div>
            <p className="subdued body-s ml-1 mt-4">
                Gathering data. This process should only take a couple of
                minutes. Please wait...
            </p>
        </div>
    );
}
