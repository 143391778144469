import clsx from "clsx";

type SubHeadingAccessoryProps = {
    text: string;
    onClick?: () => void;
    testId?: string;
    className?: string;
};

export function SubHeadingAccessory({
    text,
    onClick,
    testId,
    className,
}: SubHeadingAccessoryProps) {
    if (onClick) {
        return (
            <a
                role="button"
                onClick={onClick}
                className={clsx("subdued pl-1", className)}
                data-testid={testId}
            >
                {text}
            </a>
        );
    }

    return (
        <p
            className={clsx(className, "body-micro subdued")}
            data-testid={testId}
        >
            {text}
        </p>
    );
}
