export enum CompanyType {
    Admin = "admin",
    Investor = "investor",
    SyndicationInvestor = "syndication_investor",
    Company = "company",
    BalanceSheetPartner = "balance_sheet_partner",
}

export enum ActivationStatus {
    ReadyForSyndication = "ready_for_syndication",
    NotReadyForSyndication = "not_ready_for_syndication",
}

export enum CompanySegment {
    Mice = "mice",
    Boar = "boar",
    Whale = "whale",
}

export enum CompanyContactStatus {
    Active = "active",
    Invited = "invited",
    Disabled = "disabled",
}

export enum CompanyAnalyticsStatus {
    pending = "pending", // waiting on first company analysis run
    critical = "critical", // last company analysis failed
    needsLabeling = "needs_labeling", // based on company.labeling_completed status
    analyticsRegression = "regression", // at least one chart is missing in the most recent run that appeared in the previous run
    analyticsStale = "analytics_stale", // analytics exist but new data asset arrived
    dataStale = "data_stale", // analytics exist but no new data asset in the last 7 days
    healthy = "healthy", // last run completed successfully with no unknown errors and is not stale
    recovering = "recovering", // was regressed but no longer
    waitingForData = "waiting_for_data",
}

export enum BulkDownloadStatus {
    Fake = "FAKE",
    Started = "STARTED",
    Pending = "PENDING",
    Progress = "PROGRESS",
    Success = "SUCCESS",
    Failure = "FAILURE",
    Revoked = "REVOKED",
}

export enum BusinessType {
    ConsumerProducts = "consumer_products",
    ConsumerFinance = "consumer_finance",
    App = "app",
    Retail = "retail",
    Services = "services",
    SaasPositiveChurn = "saas_positive_churn",
    SaasNegativeChurn = "saas_negative_churn",
    CommercialFinance = "commercial_finance",
    Subscriptions = "subscriptions",
    Other = "other",
    Manufacturer = "manufacturer",
    Media = "media",
    Marketplace = "marketplace",
    Gaming = "gaming",
    ECommerce = "e_commerce",
    Health = "health",
    HealthIT = "health_it",
}

export const BUSINESS_TYPES = {
    [BusinessType.ConsumerProducts]: "Consumer Product",
    [BusinessType.ConsumerFinance]: "Consumer Finance",
    [BusinessType.App]: "Mobile App",
    [BusinessType.Retail]: "Retail or distribution",
    [BusinessType.Services]: "Service",
    [BusinessType.SaasPositiveChurn]: "SaaS",
    [BusinessType.CommercialFinance]: "Specialty finance or lending",
    [BusinessType.Subscriptions]: "Subscription",
    [BusinessType.Other]: "Other",
    [BusinessType.Manufacturer]: "Manufacturer",
    [BusinessType.Media]: "Media",
    [BusinessType.Marketplace]: "Marketplace",
    [BusinessType.Gaming]: "Gaming",
    [BusinessType.ECommerce]: "E-Commerce",
    [BusinessType.Health]: "Health",
    [BusinessType.HealthIT]: "Health IT",
};

export enum OnboardingStatus {
    VERIFY_EMAIL,
    BASIC_INFO,
    ABOUT_YOUR_BUSINESS,
    UNSUPPORTED_COUNTRY,
    SHARE_YOUR_GOALS,
    FUNDRAISING_HISTORY,
    MNDA,
    DATAROOM,
    COMPLETE,
}

export enum CompanyFileDocumentType {
    Other = "other",
    TermSheet = "term_sheet",
    Loan = "loan",
    IncomeStatement = "income_statement", // same as P&L rename later
    BalanceSheet = "balance_sheet",
    CustomerTape = "customer_tape", // same as transaction data
    CashFlowStatement = "cash_flow_statement",
    LogoCompany = "logo_company",
    CapTable = "cap_table",
    FinancialStatementMapping = "financial_statement_mapping",
    CapitalMachineModelOutput = "capital_machine_model_output",
    DocusignMnda = "docusign_mnda",
    CompanyMnda = "company_mnda",
    CleanedIncomeStatement = "cleaned_income_statement",
    CleanedBalanceSheet = "cleaned_balance_sheet",
    CleanedCustomerTransactions = "cleaned_customer_tape",
    FinancialSummary = "financial_summary",
    PorfolioMonitoringImportSource = "porfolio_monitoring_import_source",
    OutOfFeeProof = "out_of_fee_proof",
    SyndicationLegalAgreement = "syndication_legal_agreement",
    BusinessOverviewDeck = "business_overview_deck",
    FeeAgreement = "fee_tos_agreement",
    ChartbookInvestorAnalytics = "chartbook_investor_analytics",
}

export enum FileStatus {
    AwaitingUpload = "awaiting_upload",
    Success = "success",
    Failed = "failed",
    Pending = "pending",
    Processing = "processing",
}
