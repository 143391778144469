export type SelectOption = {
    label: string;
    value: string;
};

type KeyValueOptions = {
    [identifier: string]: string;
};

export const selectOptionsFromMap = (
    options: KeyValueOptions,
): SelectOption[] =>
    Object.entries(options).map(([value, label]) => ({
        label,
        value,
    }));
