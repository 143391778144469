import { Card } from "@/ui/atoms";
import clsx from "clsx";

type CardSkeletonProps = {
    testId?: string;
    className?: string;
};

export function CardSkeleton({ testId, className }: CardSkeletonProps) {
    return (
        <Card className={clsx("animate-pulse", className)} data-testid={testId}>
            <div className="flex-1 space-y-6 py-1">
                <div className="h-2 rounded bg-slate-700"></div>
                <div className="space-y-3">
                    <div className="grid grid-cols-3 gap-4">
                        <div className="col-span-2 h-2 rounded bg-slate-700"></div>
                        <div className="col-span-1 h-2 rounded bg-slate-700"></div>
                    </div>
                    <div className="h-2 rounded bg-slate-700"></div>
                </div>
            </div>
        </Card>
    );
}
