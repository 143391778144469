import { Icon } from "../../atoms";
import React from "react";
import { getNotificationConfig } from "./getNotificationConfig";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

export enum NotificationType {
    COMPLETED = "COMPLETED",
    NEW = "NEW",
    WARNING = "WARNING",
    // SUCCESS = 'SUCCESS', No designs yet
    // INFO = 'INFO', No designs yet
    // ERROR = 'ERROR', No designs yet
}

export interface InlineNotificationProps {
    type: NotificationType;
    children: React.ReactNode;
    arrow?: boolean;
    onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
    testId?: string;
    className?: string;
    sticky?: boolean;
}

export const InlineNotification = ({
    type,
    children,
    arrow,
    onClick,
    testId,
    className,
    sticky,
}: InlineNotificationProps) => {
    const config = getNotificationConfig(type);
    if (!config) return null;

    return (
        <div
            className={twMerge(
                config.bg,
                arrow && "cursor-pointer",
                "mb-10 rounded-xl p-4",
                sticky ? "sticky top-0 w-full" : "relative",
                className,
            )}
            data-testid={testId}
            onClick={(e) => {
                if (onClick && arrow) onClick(e);
            }}
        >
            <div className="flex items-center justify-between">
                <div className="flex items-center justify-start">
                    {React.cloneElement(config.icon, { className: "mr-4" })}
                    <h5
                        className={clsx(
                            "heading-s",
                            arrow && "cursor-pointer",
                            "text-[#151C23]",
                        )}
                    >
                        {children}
                    </h5>
                </div>

                {arrow && !config.noIcon && (
                    <Icon.ArrowRight
                        className="text-[#151C23]"
                        height="20"
                        width="20"
                    />
                )}
            </div>
        </div>
    );
};
