import React from "react";
import { IconProps } from "./types";

const ChevronRightIcon = ({
    className,
    width = "24",
    height = "24",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M7.68818 9.33438C7.98676 9.04728 8.46154 9.05659 8.74863 9.35517L12.458 13.2929L16.1674 9.35517C16.4545 9.05659 16.9293 9.04728 17.2278 9.33438C17.5264 9.62147 17.5357 10.0963 17.2486 10.3948L12.9986 14.8948C12.8572 15.0419 12.662 15.125 12.458 15.125C12.254 15.125 12.0588 15.0419 11.9174 14.8948L7.66738 10.3948C7.38029 10.0963 7.3896 9.62147 7.68818 9.33438Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ChevronRightIcon;
