import React from "react";
import { IconProps } from "./types";

const InvestorSignupStepThree = ({
    className,
    width = "200",
    height = "184",
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="currentColor"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1602_19672)">
                <rect
                    x="33.2126"
                    y="35.796"
                    width="148.225"
                    height="127.872"
                    rx="3.9783"
                    fill="#1F2630"
                />
                <g opacity="0.75">
                    <path
                        d="M47.8633 155.442V117.654C47.8633 117.396 48.0719 117.188 48.3293 117.188H57.1643C57.4216 117.188 57.6303 117.396 57.6303 117.654V155.442H47.8633Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M62.5139 155.442V113.584C62.5139 113.327 62.7226 113.118 62.9799 113.118H71.001C71.2584 113.118 71.467 113.327 71.467 113.584V155.442H62.5139Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M76.3503 155.442V116.84C76.3503 116.582 76.559 116.374 76.8164 116.374H85.6513C85.9087 116.374 86.1174 116.582 86.1174 116.84V155.442H76.3503Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M90.187 155.442V107.073C90.187 106.815 90.3957 106.607 90.653 106.607H99.488C99.7454 106.607 99.954 106.815 99.954 107.073V155.442H90.187Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M104.838 155.442V94.8641C104.838 94.6067 105.046 94.3981 105.304 94.3981H113.325C113.582 94.3981 113.791 94.6067 113.791 94.8641V155.442H104.838Z"
                        fill="#2042F6"
                    />
                    <path
                        d="M118.177 155.441V78.6434C118.177 78.3861 118.385 78.1774 118.643 78.1774H127.586C127.844 78.1774 128.052 78.3861 128.052 78.6434V155.441H118.177Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M132.991 155.441V71.6191C132.991 71.3617 133.199 71.1531 133.457 71.1531H142.401C142.658 71.1531 142.867 71.3617 142.867 71.6191V155.441H132.991Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M146.98 155.441V63.8151C146.98 63.5577 147.189 63.3491 147.446 63.3491H156.39C156.647 63.3491 156.856 63.5577 156.856 63.8151V155.441H146.98Z"
                        fill="#AEC3FC"
                    />
                    <path
                        d="M161.794 155.441V57.5712C161.794 57.3138 162.003 57.1052 162.26 57.1052H171.204C171.461 57.1052 171.67 57.3138 171.67 57.5712V155.441H161.794Z"
                        fill="#AEC3FC"
                    />
                </g>
                <g filter="url(#filter0_bd_1602_19672)">
                    <rect
                        x="1.87671"
                        y="20.3316"
                        width="62.6717"
                        height="62.6717"
                        rx="3.9783"
                        fill="#343C47"
                        fillOpacity="0.5"
                        shapeRendering="crispEdges"
                    />
                </g>
                <g filter="url(#filter1_d_1602_19672)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M33.2125 75.678C46.4731 75.678 57.223 64.9281 57.223 51.6674C57.223 38.4067 46.4731 27.6568 33.2125 27.6568C19.9518 27.6568 9.2019 38.4067 9.2019 51.6674C9.2019 64.9281 19.9518 75.678 33.2125 75.678ZM33.2125 66.7249C41.5285 66.7249 48.27 59.9834 48.27 51.6674C48.27 43.3514 41.5285 36.6099 33.2125 36.6099C24.8965 36.6099 18.155 43.3514 18.155 51.6674C18.155 59.9834 24.8965 66.7249 33.2125 66.7249Z"
                        fill="#343E4F"
                    />
                </g>
                <mask
                    id="mask0_1602_19672"
                    style={{ maskType: "alpha" }}
                    maskUnits="userSpaceOnUse"
                    x="9"
                    y="27"
                    width="49"
                    height="49"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M33.2125 75.678C46.4731 75.678 57.223 64.9281 57.223 51.6674C57.223 38.4067 46.4731 27.6568 33.2125 27.6568C19.9518 27.6568 9.2019 38.4067 9.2019 51.6674C9.2019 64.9281 19.9518 75.678 33.2125 75.678ZM33.2125 66.7249C41.5285 66.7249 48.27 59.9834 48.27 51.6674C48.27 43.3514 41.5285 36.6099 33.2125 36.6099C24.8965 36.6099 18.155 43.3514 18.155 51.6674C18.155 59.9834 24.8965 66.7249 33.2125 66.7249Z"
                        fill="#4A66F4"
                    />
                </mask>
                <g mask="url(#mask0_1602_19672)">
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M32.8057 27.6568H7.57422V75.678H24.4971L23.943 76.6377L48.1449 90.6107L60.7606 68.7596L36.5587 54.7867L32.8057 61.2871V27.6568Z"
                        fill="#4A66F4"
                    />
                </g>
                <rect
                    opacity="0.7"
                    x="104.837"
                    y="93.5841"
                    width="8.95309"
                    height="61.8577"
                    fill="#677283"
                />
                <g filter="url(#filter2_bd_1602_19672)">
                    <rect
                        x="108.093"
                        y="83.0032"
                        width="69.9969"
                        height="21.9758"
                        rx="1.80973"
                        fill="#343C47"
                    />
                </g>
                <line
                    x1="167.439"
                    y1="90.4194"
                    x2="114.314"
                    y2="90.4194"
                    stroke="white"
                    strokeWidth="1.80973"
                    strokeLinecap="round"
                />
                <line
                    x1="170.097"
                    y1="94.489"
                    x2="114.314"
                    y2="94.489"
                    stroke="white"
                    strokeWidth="1.80973"
                    strokeLinecap="round"
                />
                <line
                    x1="157.692"
                    y1="98.5586"
                    x2="114.314"
                    y2="98.5586"
                    stroke="white"
                    strokeWidth="1.80973"
                    strokeLinecap="round"
                />
            </g>
            <defs>
                <filter
                    id="filter0_bd_1602_19672"
                    x="-1.74276"
                    y="16.7121"
                    width="69.9106"
                    height="73.5301"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="1.80973"
                    />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1602_19672"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.61947" />
                    <feGaussianBlur stdDeviation="1.80973" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_1602_19672"
                        result="effect2_dropShadow_1602_19672"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1602_19672"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_1602_19672"
                    x="5.58244"
                    y="27.6568"
                    width="55.2602"
                    height="55.2601"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.61947" />
                    <feGaussianBlur stdDeviation="1.80973" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1602_19672"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1602_19672"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_bd_1602_19672"
                    x="104.474"
                    y="79.3837"
                    width="77.2358"
                    height="32.8342"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur
                        in="BackgroundImageFix"
                        stdDeviation="1.80973"
                    />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1602_19672"
                    />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="3.61947" />
                    <feGaussianBlur stdDeviation="1.80973" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_backgroundBlur_1602_19672"
                        result="effect2_dropShadow_1602_19672"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect2_dropShadow_1602_19672"
                        result="shape"
                    />
                </filter>
                <clipPath id="clip0_1602_19672">
                    <rect width="200" height="184" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default InvestorSignupStepThree;
