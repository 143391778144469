import React, { Fragment } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Icon } from "../Icon";
import clsx from "clsx";
export enum ItemTextPosition {
    LEFT = "left",
    RIGHT = "right",
}
export type MoreButtonItemsType = {
    label: string;
    className?: string;
    onClick: () => void;
    position?: ItemTextPosition;
};

export function MoreButton({
    items,
    disabled,
}: {
    items: MoreButtonItemsType[];
    disabled?: boolean;
}) {
    return (
        <Menu as="div" className="relative inline-block text-left">
            <div>
                <Menu.Button
                    data-testid="morebutton"
                    className={clsx(
                        "w-full cursor-pointer !bg-transparent px-4 py-2",
                        "text-sm text-gray-700",
                        "inline-flex justify-center font-medium",
                    )}
                    disabled={disabled}
                >
                    <Icon.TripleDot className="text-icon-subdued -rotate-90" />
                </Menu.Button>
            </div>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
            >
                <Menu.Items
                    className={clsx(
                        "bg-surface-default z-10 mt-2 w-56",
                        "shadow-default absolute right-0 origin-top-right rounded-md",
                    )}
                >
                    {items.map((item) => (
                        <Menu.Item key={item.label}>
                            <button
                                onClick={() => {
                                    item.onClick();
                                }}
                                className={clsx(
                                    "md secondary my-1 w-full",
                                    item.position === "right"
                                        ? "justify-end"
                                        : "justify-start",
                                    !item.position && "justify-center",
                                )}
                            >
                                <p className="ui-element cursor-pointer text-left">
                                    {item.label}
                                </p>
                            </button>
                        </Menu.Item>
                    ))}
                </Menu.Items>
            </Transition>
        </Menu>
    );
}
