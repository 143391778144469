import { ElementType, memo } from "react";
import { NavBarState } from "./Navigation.types";
import { withNavigation } from "./withNavigation";

export type PageConfig = {
    testId?: string;
} & NavBarState;

export function asPage<T = any>(
    Page: ElementType,
    config: Partial<PageConfig> = {},
) {
    return memo(function PageWithHOCs(props: T) {
        return withNavigation(
            Page,
            config,
        )({
            ...props,
            "data-testid": config.testId,
        });
    });
}
