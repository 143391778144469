import React from "react";

type Props = {
    className?: string;
    children: React.ReactNode;
    ref?: any;
};

export const Scrollable = React.forwardRef<HTMLDivElement, Props>(
    ({ className, children }, ref) => {
        return (
            <div
                ref={ref}
                className={`custom-scrollbar z-10 mb-12 size-full overflow-y-scroll ${className}`}
            >
                {children}
            </div>
        );
    },
);
