import * as d3 from "d3";
import { withChartRenderer } from "./withChartRenderer";
import { IDrawFunctionArgs, IBoxPlotData } from "./Chart.types";

export function drawBoxPlot({
    width,
    height,
    data,
    id,
}: IDrawFunctionArgs<IBoxPlotData[]>) {
    // Calculate the maximum value in the data
    const maxDataValue = d3.max(data, (d) => d.q4)!;
    const minDataValue = d3.min(data, (d) => d.q0)!;

    //////////////////////////////////////////////////////////////////
    // Create SVG container
    //////////////////////////////////////////////////////////////////

    // Set up dimensions and margins
    const margin = { top: 5, right: 20, bottom: 20, left: 20 };
    width = width - margin.left - margin.right;
    height = height - margin.top - margin.bottom;

    d3.select("#" + id)
        .selectAll("svg")
        .remove();

    // Create an SVG container
    const svg = d3
        .select("#" + id)
        .attr("data-testid", id + ":box-plot")
        .append("svg")
        .attr("width", width + margin.left + margin.right)
        .attr("height", height + margin.top + margin.bottom)
        .append("g")
        .attr("transform", `translate(${margin.left},${margin.top})`);
    //.append("g")
    ///.attr("transform", `translate(${margin.left},${margin.top})`);

    //////////////////////////////////////////////////////////////////
    // Y AXIS
    //////////////////////////////////////////////////////////////////

    // Calculate tick values for the y-axis
    const tickValues = [0];
    for (let i = 1; i <= 4; i++) {
        tickValues.push((maxDataValue / 4) * i);
    }

    // Create a scale for the y-axis (values)
    const yScale = d3
        .scaleLinear()
        .domain([minDataValue, maxDataValue])
        .nice()
        .range([height, 0]);

    // Add horizontal grid lines
    const yAxisGrid = d3
        .axisLeft(yScale)
        .tickValues(tickValues)
        .tickSize(-width);

    // Style y axis lines
    svg.append("g").attr("class", "y-axis-grid").call(yAxisGrid);
    svg.selectAll(".y-axis-grid line").attr("stroke", "#3E4758");
    svg.selectAll(".y-axis-grid text").attr("fill", "#CBD5E1");
    svg.selectAll(".y-axis-grid text").attr("dx", -2);
    svg.selectAll(".y-axis-grid path").remove();
    svg.selectAll(".y-axis-grid .tick:first-of-type line").attr(
        "stroke-width",
        "4px",
    );

    //////////////////////////////////////////////////////////////////
    // X AXIS
    //////////////////////////////////////////////////////////////////

    // Create a scale for the x-axis (categories)
    const xScale = d3
        .scaleBand()
        .domain(data.map((d) => d.name))
        .range([0, width])
        .padding(1);

    // Add the x-axis
    const xAxis = svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0,${height})`)
        .call(d3.axisBottom(xScale));

    // Style the x-axis line itself to be invisible
    xAxis.select(".domain").style("display", "none");
    xAxis.selectAll(".tick line").attr("stroke", "#3E4758");
    xAxis.selectAll(".tick text").attr("fill", "#CBD5E1");

    //////////////////////////////////////////////////////////////////
    // DRAW THE BOX PLOTS
    //////////////////////////////////////////////////////////////////

    const maxBoxWidth = 40;
    const boxWidth = Math.min(maxBoxWidth, (width / data.length) * 0.8);

    svg.selectAll(".box")
        .data(data)
        .enter()
        .append("g")
        .attr("class", "box")
        .attr(
            "transform",
            (d) =>
                `translate(${xScale(d.name) || 0 + xScale.bandwidth() / 2},0)`,
        ) // Center within the box
        .call((g) => {
            // Add center line for whiskers
            g.append("line")
                .attr("y1", (d) => yScale(d.q0))
                .attr("x", 20)
                .attr("y2", (d) => yScale(d.q4))
                .attr("stroke", "#CBD5E1")
                .attr("z-index", 2)
                .attr("stroke-width", "2px");

            // Add IQR box
            g.append("rect")
                .attr("x", -boxWidth / 2)
                .attr("y", (d) => yScale(d.q1) - (yScale(d.q1) - yScale(d.q3))) // Adjust the y position
                .attr("height", (d) => yScale(d.q1) - yScale(d.q3)) // Use Q3 - Q1 for the height
                .attr("width", boxWidth)
                .attr("fill", "#4A66F4");
        });

    // Add horizontal lines for min and max values
    svg.selectAll(".box").each(function (datum) {
        const d = datum as IBoxPlotData;
        const box = d3.select(this);

        // Bottom Lines for min value. Add extra rext to make inner corner not rounded
        box.append("rect")
            .attr("x", -boxWidth / 2) // Starting x-coordinate
            .attr("y", yScale(d.q0)) // Adjust the y-coordinate for the desired appearance
            .attr("width", boxWidth) // Total width, including rounded corners
            .attr("height", 4) // Line thickness
            .attr("rx", 2) // Horizontal radius for rounded corners
            .attr("ry", 2) // Vertical radius for rounded corners
            .attr("fill", "#FF6B6B"); // Line color
        box.append("rect")
            .attr("x", -boxWidth / 2) // Starting x-coordinate
            .attr("y", yScale(d.q0)) // Adjust the y-coordinate for the desired appearance
            .attr("width", boxWidth) // Total width, including rounded corners
            .attr("height", 1) // Line thickness
            .attr("fill", "#FF6B6B"); // Line color

        // Top Lines for max value. Add extra rext to make inner corner not rounded
        box.append("rect")
            .attr("x", -boxWidth / 2)
            .attr("y", yScale(d.q4))
            .attr("width", boxWidth) // Total width, including rounded corners
            .attr("height", 4) // Line thickness
            .attr("rx", 2) // Horizontal radius for rounded corners
            .attr("ry", 2) // Vertical radius for rounded corners
            .attr("fill", "#08BDBA"); // Line for max value
        box.append("rect")
            .attr("x", -boxWidth / 2)
            .attr("y", yScale(d.q4) + 3)
            .attr("width", boxWidth) // Total width, including rounded corners
            .attr("height", 1) // Line thickness
            .attr("fill", "#08BDBA"); // Line for max value
    });
}

export default withChartRenderer(drawBoxPlot);
