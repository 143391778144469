import React from "react";
import { IconProps } from "./types";

const BarChartupIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#3E4758" />
                    <rect
                        x="30.1666"
                        y="19.3333"
                        width="2.5"
                        height="10.8333"
                        rx="1.25"
                        fill="#CBD5E1"
                    />
                    <rect
                        x="26"
                        y="23.5"
                        width="2.5"
                        height="6.66667"
                        rx="1.25"
                        fill="#CBD5E1"
                    />
                    <path
                        d="M20.1667 31.8333H32.6667C33.1269 31.8333 33.5 32.2064 33.5 32.6667C33.5 33.1269 33.1269 33.5 32.6667 33.5H19.3333C18.8731 33.5 18.5 33.1269 18.5 32.6667V19.3333C18.5 18.8731 18.8731 18.5 19.3333 18.5C19.7936 18.5 20.1667 18.8731 20.1667 19.3333V31.8333Z"
                        fill="#CBD5E1"
                    />
                    <rect
                        x="21.8334"
                        y="25.1667"
                        width="2.5"
                        height="5"
                        rx="1.25"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default BarChartupIcon;
