import { useSession } from "@/entities/Session";
import { Onboarding } from "@/features/Onboarding";
import { Illustration } from "@/ui";
import clsx from "clsx";
import { Navigate } from "react-router-dom";

function VerifyEmailPage() {
    const session = useSession();

    if (session.emailVerified) {
        return <Navigate to="/" />;
    }

    return (
        <Onboarding.Layout centered>
            <div className={clsx("py-8", "flex flex-col", "max-w-xl gap-12")}>
                <Illustration.VerifyEmail className="mx-auto" />
                <h2 className="text-center">Verify your email to continue</h2>
                <p className="body-l subdued text-center">
                    We’ve sent a verification email to {session.auth0?.email}.
                    Click the link inside to continue.
                </p>
                <button className="md mx-auto w-[118px]">Resend Email</button>
            </div>
        </Onboarding.Layout>
    );
}

export default VerifyEmailPage;
