import React from "react";
import { IconProps } from "./types";

const TagIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#3E4758" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M24.3057 33.0607L18.9356 27.6906C18.3548 27.1063 18.3548 26.1627 18.9356 25.5785L25.6773 18.9504C25.9575 18.675 26.3346 18.5206 26.7275 18.5206H32.1062C32.9335 18.5206 33.6041 19.1913 33.6041 20.0186V25.3819C33.6041 25.7839 33.4426 26.169 33.1558 26.4506L26.4253 33.0607C26.1443 33.3419 25.763 33.5 25.3655 33.5C24.9679 33.5 24.5866 33.3419 24.3057 33.0607ZM31.6069 22.0158C31.6069 22.8431 30.9362 23.5138 30.1089 23.5138C29.2816 23.5138 28.611 22.8431 28.611 22.0158C28.611 21.1885 29.2816 20.5179 30.1089 20.5179C30.9362 20.5179 31.6069 21.1885 31.6069 22.0158Z"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default TagIcon;
