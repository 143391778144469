import React, { useEffect, useRef, useState } from "react";
import { IDrawFunctionArgs } from "./Chart.types";

export const withChartRenderer = (
    draw: (args: IDrawFunctionArgs<any>) => void,
) => {
    return function Chart<T>({ id, data }: { id: string; data: T }) {
        const [width, setWidth] = useState<number | null>(null);
        const ref = useRef<HTMLDivElement>(null);
        const resizeObserver = new ResizeObserver((entries) => {
            for (const entry of entries) {
                const { contentRect } = entry;
                const newWidth = contentRect.width;
                setWidth(parseInt(newWidth.toString(), 10));
            }
        });

        useEffect(() => {
            // Watches for resizes of the parent element
            if (ref.current?.parentElement) {
                resizeObserver.observe(ref.current.parentElement);
            }

            return () => {
                resizeObserver.disconnect();
            };
        }, [ref]);

        useEffect(() => {
            // Gets the original width
            if (
                ref.current &&
                ref.current.parentElement?.clientWidth &&
                ref.current.parentElement?.clientWidth !== width
            ) {
                setWidth(ref.current.parentElement?.clientWidth);
            }
        }, [ref]);

        useEffect(() => {
            if (width !== null) {
                draw({
                    width,
                    height: (width / 4) * 3,
                    data,
                    id,
                });
            }
        }, [width, data, id, draw]);

        return <div id={id} className="py-8" ref={ref} />;
    };
};
