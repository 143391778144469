import clsx from "clsx";
import React from "react";

export function ModalFooter({ children }: { children?: React.ReactNode }) {
    return (
        <div
            className={clsx(
                "bg-default px-6 py-4 dark:bg-slate-800",
                "flex items-center justify-between rounded-b-lg",
            )}
        >
            {children}
        </div>
    );
}
