import React from "react";
import clsx from "clsx";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, ChevronUpDownIcon } from "@heroicons/react/20/solid";
import { ErrorMessage } from "./ErrorMessage";
import { Label } from "./Label";

export type OptionRawSelect = {
    label: string | React.ReactNode;
    value: any;
};

export type GroupedOptionRawSelect = {
    groupLabel: string;
    options: OptionRawSelect[];
};

type RawSelectProps = {
    testId?: string;
    name: string;
    className?: string;
    options: (OptionRawSelect | GroupedOptionRawSelect)[];
    placeholder?: string;
    label: string;
    selected: OptionRawSelect | OptionRawSelect[] | null;
    onChange: (value: OptionRawSelect | OptionRawSelect[]) => void;
    onBlur?: () => void;
    errorMessage?: string;
    required?: boolean;
    ariaDescribedby?: string;
    showErrorRing?: boolean;
    disabled?: boolean;
    multiple?: boolean;
};

export function RawSelect({
    testId,
    name,
    showErrorRing,
    placeholder = "Select an option",
    label,
    options,
    selected,
    onChange,
    errorMessage,
    required,
    ariaDescribedby,
    onBlur,
    disabled,
    multiple = false,
}: RawSelectProps) {
    const describedBy = ariaDescribedby || `${testId}-error`;

    return (
        <Listbox
            value={selected}
            onChange={onChange}
            disabled={disabled}
            multiple={multiple}
        >
            {({ open }) => (
                <div className="relative">
                    <Label required={required} name={name} label={label} />
                    <Listbox.Button
                        aria-haspopup="listbox"
                        className={clsx(
                            "secondary relative mb-1 mt-2 w-full",
                            (errorMessage || showErrorRing) &&
                                "ring-1 ring-red-600",
                            disabled && "cursor-not-allowed",
                        )}
                        data-testid={testId}
                        onBlur={onBlur}
                        aria-describedby={ariaDescribedby}
                    >
                        {multiple ? (
                            Array.isArray(selected) && selected.length > 0 ? (
                                <div className="flex flex-wrap gap-2">
                                    {selected.map((option) => (
                                        <span
                                            key={option.value}
                                            className="rounded bg-slate-800 px-2 py-1"
                                        >
                                            {option.label}
                                        </span>
                                    ))}
                                </div>
                            ) : (
                                <p className="body-small subdued">
                                    {placeholder}
                                </p>
                            )
                        ) : // When single selection is enabled
                        selected ? (
                            <p className="body-small">
                                {(selected as OptionRawSelect).label}
                            </p>
                        ) : (
                            <p className="body-small subdued">{placeholder}</p>
                        )}
                        <span
                            className={clsx(
                                "pr-2",
                                "pointer-events-none absolute inset-y-0 right-0 flex items-center",
                            )}
                        >
                            <ChevronUpDownIcon
                                className="size-5 text-gray-400"
                                aria-hidden="true"
                            />
                        </span>
                    </Listbox.Button>
                    <Transition
                        show={open}
                        as={React.Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Listbox.Options
                            static
                            className={clsx(
                                "absolute z-10 mt-1 max-h-60 w-full py-1",
                                "rounded-md ring-1 ring-inset ring-slate-300 focus:outline-none dark:ring-slate-700",
                                "custom-scrollbar bg-secondary overflow-auto",
                            )}
                        >
                            {options.map((optionOrGroup) =>
                                "groupLabel" in optionOrGroup ? (
                                    <div
                                        key={
                                            optionOrGroup.groupLabel ||
                                            "group-unique-key"
                                        }
                                    >
                                        <p
                                            className={clsx(
                                                "bg-secondary body-micro p-2",
                                                "subdued font-bold uppercase",
                                            )}
                                        >
                                            {optionOrGroup.groupLabel}
                                        </p>
                                        {optionOrGroup.options.map((option) => (
                                            <Listbox.Option
                                                key={option.value}
                                                className={({ active }) =>
                                                    clsx(
                                                        active
                                                            ? "bg-slate-800"
                                                            : "bg-secondary",
                                                        "relative cursor-pointer select-none py-2 pl-10 pr-4",
                                                    )
                                                }
                                                value={option}
                                            >
                                                {({ selected, active }) => (
                                                    <>
                                                        {typeof option.label ===
                                                        "string" ? (
                                                            <p className="body-small block cursor-pointer">
                                                                {option.label}
                                                            </p>
                                                        ) : (
                                                            <div>
                                                                {option.label}
                                                            </div>
                                                        )}
                                                        {selected ? (
                                                            <span
                                                                className={clsx(
                                                                    active
                                                                        ? "subdued"
                                                                        : "subdued",
                                                                    "absolute inset-y-0 left-0 flex items-center pl-3",
                                                                )}
                                                            >
                                                                <CheckIcon
                                                                    className="size-5"
                                                                    aria-hidden="true"
                                                                />
                                                            </span>
                                                        ) : null}
                                                    </>
                                                )}
                                            </Listbox.Option>
                                        ))}
                                    </div>
                                ) : (
                                    <Listbox.Option
                                        key={optionOrGroup.value}
                                        className={({ active }) =>
                                            clsx(
                                                active
                                                    ? "bg-slate-800"
                                                    : "bg-secondary",
                                                "relative cursor-pointer select-none py-2 pl-10 pr-4",
                                            )
                                        }
                                        value={optionOrGroup}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                {typeof optionOrGroup.label ===
                                                "string" ? (
                                                    <p className="body-small block cursor-pointer">
                                                        {optionOrGroup.label}
                                                    </p>
                                                ) : (
                                                    <div>
                                                        {optionOrGroup.label}
                                                    </div>
                                                )}
                                                {selected ? (
                                                    <span
                                                        className={clsx(
                                                            active
                                                                ? "subdued"
                                                                : "subdued",
                                                            "absolute inset-y-0 left-0 flex items-center pl-3",
                                                        )}
                                                    >
                                                        <CheckIcon
                                                            className="size-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ),
                            )}
                        </Listbox.Options>
                    </Transition>
                    <div
                        className={clsx(
                            "overflow-hidden transition-all duration-300",
                            errorMessage ? "h-6 opacity-100" : "h-0 opacity-0",
                        )}
                    >
                        {errorMessage && (
                            <ErrorMessage
                                id={describedBy}
                                message={errorMessage}
                            />
                        )}
                    </div>
                </div>
            )}
        </Listbox>
    );
}
