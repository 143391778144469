import React from "react";
import { IconProps } from "./types";

const CheckIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M19.2045 6.15347C19.534 6.4045 19.5976 6.87509 19.3466 7.20457L11.3466 17.7046C11.2154 17.8767 11.0163 17.9838 10.8003 17.9983C10.5844 18.0129 10.3727 17.9334 10.2197 17.7804L5.71967 13.2804C5.42678 12.9875 5.42678 12.5126 5.71967 12.2197C6.01256 11.9268 6.48744 11.9268 6.78033 12.2197L10.6735 16.1129L18.1534 6.29551C18.4045 5.96603 18.8751 5.90243 19.2045 6.15347Z"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CheckIcon;
