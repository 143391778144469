import { useState, useEffect } from "react";
import { FilterboxProps, Option } from "./Filterbox.types";

export function useFilterBox({
    options,
    onChange,
    defaultSelections,
}: Pick<FilterboxProps, "options" | "onChange" | "defaultSelections">) {
    const values = Array.from(new Set(options.map(({ value }) => value)));
    const [selected, setSelected] = useState<Set<string>>(
        new Set(defaultSelections || values),
    );
    const [selectAll, setSelectAll] = useState<boolean>(false);

    const toggleSelectAll = () => {
        setSelected((prevSelected) =>
            prevSelected.size === values.length ? new Set() : new Set(values),
        );
        setSelectAll(!selectAll);
    };
    const updateClickedOption = (option: Option) => {
        setSelectAll(false);
        setSelected(new Set([option.value]));
    };
    const updateCheckedOption = (checked: boolean, option: Option) => {
        const newSelected = new Set(selected);
        checked
            ? newSelected.delete(option.value)
            : newSelected.add(option.value);
        setSelected(newSelected);
    };

    useEffect(() => {
        if (!selectAll && selected.size === values.length) {
            setSelectAll(true);
        }

        const isNullSet = selected.size === 0;
        const isFullSet = selected.size === values.length;
        onChange({
            filterSet: Array.from(selected),
            isFullSet,
            isNullSet,
            hasSelections: !isFullSet && !isNullSet,
            isSelected: (val) => selected.has(val),
            isOnly: (val) => selected.size === 1 && selected.has(val),
        });
    }, [selected, selectAll, setSelectAll]);

    return {
        toggleSelectAll,
        updateClickedOption,
        updateCheckedOption,
        selected,
        selectAll,
    };
}
