import React from "react";
import { IconProps } from "./types";

const MapPinIcon = ({
    className,
    width = "24",
    height = "24",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11.359 21.183L11.3618 21.1842C11.5589 21.27 11.6689 21.25 11.6689 21.25C11.6689 21.25 11.7789 21.27 11.9769 21.1839L11.9789 21.183L11.9846 21.1804L12.0028 21.172C12.0178 21.165 12.0386 21.1552 12.0649 21.1426C12.1173 21.1173 12.1915 21.0806 12.2843 21.0322C12.4697 20.9355 12.7302 20.7919 13.0406 20.5995C13.6602 20.2155 14.4863 19.6326 15.3144 18.8344C16.9711 17.2376 18.6689 14.7425 18.6689 11.25C18.6689 7.38401 15.5349 4.25 11.6689 4.25C7.80295 4.25 4.66895 7.38401 4.66895 11.25C4.66895 14.7425 6.36678 17.2376 8.02346 18.8344C8.85158 19.6326 9.67771 20.2155 10.2973 20.5995C10.6077 20.7919 10.8682 20.9355 11.0536 21.0322C11.1464 21.0806 11.2206 21.1173 11.273 21.1426C11.2993 21.1552 11.3201 21.165 11.3351 21.172L11.3533 21.1804L11.359 21.183ZM11.6689 13.5C12.9116 13.5 13.9189 12.4926 13.9189 11.25C13.9189 10.0074 12.9116 9 11.6689 9C10.4263 9 9.41895 10.0074 9.41895 11.25C9.41895 12.4926 10.4263 13.5 11.6689 13.5Z"
                        fill="white"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default MapPinIcon;
