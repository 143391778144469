export const ddActions = {
    profile: {
        edit: {
            click: "profile:edit:clicked",
            discard: {
                click: "profile:edit:discard:clicked",
                cancel: "profile:edit:discard:cancel",
                confirm: "profile:edit:discard:confirm",
            },
            preview: {
                click: "profile:edit:preview:clicked",
                back: "profile:edit:preview:back",
            },
            publish: {
                click: "profile:edit:publish:clicked",
            },
        },
        share: {
            public: "profile:share:public:link",
        },
        downloadChartbook: {
            click: "profile:download_chartbook:clicked",
            success: "profile:download_chartbook:success",
            fail: "profile:download_chartbook:fail",
            start: "profile:download_chartbook:started",
        },
        financialHighlights: {
            item: {
                delete: "profile:financial_highlights:delete",
                add: "profile:financial_highlights:add",
            },
        },
        hero: {
            callout: {
                delete: "profile:hero:delete",
                add: "profile:hero:add",
            },
        },
        footer: {
            finra: "profile:footer:finra:link",
            help: "profile:footer:help-center:link",
            contact: "profile:footer:contact-hum:link",
        },
    },
    match: {
        accept: "match:accept",
        reject: "match:reject",
        requestInfo: "match:requestInfo",
        teaserViewed: "match:teaserViewed",
    },
    calendly: {
        schedule: "calendly:schedule",
    },
};
