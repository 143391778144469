import { datadogRum } from "@datadog/browser-rum";
import { datadogLogs } from "@datadog/browser-logs";

export function initDataDog() {
    const site = "datadoghq.com";
    const version = "2.0.0";
    const service = "frontend";

    if (
        process.env.NODE_ENV === "production" &&
        process.env.DATA_DOG_APPLICATION_ID &&
        process.env.DATA_DOG_CLIENT_TOKEN
    ) {
        datadogRum.init({
            applicationId: process.env.DATA_DOG_APPLICATION_ID,
            clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
            site,
            service,
            version,
            env: "prod",
            sessionSampleRate: 100,
            sessionReplaySampleRate: 100,
            trackResources: true,
            trackLongTasks: true,
            trackUserInteractions: true,
            defaultPrivacyLevel: "mask-user-input",
            enableExperimentalFeatures: ["feature_flags"],
            actionNameAttribute: "dd-action",
        });

        datadogLogs.init({
            clientToken: process.env.DATA_DOG_CLIENT_TOKEN,
            site,
            service,
            version,
            env: "prod",
            forwardErrorsToLogs: true,
            forwardConsoleLogs: "all",
            sessionSampleRate: 100,
        });
    }
}
