import React from "react";
import clsx from "clsx";

type SidebarProps = {
    testId?: string;
    className?: string;
    children?: React.ReactNode;
    fixedBar?: boolean;
};

export function SidebarNav({ testId, children, fixedBar }: SidebarProps) {
    return (
        <div
            className={clsx(
                fixedBar ? "pt-20" : "pt-8",
                "px-4 pb-8",
                "w-full",
                "h-full",
                "fixed",
                "lg:max-w-[280px]",
                "max-w-[200px]",
                "flex",
                "flex-col",
                "gap-y-3",
                "border-r",
                "border-default",
            )}
            data-testid={testId}
        >
            {children}
        </div>
    );
}

SidebarNav.Content = ({ children }: SidebarProps) => (
    <div className="ml-[200px] pt-[48px] lg:ml-[280px]">{children}</div>
);

SidebarNav.Item = ({
    children,
    className,
    onClick,
    testId,
    active,
}: {
    children: React.ReactNode;
    className?: string;
    onClick?: () => void;
    testId?: string;
    active?: boolean;
}) => (
    <div
        className={clsx(
            "px-4 py-3",
            "rounded-default",
            "cursor-pointer",
            "hover:bg-onSurface-pressed",
            active && "bg-onSurface-pressed",
            "[&>*]:cursor-pointer",
            "flex",
            "items-center",
            "justify-between",
            className,
        )}
        onClick={onClick}
        data-testid={testId}
    >
        {children}
    </div>
);
