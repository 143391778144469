import clsx from "clsx";
import React, { useState } from "react";

export function TextInput({
    onChange,
    placeholder,
    name,
    id,
    value,
    className,
    testId,
    multiline,
}: {
    onChange: (s: string) => void;
    placeholder: string;
    id: string;
    name: string;
    value?: string;
    className?: string;
    testId?: string;
    multiline?: boolean;
}) {
    const [focused, setFocus] = useState(false);
    const Root = multiline ? "textarea" : "input";

    return (
        <div className={clsx("w-full", className)} data-testid={testId}>
            {placeholder && (
                <p
                    className={clsx(
                        "ui-element text-disabledTextColor pb-1 pl-4 text-[12px]",
                        {
                            "opacity-0": !focused,
                        },
                    )}
                >
                    {placeholder}
                </p>
            )}
            <Root
                type="text"
                name={name}
                id={id}
                value={value}
                className={clsx(
                    `border-default w-full rounded border bg-transparent p-3 pl-4 outline-none
                    focus:ring-transparent`,
                    { "placeholder:text-transparent": focused },
                )}
                placeholder={!value ? placeholder : undefined}
                onFocus={() => setFocus(true)}
                onBlur={() => setFocus(false)}
                onChange={(e) => onChange(e.target.value)}
            />
        </div>
    );
}
