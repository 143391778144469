import React from "react";
import { IconProps } from "./types";

const RingOffIcon = ({
    className,
    width = "18",
    height = "18",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="transparent"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M16.5 9C16.5 13.1421 13.1421 16.5 9 16.5C4.85786 16.5 1.5 13.1421 1.5 9C1.5 4.85786 4.85786 1.5 9 1.5C13.1421 1.5 16.5 4.85786 16.5 9Z"
                        stroke="#363F4F"
                        strokeWidth="3"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default RingOffIcon;
