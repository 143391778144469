import React from "react";
import { IconProps } from "./types";

const DefaultFileIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.08929 1.5H14.0315C14.3805 1.5 14.7186 1.62168 14.9875 1.8441L20.456 6.36674C20.8005 6.6517 21 7.07551 21 7.52264V21.3917C21 23.3613 20.977 23.5 18.9107 23.5H5.08929C3.023 23.5 3 23.3613 3 21.3917V3.60833C3 1.63874 3.023 1.5 5.08929 1.5Z"
                        fill="#2A313F"
                    />
                    <rect
                        x="6"
                        y="11.5"
                        width="9"
                        height="2"
                        rx="1"
                        fill="#788598"
                    />
                    <rect
                        x="6"
                        y="15.5"
                        width="5"
                        height="2"
                        rx="1"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default DefaultFileIcon;
