export function formattedUrl(url: string | null): string {
    if (!url) {
        return "";
    }
    let domain = url.replace(/(^\w+:|^)\/\//, "");
    domain = domain.replace(/^www\./, "");
    domain = domain.split("/")[0];

    return domain;
}

export const URL_REGEX =
    /((https?):\/\/)?(www.)?[a-z0-9-]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#-]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/gm;
