import clsx from "clsx";
import { SubHeadingAccessory } from "./SubHeadingAccessory";
import { SubHeadingData } from "./SubHeadingData";

type SubHeadingProps = {
    children: React.ReactNode;
    testId?: string;
    className?: string;
};

export function SubHeading({ children, testId, className }: SubHeadingProps) {
    return (
        <div
            className={clsx(
                "my-10 flex items-center gap-2 sm:gap-14",
                className,
            )}
            data-testid={testId}
        >
            {children}
        </div>
    );
}

SubHeading.Data = SubHeadingData;
SubHeading.Accessory = SubHeadingAccessory;
