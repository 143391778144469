import { useAuth0 } from "@auth0/auth0-react";
import { getAccessToken } from "../Session.queries";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import Cookies from "js-cookie";

export function useToken() {
    const auth0Session = useAuth0();

    const token = useQuery<string>({
        ...getAccessToken(auth0Session),
        enabled:
            auth0Session.isAuthenticated &&
            !!auth0Session.getAccessTokenSilently,
        refetchOnWindowFocus: true,
    });

    useEffect(() => {
        if (token.data) {
            const cookieOptions = {
                secure: process.env.NODE_ENV !== "development",
                sameSite:
                    process.env.NODE_ENV === "development" ? "Lax" : "Strict",
            };

            Cookies.set(process.env.TOKEN_COOKIE, token.data, cookieOptions);
            let domain = ".test";
            if (process.env.NODE_ENV === "staging") domain = ".info";
            if (process.env.NODE_ENV === "production") domain = ".com";
            Cookies.set(process.env.TOKEN_COOKIE, token.data, {
                ...cookieOptions,
                domain: "icm.humcapital" + domain,
            });
        }
    }, [token.data]);

    return token;
}
