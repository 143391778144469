import React from "react";
import { Disclosure } from "@headlessui/react";
import ChevronRightIcon from "../../atoms/Icon/ChevronRightIcon";
import { Card } from "../../atoms";
import clsx from "clsx";

export const Accordion = ({
    title,
    description,
    children,
    open = false,
    className,
}: {
    title: string;
    description?: string;
    children: React.ReactNode;
    open?: boolean;
    className?: string;
}) => {
    return (
        <Disclosure defaultOpen={open}>
            {({ open }) => (
                <div>
                    <Disclosure.Button className={clsx("w-full", className)}>
                        <Card>
                            <div className="flex items-center justify-between text-left">
                                <div>
                                    <h4>{title}</h4>
                                    {description && (
                                        <p className="body-m mr-8 mt-2">
                                            {description}
                                        </p>
                                    )}
                                </div>
                                <ChevronRightIcon
                                    className={clsx(
                                        "cursor-pointer",
                                        "transition duration-300 ease-in-out",
                                        open ? "rotate-90" : "",
                                    )}
                                />
                            </div>
                        </Card>
                    </Disclosure.Button>
                    <Disclosure.Panel className="w-full">
                        <div className="px-2 md:px-6">{children}</div>
                    </Disclosure.Panel>
                </div>
            )}
        </Disclosure>
    );
};
