import clsx from "clsx";

type SearchRadioResultProps = {
    testId?: string;
    className?: string;
    id: string | number;
    name: string;
    onChange: (e: any) => void;
    children?: React.ReactNode;
    disabled?: boolean;
};

export function SearchRadioResult({
    testId,
    className,
    id,
    name,
    onChange,
    children,
    disabled,
}: SearchRadioResultProps) {
    return (
        <div
            className={clsx("flex items-center p-3", className)}
            role="listitem"
            data-testid={testId}
        >
            <input
                id={id.toString()}
                name={name}
                type="radio"
                defaultChecked={false}
                className={clsx(
                    "bg-onSurface-active mr-2",
                    "border-2 border-gray-400",
                    "size-4 text-blue-500",
                    "focus:ring-0 focus:ring-offset-0",
                )}
                onChange={onChange}
                disabled={disabled}
            />
            <label
                htmlFor={id.toString()}
                className={clsx(disabled && "text-gray-500", "mr-2")}
            >
                {name}
                {children}
            </label>
        </div>
    );
}
