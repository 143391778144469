import { useRouter } from "@/utils";
import type { INavLink } from "@/utils/navigation/Navigation.types";
import { Dialog, Transition } from "@headlessui/react";
import {
    ArrowRightStartOnRectangleIcon,
    Cog6ToothIcon,
    XMarkIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { NavLink } from "react-router-dom";
import { Fragment } from "react/jsx-runtime";

export function MobileNavMenu({
    open,
    close,
    links = [],
    showLogout = false,
    showSettings = false,
}: {
    open: boolean;
    close: () => void;
    links?: INavLink[];
    showSettings?: boolean;
    showLogout?: boolean;
}) {
    const router = useRouter();

    return (
        <Transition.Root show={open} as={Fragment}>
            <Dialog as="div" className="relative lg:hidden" onClose={close}>
                <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-transparent" />
                </Transition.Child>

                <div className="fixed inset-0 z-[2000] flex">
                    <Transition.Child
                        as={Fragment}
                        enter="transition ease-in-out duration-300 transform"
                        enterFrom="-translate-x-full"
                        enterTo="translate-x-0"
                        leave="transition ease-in-out duration-300 transform"
                        leaveFrom="translate-x-0"
                        leaveTo="-translate-x-full"
                    >
                        <Dialog.Panel
                            className={`bg-default relative flex w-full max-w-xs flex-col overflow-y-auto pb-12 shadow-xl`}
                        >
                            <div className="flex px-4 pb-2 pt-5">
                                <button
                                    type="button"
                                    onClick={close}
                                    className={clsx(
                                        "quiet",
                                        "relative rounded-md",
                                        "focus:outline-none focus:ring-2 focus:ring-slate-100 focus:ring-offset-2",
                                    )}
                                >
                                    <span className="absolute -inset-2.5" />
                                    <span className="sr-only">Close panel</span>
                                    <XMarkIcon
                                        aria-hidden="true"
                                        className="size-6 stroke-white hover:stroke-black"
                                    />
                                </button>
                            </div>
                            {links.length > 0 && (
                                <div
                                    id="nav-links"
                                    className="space-y-6 px-4 py-6"
                                >
                                    {links.map(({ to, label, external }) => (
                                        <div
                                            key={to}
                                            className="flow-root"
                                            data-testid={`mobile:link-item-${label}`}
                                        >
                                            <NavLink
                                                to={to}
                                                reloadDocument={!!external}
                                            >
                                                <p className="subdued body-s hover:text-color-default cursor-pointer">
                                                    {label}
                                                </p>
                                            </NavLink>
                                        </div>
                                    ))}
                                </div>
                            )}

                            <div
                                className={clsx(
                                    "px-4 py-6",
                                    links.length > 0 &&
                                        "border-default border-t",
                                    "space-y-6",
                                )}
                            >
                                {showSettings && (
                                    <div className="flow-root">
                                        <button
                                            className={clsx(
                                                "xs",
                                                "quiet",
                                                "hover:text-slate-200",
                                                "group",
                                            )}
                                            onClick={() =>
                                                router.updateSearch(
                                                    "settings",
                                                    "open",
                                                )
                                            }
                                        >
                                            <Cog6ToothIcon className="xs size-6 group-hover:stroke-slate-200" />
                                            Settings
                                        </button>
                                    </div>
                                )}
                                {showLogout && (
                                    <div className="flow-root">
                                        <NavLink to="/logout">
                                            <button
                                                className={clsx(
                                                    "xs",
                                                    "quiet ",
                                                    "hover:text-slate-300",
                                                    "group",
                                                )}
                                            >
                                                <ArrowRightStartOnRectangleIcon className="xs size-6  group-hover:stroke-slate-200" />
                                                Logout
                                            </button>
                                        </NavLink>
                                    </div>
                                )}
                            </div>
                        </Dialog.Panel>
                    </Transition.Child>
                </div>
            </Dialog>
        </Transition.Root>
    );
}
