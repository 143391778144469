import clsx from "clsx";

type SearchHeaderProps = {
    testId?: string;
    className?: string;
    title: string;
    description?: string;
    children?: React.ReactNode;
};

export function SearchHeader({
    testId,
    title,
    description,
    children,
    className,
}: SearchHeaderProps) {
    return (
        <div className={clsx(className)} data-testid={testId}>
            <h4>{title}</h4>
            <div
                className={clsx(
                    "my-3",
                    "flex items-center justify-between",
                    "sm:my-5",
                )}
            >
                {description && <p className="body-m">{description}</p>}
                {children}
            </div>
        </div>
    );
}
