import React from "react";
import { IconProps } from "./types";

const CircleDollarIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#3E4758" />
                    <path
                        d="M26.7498 26.3176V28.9324C27.1815 28.8527 27.5744 28.7046 27.8875 28.4999C28.3697 28.1846 28.4998 27.852 28.4998 27.625C28.4998 27.398 28.3697 27.0654 27.8875 26.7501C27.5744 26.5454 27.1815 26.3973 26.7498 26.3176Z"
                        fill="#CBD5E1"
                    />
                    <path
                        d="M24.3295 24.1195C24.3833 24.1754 24.4445 24.2305 24.5139 24.2842C24.7219 24.4448 24.9734 24.5678 25.2499 24.6469V22.1031C25.1753 22.1245 25.1026 22.149 25.032 22.1766C24.9871 22.1942 24.943 22.213 24.9 22.233C24.7598 22.2982 24.6301 22.3761 24.5139 22.4659C24.1365 22.7576 23.9999 23.0925 23.9999 23.375C23.9999 23.5589 24.0577 23.7649 24.2022 23.9668C24.2389 24.0181 24.2811 24.0691 24.3295 24.1195Z"
                        fill="#CBD5E1"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M33.9999 25.5C33.9999 29.9183 30.4182 33.5 25.9999 33.5C21.5816 33.5 17.9999 29.9183 17.9999 25.5C17.9999 21.0817 21.5816 17.5 25.9999 17.5C30.4182 17.5 33.9999 21.0817 33.9999 25.5ZM25.9998 19.5C26.414 19.5 26.7498 19.8358 26.7498 20.25V20.5658C27.3421 20.6711 27.9181 20.9043 28.4029 21.2789C28.8292 21.6083 29.1465 22.0184 29.3281 22.479C29.48 22.8643 29.2907 23.2999 28.9054 23.4517C28.52 23.6036 28.0845 23.4144 27.9326 23.029C27.8598 22.8444 27.7199 22.6467 27.4858 22.4659C27.2778 22.3052 27.0263 22.1823 26.7498 22.1031V24.7994C27.4479 24.8923 28.1326 25.1182 28.7084 25.4947C29.4954 26.0093 29.9998 26.7644 29.9998 27.625C29.9998 28.4856 29.4954 29.2407 28.7084 29.7553C28.1326 30.1318 27.4479 30.3577 26.7498 30.4506V30.75C26.7498 31.1642 26.414 31.5 25.9998 31.5C25.5856 31.5 25.2498 31.1642 25.2498 30.75V30.4506C24.5517 30.3577 23.8671 30.1318 23.2913 29.7553C22.8088 29.4398 22.4336 29.0376 22.2133 28.5672C22.0376 28.192 22.1993 27.7455 22.5744 27.5699C22.9495 27.3942 23.396 27.5559 23.5717 27.931C23.6525 28.1035 23.8168 28.3067 24.1122 28.4999C24.4252 28.7046 24.8182 28.8526 25.2498 28.9324V26.1842C24.6575 26.0789 24.0815 25.8457 23.5967 25.4711C22.9002 24.9329 22.4998 24.1802 22.4998 23.375C22.4998 22.5698 22.9002 21.8171 23.5967 21.2789C24.0815 20.9043 24.6575 20.6711 25.2498 20.5658V20.25C25.2498 19.8358 25.5856 19.5 25.9998 19.5Z"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleDollarIcon;
