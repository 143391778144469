export const Header = ({
    title,
    description,
}: {
    title: string;
    description?: string;
}) => {
    return (
        <div>
            <h1 className="display-s lg:display-l mb-4 lg:mb-6">{title}</h1>
            <p className="body-l lg:mb-6">{description}</p>
        </div>
    );
};
