import React, { useState } from "react";
import { motion } from "framer-motion";
import clsx from "clsx";
// import { arrayMoveImmutable as move } from "array-move";

export function WidgetGrid({
    children,
    key,
}: {
    children: React.ReactNode[];
    key: string;
}) {
    const childrenArray = React.Children.toArray(children);
    const originalPositions = Array.from(
        { length: childrenArray.length },
        (_, i) => i,
    );
    const [order] = useState(originalPositions);
    const [selected] = useState<number | null>(null);
    // const handleClick = (originalPosition, currentPosition) => {
    //     setSelected(selected === originalPosition ? null : originalPosition);

    //     if (originalPosition !== currentPosition) {
    //         setOrder(move(order, currentPosition, originalPosition));
    //     } else if (originalPosition % 2 !== 0) {
    //         setOrder(move(order, originalPosition, originalPosition - 1));
    //     }
    // };

    return (
        <motion.div
            layout
            key={key}
            initial={{ opacity: 0 }}
            animate={{ y: 0, opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.3 }}
            className={clsx("grid grid-cols-2 gap-8", "w-full")}
        >
            {order.map((originalPosition) => (
                <motion.div
                    key={originalPosition}
                    layout
                    // onClick={() =>
                    //     handleClick(originalPosition, currentPosition)
                    // }
                    className={clsx(
                        "col-span-1 rounded",
                        selected === originalPosition && "col-span-2",
                    )}
                >
                    {childrenArray[originalPosition]}
                </motion.div>
            ))}
        </motion.div>
    );
}
