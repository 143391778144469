import { Spinner } from "@/ui/atoms/Spinner";
import { useRouter } from "../router";
import { useEffect } from "react";

export const V1Redirect = ({ to = "/" }: { to?: string }) => {
    useEffect(() => {
        window.location.assign(`${process.env.V1_BASE_URL}${to}`);
    }, [to]);

    return <Spinner fullScreen />;
};

export const V1RedirectWithPath = () => {
    const router = useRouter();

    useEffect(() => {
        window.location.assign(
            `${process.env.V1_BASE_URL}${router.pathname}${window.location.search}`,
        );
    }, [router.pathname]);

    return <Spinner fullScreen />;
};
