import React from "react";
import clsx from "clsx";

type InfoSectionProps = {
    testId?: string;
    className?: string;
    title: string;
    children?: React.ReactNode;
    primaryAction?: {
        text: string;
        onClick: () => void;
        disabled?: boolean;
        icon?: React.ReactNode;
    };
};

export function InfoSection({
    testId,
    className,
    title,
    primaryAction,
    children,
}: InfoSectionProps) {
    return (
        <div
            className={clsx(
                "block",
                "w-full",
                "border",
                "border-default",
                "rounded-default",
                "mb-6",
                "p-6",
                className,
            )}
            data-testid={testId}
        >
            <div
                className={clsx(
                    "flex",
                    "justify-between",
                    "items-center",
                    "mb-8",
                    "cursor-default",
                )}
            >
                <h5 className="heading-l">{title}</h5>
                {primaryAction ? (
                    <button
                        className="inline-flex gap-2"
                        data-testid={`${testId}:primary-action-button`}
                        disabled={primaryAction.disabled}
                        onClick={() => primaryAction.onClick()}
                    >
                        {primaryAction.icon}
                        {primaryAction.text}
                    </button>
                ) : null}
            </div>
            {children}
        </div>
    );
}
