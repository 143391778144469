import React from "react";
import { IconProps } from "./types";

const SearchIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M11 5.5C7.96243 5.5 5.5 7.96243 5.5 11C5.5 14.0376 7.96243 16.5 11 16.5C12.519 16.5 13.893 15.8852 14.8891 14.8891C15.8852 13.893 16.5 12.519 16.5 11C16.5 7.96243 14.0376 5.5 11 5.5ZM4 11C4 7.13401 7.13401 4 11 4C14.866 4 18 7.13401 18 11C18 12.6625 17.4197 14.1906 16.4517 15.3911L19.7803 18.7197C20.0732 19.0126 20.0732 19.4874 19.7803 19.7803C19.4874 20.0732 19.0126 20.0732 18.7197 19.7803L15.3911 16.4517C14.1906 17.4197 12.6625 18 11 18C7.13401 18 4 14.866 4 11Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default SearchIcon;
