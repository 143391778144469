import { useCallback, useContext, useMemo } from "react";
import { FormContext } from "./Form";
import { ObjectSchema, isSchema } from "yup";
import { FormState } from "./Form.types";
import { findLength, unflattenObject } from "./utils";
import _ from "lodash";
import * as yup from "yup";

type MutableListItem = {
    parent: string;
    remove: () => void;
};

export function MutableList<T extends ObjectSchema<any>>({
    name,
    children,
}: {
    name: string;
    children: React.FC<{
        items: MutableListItem[];
        addItem: () => void;
        removeItem: (i: number) => void;
    }>;
}) {
    const form: FormState<T> | undefined = useContext(FormContext) as
        | FormState<T>
        | undefined;

    const length = useMemo(
        () => findLength(form?.values, name) || 0,
        [form?.values],
    );

    const addItem = useCallback(() => {
        if (!form?.schema) return;
        const schemaField = yup.reach(form?.schema, name);

        if (!isSchema(schemaField)) return;

        // @ts-expect-error - we know this is an object schema
        const next = Object.keys(schemaField.innerType.fields).reduce(
            (acc, key) => {
                acc[`${name}[${length}].${key}`] = "";
                return acc;
            },
            {} as Record<string, any>,
        );
        form?.mergeValues(next);
    }, [form?.mergeValues, length]);

    const removeItem = useCallback(
        (index: number) => {
            const matcher = `${name}[`;
            const matches = _.pickBy(form?.values, (value, key) =>
                _.includes(key, matcher),
            );

            const filtered = _.pickBy(
                form?.values,
                (value, key) => !_.includes(key, matcher),
            );

            const o = unflattenObject(matches);

            // Access the correct nested array
            const pathArray = name.split(".");
            const lastKey = pathArray.pop();

            if (!lastKey) {
                console.error("Invalid path:", name);
                return;
            }

            let parentObject = o;
            if (pathArray.length > 0) {
                parentObject = pathArray.reduce((acc, key) => acc[key], o);
            }

            if (!parentObject || !Array.isArray(parentObject[lastKey])) {
                console.error(`Expected ${lastKey} to be an array`);
                return;
            }

            parentObject[lastKey] = parentObject[lastKey].filter(
                (_: any, i: number) => i !== index,
            );

            // Re-index the keys to maintain the correct order
            const updated = Object.keys(parentObject[lastKey]).reduce(
                (acc, key) => {
                    const item = parentObject[lastKey][key];
                    Object.keys(item).forEach((subKey) => {
                        acc[`${name}[${key}].${subKey}`] = item[subKey];
                    });
                    return acc;
                },
                {},
            );

            form?.replaceValues({ ...filtered, ...updated });
        },
        [form, name],
    );

    const items = Array.from({ length }).map((_, index) => ({
        parent: `${name}[${index}]`,
        remove: () => removeItem(index),
    }));

    if (!form) return null;

    return children({ items, addItem, removeItem });
}
