type SubHeadingDataProps = {
    label: string;
    value: string | number;
    children?: React.ReactNode;
};

export function SubHeadingData({
    label,
    value,
    children,
}: SubHeadingDataProps) {
    return (
        <div>
            <p className="subdued ui-element">{label}</p>
            <div className="flex items-baseline gap-x-1">
                <h4>{value}</h4>
                {children}
            </div>
        </div>
    );
}
