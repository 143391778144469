import { v4 as uuid } from "uuid";

export function UUIDRecord<T>(arr: T[]): Record<string, T> {
    const obj: Record<string, T> = {};
    arr.forEach((value) => {
        const id = uuid();
        obj[id] = { ...value, id };
    });

    return obj;
}
