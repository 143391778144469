/**
 * Transforms an array of objects into a single object where each key represents
 * a path to a specific value in the original array. The resulting object keys
 * are in the format `${arrayName}[${index}].${key}`.
 *
 * @param arrayName - The base name for the array, used in the resulting object keys.
 * @param array - The array of objects to be transformed.
 * @returns An object with keys representing the path to each value in the original array.
 */

type KeyValueObject = Record<string, any>;

export function arrayToPathValueObject(
    arrayName: string,
    array: KeyValueObject[],
): KeyValueObject {
    return array.reduce((acc, item, index) => {
        Object.keys(item).forEach((key) => {
            acc[`${arrayName}[${index}].${key}`] = item[key];
        });
        return acc;
    }, {});
}
