import { Spinner } from "@/ui";
import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

function LoginPage() {
    const { loginWithRedirect, user } = useAuth0();

    useEffect(() => {
        if (!user) {
            loginWithRedirect({
                authorizationParams: {
                    redirect_uri: process.env.AUTH0_LOGIN_REDIRECT_URI,
                },
            });
        }
    }, [user, loginWithRedirect]);

    return (
        <>
            <Spinner fullScreen />
            <p className="sr-only">Login Page</p>
        </>
    );
}

export default LoginPage;
