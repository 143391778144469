import { QueryCache, QueryClient } from "@tanstack/react-query";
import { toast } from "@/ui";
import type { UseApiQueryOptions } from "../hooks/useApiQuery";

export const queryCache = new QueryCache({
    onError: (data, q) => {
        const options = q.options as UseApiQueryOptions<any, any>;
        if (options.toastsEnabled && options.errorToast) {
            const { errorToast } = options;
            toast.error(errorToast.message, errorToast.options);
        }
    },
    onSuccess: (data, q) => {
        const options = q.options as UseApiQueryOptions<any, any>;
        if (options.toastsEnabled && options.successToast) {
            const { successToast } = options;
            toast.success(successToast.message, successToast.options);
        }
    },
});

export const queryClient = new QueryClient({ queryCache });
