import { PrivateCompanyProfile } from "./CompanyProfile.types";
import { createMutation } from "@/api";
import { getPrivateProfile } from "./CompanyProfile.queries";
import { isoDateFormat } from "@/utils";

export const updateCompanyProfile = createMutation<PrivateCompanyProfile>(
    ({ api, payload, router }) =>
        api.put(
            `/backend/v1/companies/${router.params.companyId}/teaser_profile`,
            {
                hero: {
                    ...payload.hero,
                    callouts: payload.hero?.callouts || [],
                },
                financialHighlights: {
                    title: payload.financialHighlights?.title,
                    description: payload.financialHighlights?.description,
                    lastUpdated: isoDateFormat(
                        payload.financialHighlights?.month,
                        payload.financialHighlights?.year,
                    ),
                    highlights: Object.values(
                        payload.financialHighlights?.highlights || [],
                    ),
                },
                showFilesSection: payload.downloads?.isVisible,
                filesSectionDescription: payload.downloads?.description,
            },
        ),
    {
        invalidates: [getPrivateProfile],
    },
);

export const startProfileDownload = createMutation<
    { taskId: string },
    { token: string }
>(
    ({ api, payload }) =>
        api.post("/backend/v1/public_analytics_download_tasks", {
            token: payload.token,
        }),
    {
        waitForSession: false,
    },
);
