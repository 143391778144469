import React from "react";
import { IconProps } from "./types";

const StepInProgress = ({
    className,
    width = "24",
    height = "24",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M19.5 12C19.5 16.1421 16.1421 19.5 12 19.5C7.85786 19.5 4.5 16.1421 4.5 12C4.5 7.85786 7.85786 4.5 12 4.5C16.1421 4.5 19.5 7.85786 19.5 12Z"
                        stroke="#6B7280"
                        strokeWidth="3"
                    />
                    <mask
                        id="mask0_36381_900"
                        style={{ maskType: "alpha" }}
                        maskUnits="userSpaceOnUse"
                        x="12"
                        y="3"
                        width="9"
                        height="9"
                    >
                        <path
                            d="M21 12C21 10.8181 20.7672 9.64778 20.3149 8.55585C19.8626 7.46392 19.1997 6.47177 18.364 5.63604C17.5282 4.80031 16.5361 4.13738 15.4442 3.68508C14.3522 3.23279 13.1819 3 12 3V12H21Z"
                            fill="#4BCA81"
                        />
                    </mask>
                    <g mask="url(#mask0_36381_900)"></g>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default StepInProgress;
