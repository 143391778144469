import { User } from "@/entities/User/User.model";
import { Router } from "@/utils/router";

export interface INavLink {
    label: string;
    to: string;
    external?: boolean;
}

export type UserLink = INavLink | { label: string; action: () => void };

export type LinkResolver<Props> = (context: {
    router: Router;
    user?: User | null;
    props?: Props;
}) => INavLink | null;

export type NavBarState = {
    navIsVisible: boolean;
    navLinks: (INavLink | LinkResolver<any>)[];
    showSettings?: boolean;
    showLogout?: boolean;
};

export type PageProps<T = Record<string, never>> = {
    setNavState: (config: Partial<NavBarState>) => void;
    navState: NavBarState;
} & T;

export function isINavLink(link: UserLink): link is INavLink {
    console.log(link, "to" in link);
    return "to" in link;
}
