import React from "react";
import { IconProps } from "./types";

const UnsupportedCountry = ({
    className,
    width = "186",
    height = "186",
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="currentColor"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
            role="img"
            aria-label="Unsupported country image"
        >
            <g clipPath="url(#clip0_1652_22981)">
                <path
                    d="M151.497 34.6601C119.399 2.42181 67.2399 2.30949 35.0016 34.4073C2.76337 66.5052 2.65104 118.664 34.7489 150.902C34.8331 150.987 34.9174 151.071 35.0016 151.155C67.0995 183.394 119.259 183.506 151.497 151.408C183.735 119.31 183.847 67.1511 151.75 34.9128C151.665 34.8286 151.581 34.7443 151.497 34.6601Z"
                    fill="#48536B"
                />
                <path
                    d="M99.8048 107.003L89.9445 102.071C89.6426 101.924 89.453 101.615 89.4565 101.282C89.4495 96.662 85.704 92.9166 81.0845 92.9096H80.1403C79.9051 92.9096 79.6804 92.8148 79.5155 92.6498L78.4834 91.6178C76.9179 90.0417 74.7836 89.1606 72.5616 89.1641H66.1062C62.933 89.1536 60.0265 90.9473 58.6188 93.7906L56.8427 97.3395C56.7584 97.5115 56.618 97.6484 56.446 97.7362L52.8971 99.5124C50.0538 100.923 48.2565 103.826 48.2706 107V110.226C48.2706 111.879 48.7585 113.494 49.6782 114.87L55.7088 123.912C56.5162 125.123 57.629 126.103 58.9313 126.756L62.761 128.672C63.0628 128.82 63.2524 129.128 63.2489 129.462V144.23C63.2489 145.528 63.5543 146.81 64.1335 147.975L67.054 153.816C67.4577 154.62 67.9842 155.357 68.6231 155.993L73.2215 160.591C75.77 163.143 79.6629 163.775 82.8853 162.16L85.3636 160.921C87.6803 159.773 89.3302 157.611 89.8286 155.073L92.8545 139.944C92.8896 139.772 92.9738 139.614 93.0967 139.491L98.8219 133.766C99.7381 132.846 100.433 131.726 100.844 130.494L104.003 121.013C104.287 120.16 104.435 119.265 104.435 118.362V114.487C104.445 111.314 102.652 108.407 99.8083 107L99.8048 107.003Z"
                    fill="black"
                />
                <path
                    d="M138.148 33.0106C136.705 33.0632 135.29 33.4213 133.995 34.0637L127.266 37.4265C126.301 37.9109 125.42 38.5498 124.654 39.315L118.255 45.7142C116.36 47.6063 115.296 50.1758 115.296 52.8576C115.303 58.4319 119.821 62.9496 125.395 62.9567H126.525C128.094 62.9567 129.642 62.5881 131.046 61.8895L137.344 58.7373C137.709 58.5583 138.109 58.4635 138.516 58.4635H143.371C144.063 58.4635 144.726 58.7408 145.217 59.2288L145.99 60.001C147.889 61.8895 150.455 62.9531 153.13 62.9602H161.881L169.214 60.8154C166.268 53.8791 162.33 47.2623 157.395 41.186C155.639 39.0237 153.758 36.9315 151.754 34.9167C151.669 34.8324 151.585 34.7482 151.501 34.6639C150.139 33.2949 148.742 31.9856 147.31 30.7324"
                    fill="#1B222B"
                />
                <path
                    d="M99.8048 107.004L89.9445 102.072C89.6426 101.924 89.453 101.616 89.4565 101.282C89.4495 96.6625 85.704 92.9171 81.0845 92.91H80.1403C79.9051 92.91 79.6804 92.8153 79.5155 92.6503L78.4834 91.6183C76.9179 90.0422 74.7836 89.1611 72.5616 89.1646H66.1062C62.933 89.1541 60.0265 90.9478 58.6188 93.7911L56.8427 97.34C56.7584 97.512 56.618 97.6489 56.446 97.7367L52.8971 99.5129C50.0538 100.924 48.2565 103.827 48.2706 107V110.226C48.2706 111.88 48.7585 113.494 49.6782 114.87L55.7088 123.913C56.5162 125.124 57.629 126.103 58.9313 126.756L62.761 128.673C63.0628 128.82 63.2524 129.129 63.2489 129.462V144.23C63.2489 145.529 63.5543 146.81 64.1335 147.976L67.054 153.817C67.4577 154.621 67.9842 155.358 68.6231 155.993L73.2215 160.591C75.77 163.143 79.6629 163.775 82.8853 162.161L85.3636 160.921C87.6803 159.774 89.3302 157.611 89.8286 155.073L92.8545 139.944C92.8896 139.772 92.9738 139.614 93.0967 139.491L98.8219 133.766C99.7381 132.846 100.433 131.727 100.844 130.494L104.003 121.013C104.287 120.16 104.435 119.265 104.435 118.363V114.488C104.445 111.314 102.652 108.408 99.8083 107L99.8048 107.004Z"
                    fill="#1B222B"
                />
                <path
                    d="M151.498 151.409C162.597 140.358 169.888 126.928 173.37 112.75C176.684 99.2531 176.54 85.0786 172.938 71.6343L164.808 70.8164H150.581C150.178 70.8164 149.777 70.7497 149.395 70.6234L141.381 67.952C139.356 67.2781 137.179 67.1938 135.105 67.7134L123.493 70.6163C122.246 70.9288 121.064 71.4518 119.996 72.1644L113.179 76.7102C110.048 78.7882 108.17 82.2985 108.174 86.058V92.0641C108.174 93.2716 108.37 94.4721 108.753 95.6165L110.403 100.573C111.926 105.164 116.223 108.264 121.064 108.257H121.386C123.131 108.257 124.848 107.85 126.406 107.07L128.969 105.789C130.819 104.866 133.069 105.617 133.992 107.467C134.252 107.987 134.385 108.559 134.385 109.141V110.233C134.385 111.978 134.792 113.694 135.571 115.253L141.483 127.075C141.742 127.595 141.876 128.164 141.876 128.743V136.992C141.876 138.199 142.072 139.4 142.455 140.548C142.48 140.622 142.504 140.692 142.536 140.765L145.035 146.951L151.501 151.405L151.498 151.409Z"
                    fill="#1B222B"
                />
                <path
                    d="M53.7435 20.4053C47.0214 24.0665 40.7029 28.7351 35.0022 34.4077C24.9277 44.4401 17.9915 56.4276 14.1934 69.1558L20.9682 73.319C21.6316 73.5788 22.3161 73.7788 23.0147 73.9193L25.191 80.4484C25.9914 82.8529 27.6236 84.8888 29.7965 86.1947L41.7489 93.3626C45.9191 95.876 51.2653 95.2195 54.7018 91.769L54.9054 91.5654C58.1384 88.3394 58.9387 83.4075 56.8957 79.3251C56.4534 78.4475 56.4534 77.4155 56.8957 76.5379L58.2858 73.7543C58.5315 73.2664 58.8966 72.8521 59.3494 72.5503L68.7113 66.309C68.8201 66.2388 68.9324 66.1721 69.0483 66.1159L87.3367 56.9717C90.9418 55.1885 93.2164 51.5097 93.2024 47.487V47.3536C93.1954 41.502 88.453 36.7561 82.6014 36.7491H74.9771C73.7941 36.7491 72.7165 36.0821 72.1935 35.022L71.1263 32.8948C70.3295 31.3082 69.1501 29.9427 67.6933 28.9282L61.9084 24.9125L53.747 20.4123L53.7435 20.4053Z"
                    fill="#1B222B"
                />
            </g>
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M137.792 59.3061C137.825 59.3246 137.851 59.3389 137.869 59.3489L137.899 59.3654C138.14 59.4947 138.439 59.4937 138.68 59.3658L138.711 59.3489C138.729 59.3389 138.755 59.3246 138.788 59.3061C138.853 59.2691 138.946 59.2152 139.064 59.1445C139.298 59.0033 139.629 58.795 140.025 58.5218C140.814 57.9763 141.868 57.1669 142.924 56.1097C145.026 54.0054 147.209 50.8439 147.209 46.778C147.209 41.9613 143.216 38.0566 138.29 38.0566C133.364 38.0566 129.371 41.9613 129.371 46.778C129.371 50.8439 131.554 54.0054 133.656 56.1097C134.712 57.1669 135.766 57.9763 136.555 58.5218C136.951 58.795 137.282 59.0033 137.516 59.1445C137.634 59.2152 137.727 59.2691 137.792 59.3061ZM138.29 49.9494C140.081 49.9494 141.533 48.5295 141.533 46.778C141.533 45.0265 140.081 43.6066 138.29 43.6066C136.499 43.6066 135.047 45.0265 135.047 46.778C135.047 48.5295 136.499 49.9494 138.29 49.9494Z"
                fill="#8C9FB6"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M65.5503 67.333C65.5829 67.3515 65.6086 67.3657 65.6267 67.3758L65.6569 67.3922C65.8979 67.5216 66.1968 67.5206 66.4379 67.3926L66.4689 67.3758C66.4871 67.3657 66.5127 67.3515 66.5454 67.333C66.6107 67.296 66.704 67.242 66.8213 67.1714C67.0559 67.0301 67.387 66.8219 67.7825 66.5487C68.5722 66.0031 69.6259 65.1938 70.6819 64.1365C72.7837 62.0323 74.9667 58.8707 74.9667 54.8048C74.9667 49.9882 70.9736 46.0835 66.0478 46.0835C61.122 46.0835 57.1289 49.9882 57.1289 54.8048C57.1289 58.8707 59.312 62.0323 61.4137 64.1365C62.4697 65.1938 63.5234 66.0031 64.3131 66.5487C64.7086 66.8219 65.0398 67.0301 65.2743 67.1714C65.3916 67.242 65.485 67.296 65.5503 67.333ZM66.0478 57.9762C67.839 57.9762 69.2911 56.5564 69.2911 54.8048C69.2911 53.0533 67.839 51.6334 66.0478 51.6334C64.2566 51.6334 62.8046 53.0533 62.8046 54.8048C62.8046 56.5564 64.2566 57.9762 66.0478 57.9762Z"
                fill="white"
            />
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M92.3061 129.759C92.3388 129.778 92.3644 129.793 92.3826 129.803L92.4128 129.821C92.6537 129.955 92.9526 129.954 93.1938 129.821L93.2248 129.803C93.2429 129.793 93.2686 129.778 93.3013 129.759C93.3666 129.72 93.4599 129.664 93.5772 129.591C93.8118 129.443 94.1429 129.226 94.5384 128.942C95.3281 128.374 96.3818 127.531 97.4378 126.429C99.5396 124.237 101.723 120.944 101.723 116.709C101.723 111.691 97.7295 107.624 92.8037 107.624C87.8779 107.624 83.8848 111.691 83.8848 116.709C83.8848 120.944 86.0678 124.237 88.1696 126.429C89.2256 127.531 90.2793 128.374 91.069 128.942C91.4645 129.226 91.7956 129.443 92.0302 129.591C92.1475 129.664 92.2408 129.72 92.3061 129.759ZM92.8037 120.012C94.5949 120.012 96.0469 118.533 96.0469 116.709C96.0469 114.884 94.5949 113.405 92.8037 113.405C91.0125 113.405 89.5604 114.884 89.5604 116.709C89.5604 118.533 91.0125 120.012 92.8037 120.012Z"
                fill="#8C9FB6"
            />
            <defs>
                <clipPath id="clip0_1652_22981">
                    <rect
                        width="165"
                        height="165"
                        fill="white"
                        transform="translate(10.75 10.408)"
                    />
                </clipPath>
            </defs>
        </svg>
    );
};

export default UnsupportedCountry;
