import clsx from "clsx";
import React from "react";

type OnboardingLayoutProps = {
    children: React.ReactNode;
    testId?: string;
};

export function OnboardingLayout({ children, testId }: OnboardingLayoutProps) {
    return (
        <div
            data-testid={testId}
            className={clsx(
                "flex flex-col items-center xl:flex-row",
                "gap-[42px] lg:gap-10 xl:gap-[120px]",
                "h-screen p-16 pt-8",
                "first:flex first:w-full first:flex-col first:gap-3",
                "lg:first:max-w-[400px]",
                "last:w-full lg:last:max-w-[500px]",
            )}
        >
            {children}
        </div>
    );
}

OnboardingLayout.LeftContent = ({
    children,
}: {
    children?: React.ReactNode;
}) => (
    <>
        <div
            className={clsx(
                "bg-secondary",
                "relative",
                "md:fixed md:h-full md:w-[480px] md:max-w-[320px] md:p-6 lg:max-w-[480px]",
            )}
        >
            <div
                className={clsx(
                    "h-full",
                    "flex flex-col",
                    "justify-between",
                    "max-sm:px-8 max-sm:py-10",
                )}
            >
                {children}
            </div>
        </div>
    </>
);

OnboardingLayout.Footer = ({ children }: { children: React.ReactNode }) => (
    <div className="mb-16 hidden md:block lg:mx-14">{children}</div>
);

OnboardingLayout.FooterMobile = ({
    children,
}: {
    children: React.ReactNode;
}) => <div className="mb-2 mt-5 block md:hidden">{children}</div>;

OnboardingLayout.Header = ({
    title,
    subtitle,
}: {
    title: string;
    subtitle?: string;
}) => (
    <div className="lg:mx-14">
        <h2 className="hidden md:block">{title}</h2>
        <h3 className="block md:hidden">{title}</h3>
        <p className="body-m subdued mt-2">{subtitle}</p>
    </div>
);

OnboardingLayout.RightContent = ({
    children,
}: {
    children: React.ReactNode;
}) => (
    <div
        className={clsx(
            "px-6 pb-10 pt-4",
            "flex flex-col",
            "items-center justify-center",
            "max-sm:flex-1 md:ml-[320px] md:min-h-screen md:px-4 md:pt-10 lg:ml-[480px]",
        )}
    >
        {children}
    </div>
);
