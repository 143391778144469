import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { Section, Card, ProgressDownload, Icon } from "@/ui";
import { log, ddActions } from "@/utils";
import {
    DownloadsSection,
    useProfileDownloads,
} from "@/entities/CompanyProfile";
import { BulkDownloadStatus } from "@/entities/Company";

export const Downloads = ({
    isVisible = false,
    description,
    publicToken,
}: DownloadsSection & { publicToken: string }) => {
    const { task, isPolling, startDownload, stopDownload, isError } =
        useProfileDownloads(publicToken);
    const isFailure =
        isError ||
        task?.state === BulkDownloadStatus.Failure ||
        task?.state === BulkDownloadStatus.Revoked;
    const isSuccess = task?.state === BulkDownloadStatus.Success;

    useEffect(() => {
        if (isFailure) {
            datadogRum.addAction(ddActions.profile.downloadChartbook.fail, {
                company_token: publicToken,
            });
            log.error("Failed to download chartbook", {
                company_token: publicToken,
            });
        }
    }, [isFailure, publicToken]);

    if (!isVisible) return null;

    return (
        <Section title="Downloads" className="py-10">
            {description && (
                <p className="subdued body-m mb-4">{description}</p>
            )}

            <Card className="flex items-center justify-between">
                <div className="flex items-center gap-2">
                    <Icon.ExcelFile />
                    <p className="ui-element subdued">
                        Hum Standardized Data & Chartbook
                    </p>
                </div>
                <Icon.Download
                    dd-action={ddActions.profile.downloadChartbook.click}
                    onClick={() => startDownload()}
                />
            </Card>
            <ProgressDownload
                onCancel={() => stopDownload()}
                isPolling={isPolling}
                isFailure={isFailure}
                isSuccess={isSuccess}
            />
        </Section>
    );
};
