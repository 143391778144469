import lo from "lodash";

export function snakeCased(data) {
    if (Array.isArray(data)) return data.map(snakeCased);
    if (data === null) return data;
    if (typeof data !== "object") return data;
    const snakeCasedData = {};
    lo.mapKeys(data, (value, key) => {
        snakeCasedData[lo.snakeCase(key)] = snakeCased(value);
    });
    return snakeCasedData;
}
