import React from "react";
import { IconProps } from "./types";

const ChatIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#92ABFA" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.6692 18C23.4333 18 21.2386 18.1791 19.0989 18.5237C17.6626 18.7551 16.6692 20.0133 16.6692 21.4259V26.5741C16.6692 27.9868 17.6626 29.2449 19.099 29.4763C19.9473 29.6129 20.8044 29.7236 21.6692 29.8073V33.25C21.6692 33.5533 21.8519 33.8268 22.1322 33.9429C22.4124 34.059 22.735 33.9948 22.9495 33.7803L26.5286 30.2013C26.6644 30.0654 26.8524 29.9837 27.0561 29.977C28.8139 29.9185 30.5439 29.7494 32.2394 29.4763C33.6758 29.2449 34.6692 27.9867 34.6692 26.5741V21.4259C34.6692 20.0133 33.6758 18.7551 32.2394 18.5237C30.0998 18.1791 27.9051 18 25.6692 18ZM25.6692 25C26.2215 25 26.6692 24.5523 26.6692 24C26.6692 23.4477 26.2215 23 25.6692 23C25.1169 23 24.6692 23.4477 24.6692 24C24.6692 24.5523 25.1169 25 25.6692 25ZM23.6692 24C23.6692 24.5523 23.2215 25 22.6692 25C22.1169 25 21.6692 24.5523 21.6692 24C21.6692 23.4477 22.1169 23 22.6692 23C23.2215 23 23.6692 23.4477 23.6692 24ZM28.6692 25C29.2215 25 29.6692 24.5523 29.6692 24C29.6692 23.4477 29.2215 23 28.6692 23C28.1169 23 27.6692 23.4477 27.6692 24C27.6692 24.5523 28.1169 25 28.6692 25Z"
                        fill="#2A313F"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ChatIcon;
