import clsx from "clsx";
import { FilterIndicator } from "../../molecules";

type FilterBarProps = {
    isFiltering?: boolean;
    filterText?: string;
    children?: React.ReactNode;
    testId?: string;
    className?: string;
    title?: string;
    appliedFilters?: string;
};

export function FilterBar({
    testId,
    className,
    children,
    isFiltering = false,
    appliedFilters,
    title,
}: FilterBarProps) {
    return (
        <div className={clsx("mb-2 w-full", className)} data-testid={testId}>
            <div
                className={clsx(
                    "mb-5 mt-2",
                    "flex flex-col",
                    "sm:flex-row sm:justify-between",
                )}
            >
                <FilterIndicator isFiltering={isFiltering} />
                {children}
            </div>

            <div
                className={clsx(
                    "w-full",
                    "flex flex-col",
                    "items-baseline gap-x-3",
                    "sm:flex-row",
                )}
            >
                {title && <h4>{title}</h4>}
                {appliedFilters && (
                    <p className="ui-element">{appliedFilters}</p>
                )}
            </div>
        </div>
    );
}
