import React from "react";
import { IconProps } from "../types";

const ClearbooksIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_3963)">
                        <path
                            d="M28.7455 16.6582H19.8139C18.5748 16.6582 17.5615 17.6721 17.5615 18.9106V30.5478C17.5615 31.7863 18.5748 32.8002 19.8139 32.8002H28.7455C29.9847 32.8002 30.9979 31.7863 30.9979 30.5478V18.9113C30.9979 17.6727 29.9847 16.6588 28.7455 16.6588V16.6582ZM29.7255 30.2853C29.7255 30.9049 29.2185 31.4112 28.5989 31.4112H19.9637C19.3441 31.4112 18.8378 30.9049 18.8378 30.2853V19.1737C18.8378 18.5541 19.3441 18.0472 19.9637 18.0472H28.5983C29.2179 18.0472 29.7248 18.5541 29.7248 19.1737V30.286L29.7255 30.2853Z"
                            fill="#268CC0"
                        />
                        <path
                            d="M11.4048 21.9513C10.7852 21.9513 10.2783 21.4443 10.2783 20.8254V9.71309C10.2783 9.0935 10.7852 8.5872 11.4048 8.5872H20.0394C20.659 8.5872 21.1653 9.0935 21.1653 9.71309V10.9894H22.439V9.45066C22.439 8.21212 21.4258 7.19824 20.1866 7.19824H11.2544C10.0152 7.19824 9.00195 8.21212 9.00195 9.45066V21.0872C9.00195 22.3264 10.0152 23.3409 11.2544 23.3409H12.2305V21.9519H11.4048V21.9513Z"
                            fill="#D92A2D"
                        />
                        <path
                            d="M15.8345 26.7566C15.2155 26.7566 14.7092 26.2503 14.7092 25.6307V14.5184C14.7092 13.8988 15.2155 13.3919 15.8345 13.3919H24.4697C25.0893 13.3919 25.5956 13.8988 25.5956 14.5184V15.5694H26.868V14.256C26.868 13.0168 25.8548 12.0029 24.6163 12.0029H15.6841C14.4455 12.0029 13.4316 13.0168 13.4316 14.256V25.8931C13.4316 27.1317 14.4462 28.1449 15.6841 28.1449H16.36V26.7566H15.8345Z"
                            fill="#F3B21D"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_3963">
                            <rect
                                width="21.9961"
                                height="25.6029"
                                fill="white"
                                transform="translate(9.00195 7.19824)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default ClearbooksIcon;
