export interface IBoxPlotData {
    name: string;
    IQR: number;
    q0: number;
    q1: number;
    q2: number;
    q3: number;
    q4: number;
    calloutP?: number;
    values?: number[];
}

export interface IDrawFunctionArgs<T> {
    width: number;
    height: number;
    data: T;
    id: string;
}
