import React from "react";
import { IconProps } from "./types";

const PercentIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#3E4758" />
                    <path
                        d="M31.2656 20.7401C30.7395 20.214 29.8866 20.2136 29.3601 20.7393L20.7982 29.2881C20.2674 29.818 20.2671 30.6779 20.7974 31.2082C21.3277 31.7385 22.1876 31.7382 22.7175 31.2075L31.2663 22.6456C31.792 22.1191 31.7917 21.2662 31.2656 20.7401Z"
                        fill="#CBD5E1"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.775 24.2143C23.1224 24.2143 24.2142 23.1227 24.2142 21.7738C24.2142 20.4249 23.1224 19.3333 21.775 19.3333C20.4276 19.3333 19.3333 20.4249 19.3333 21.7738C19.3333 23.1227 20.4276 24.2143 21.775 24.2143ZM30.2274 32.6667C31.5748 32.6667 32.6666 31.5751 32.6666 30.2262C32.6666 28.8748 31.5748 27.7857 30.2274 27.7857C28.8799 27.7857 27.7856 28.8748 27.7856 30.2262C27.7856 31.5751 28.8799 32.6667 30.2274 32.6667Z"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default PercentIcon;
