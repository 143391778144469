import React from "react";
import { IconProps } from "./types";

const LineChartupIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="none"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#3E4758" />
                    <path
                        d="M20.1667 31.8333H32.6667C33.1269 31.8333 33.5 32.2064 33.5 32.6667C33.5 33.1269 33.1269 33.5 32.6667 33.5H19.3333C18.8731 33.5 18.5 33.1269 18.5 32.6667V19.3333C18.5 18.8731 18.8731 18.5 19.3333 18.5C19.7936 18.5 20.1667 18.8731 20.1667 19.3333V31.8333Z"
                        fill="#CBD5E1"
                    />
                    <path
                        d="M23.2747 28.2366C22.9599 28.5724 22.4325 28.5894 22.0968 28.2746C21.761 27.9598 21.744 27.4325 22.0588 27.0967L25.1838 23.7634C25.4882 23.4387 25.9941 23.4105 26.3326 23.6994L28.7991 25.8041L32.0126 21.7336C32.2978 21.3724 32.8218 21.3107 33.1831 21.5959C33.5443 21.8811 33.606 22.4051 33.3208 22.7664L29.5708 27.5164C29.2779 27.8874 28.7354 27.9407 28.3758 27.6339L25.8557 25.4835L23.2747 28.2366Z"
                        fill="#CBD5E1"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default LineChartupIcon;
