import { ExclamationTriangleIcon } from "@heroicons/react/20/solid";
import clsx from "clsx";
import { Spinner } from "../../atoms/Spinner";
import { Tooltip, TooltipSize } from "../../atoms/Tooltip";

export function FilterIndicator({
    isFiltering,
    filterText = "Updating...",
    hasError,
    className,
    hideOnMobile = false,
}: {
    hideOnMobile?: boolean;
    filterText?: string;
    isFiltering?: boolean;
    hasError?: boolean;
    className?: string;
}) {
    return (
        <div
            className={clsx(
                "flex items-center gap-4",
                hideOnMobile && "hidden sm:flex",
                className,
            )}
        >
            {hasError && (
                <>
                    <ExclamationTriangleIcon width={20} className="subdued" />
                    <Tooltip
                        position="top"
                        size={TooltipSize.XL}
                        content="There was an error while filtering. Please try again or refresh your browser."
                    >
                        <p className="text-button subdued">Filter error</p>
                    </Tooltip>
                </>
            )}
            {isFiltering && (
                <>
                    <Spinner />
                    <p className="subdued text-button">{filterText}</p>
                </>
            )}
        </div>
    );
}
