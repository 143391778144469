import { FilterState } from "@/utilityTypes";

/**
 * A lightweight utility class for building a query string. It supports chaining methods to build complex query strings.
 * This class provides methods to add individual key-value pairs, apply specific formatting like equality, inclusion, and
 * fuzzy matching, and finally compile these into a complete query string.
 *
 * @example
 * let query = new QueryStringBuilder()
 *    .add('param1', 123)
 *    .equals("param2", 456)
 *    .in("param3", ["foo", "bar"])
 *    .like("param4", "searchterm")
 *    .build(); // returns 'param1=123&param2=eq.456&param3=in.("foo","bar")&param4=like.searchterm'
 */
export class QueryStringBuilder {
    /**
     * Stores the key-value pairs that will be compiled into the query string.
     */
    pairs: { key: string; val: string | number }[];

    /**
     * Initializes a new instance of the QueryStringBuilder class.
     */
    constructor() {
        this.pairs = [];
    }

    /**
     * Adds a key-value pair to the query string. If the value is undefined, the pair is not added.
     * This method facilitates the creation of parameterized queries.
     * @param {string} key - The key to add to the query string.
     * @param {string|number|boolean|undefined} [val] - The value to associate with the key. If undefined, the key is not added.
     * @return {this} Returns this instance for chaining further calls.
     */
    add(key: string, val?: string | number | boolean | undefined) {
        if (val !== undefined) {
            this.pairs.push({ key, val: `${val}` });
        }
        return this;
    }

    /**
     * Adds a key with a value prefixed by "eq." to indicate equality. Does not add the key if the value is undefined.
     * @param {string} key - The key to add.
     * @param {string|number|boolean|undefined} [val] - The value to be prefixed and associated with the key.
     * @return {this} Returns this instance for chaining.
     */
    equals(key: string, val?: string | number | boolean | undefined) {
        return val !== undefined ? this.add(key, `eq.${val}`) : this;
    }

    /**
     * Adds a key with a value prefixed by "like." for fuzzy matching. Does not add the key if the value is undefined.
     * @param {string} key - The key for the query parameter.
     * @param {string|number|boolean|undefined} [val] - The value for partial matching.
     * @return {this} Returns this instance for chaining.
     */
    like(key: string, val?: string | number | boolean | undefined) {
        return val !== undefined ? this.add(key, `like.${val}`) : this;
    }

    /**
     * Adds a key with a value prefixed by "ilike." for case-insensitive fuzzy matching. Does not add the key if the value is undefined.
     * @param {string} key - The key for the query parameter.
     * @param {string|number|boolean|undefined} [val] - The value for case-insensitive partial matching.
     * @return {this} Returns this instance for chaining.
     */
    ilike(key: string, val?: string | number | boolean | undefined) {
        return val !== undefined ? this.add(key, `ilike.${val}`) : this;
    }

    /**
     * Formats and adds a key with multiple values indicated by "in." prefix. Does not add the key if the values array is undefined.
     * @param {string} key - The key for the query parameter.
     * @param {(string|number)[] | FilterState | false} [collection] - The array of values for the key, or a special filter state object.
     * @return {this} Returns this instance for chaining.
     */
    in(key: string, collection?: (string | number)[] | FilterState | false) {
        if (!collection) return this;

        const isFilterState = collection && "filterSet" in collection;

        if (isFilterState) {
            const state = collection as FilterState;
            if (state.hasSelections) this.#addCollection(key, state.filterSet);
        } else if (Array.isArray(collection)) {
            this.#addCollection(key, collection);
        }

        return this;
    }

    /**
     * A private method to format and add a query parameter with multiple values, prefixed with "in.".
     * This method constructs a string from the values array and adds it to a query parameter
     * identified by the given key. The values are formatted as a comma-separated list enclosed in parentheses,
     * with each value quoted. The method is utilized internally by the 'in' method when the collection
     * argument is not a FilterState or false, indicating a direct array of values to be processed.
     *
     * @private
     * @param {string} key - The key for the query parameter to which the collection will be added.
     * @param {(string | number)[]} vals - An array of values (string or number) that are to be
     * formatted and added as the value for the specified key. The array should not be undefined
     * and is expected to contain at least one value.
     * @returns {void} This method does not return a value. It directly modifies the instance's
     * state by adding a formatted query parameter.
     */
    #addCollection(key: string, vals: (string | number)[]) {
        const concatenation = vals.reduce(
            (acc, val, index) => acc + `${index === 0 ? "" : ","}"${val}"`,
            "",
        );
        this.add(key, `in.(${concatenation})`);
    }

    /**
     * Constructs the query string from added key-value pairs.
     * @return {string} The constructed query string.
     */
    build() {
        return this.pairs.map((pair) => `${pair.key}=${pair.val}`).join("&");
    }

    /**
     * Creates a new QueryStringBuilder instance from a hash object containing key-value pairs.
     * This static method allows for easy instantiation of a query builder with pre-defined parameters.
     * @param {object} hash - The object with key-value pairs to be added to the query string.
     * @return {QueryBuilder} A new instance populated with the hash's key-value pairs.
     */
    static fromHash(hash: object): QueryStringBuilder {
        const query = new QueryStringBuilder();
        Object.entries(hash).forEach(([key, val]) => {
            if (val !== undefined) {
                query.add(key, val);
            }
        });
        return query;
    }
}
