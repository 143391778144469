import { Fragment, useEffect, useState } from "react";
import clsx from "clsx";
import { Icon, Spinner } from "../../atoms";
import { ExclamationTriangleIcon, CheckIcon } from "@heroicons/react/20/solid";
import { Transition } from "@headlessui/react";

type ProgressDownloadProps = {
    isPolling: boolean;
    isFailure: boolean;
    isSuccess: boolean;
    text?: string;
    onCancel?: () => void;
    onClose?: () => void;
    testId?: string;
    className?: string;
};

export function ProgressDownload({
    isPolling,
    isFailure,
    isSuccess,
    text = "Gathering data. This process should only take a couple of minutes. Please wait...",
    onCancel,
    onClose,
    testId,
    className,
}: ProgressDownloadProps) {
    const [hasPolled, setHasPolled] = useState(false);
    const [show, setShow] = useState(false);

    useEffect(() => {
        if (isSuccess || isFailure) setHasPolled(true);
        if ((isPolling || isSuccess || isFailure) && !show) setShow(true);
    }, [isPolling]);

    return (
        <div data-testid={testId}>
            <Transition
                as={Fragment}
                show={show}
                enter="transform transition duration-[600ms]"
                enterFrom="translate-y-[200px]"
                enterTo="translate-y-0"
                leave="transform transition duration-[600ms]"
                leaveFrom="translate-y-2"
                leaveTo="translate-y-[200px]"
            >
                <div
                    className={clsx(
                        "w-full",
                        "max-w-[468px]",
                        "bg-gray-700",
                        "rounded-[8px]",
                        "border",
                        "border-default",
                        "p-6",
                        "fixed",
                        "z-50",
                        "bottom-[32px]",
                        "right-[32px]",
                        "shadow-default",
                        className,
                    )}
                >
                    <div className="flex items-center justify-between">
                        <div>
                            <h5 className="heading-m">Download</h5>
                        </div>
                        <div className="flex items-center">
                            {isPolling && onCancel && (
                                <button
                                    className="secondary"
                                    onClick={() => {
                                        onCancel();
                                        setShow(false);
                                    }}
                                >
                                    Cancel
                                </button>
                            )}
                            {hasPolled && (
                                <Icon.Close
                                    className="ml-5 cursor-pointer"
                                    onClick={() => {
                                        setShow(false);
                                        setHasPolled(false);
                                        onClose && onClose();
                                    }}
                                />
                            )}
                        </div>
                    </div>
                    <div className="mt-4 flex items-center justify-between">
                        <p
                            className={clsx(
                                "ui-element mr-2",
                                "subdued overflow-hidden text-ellipsis",
                            )}
                        >
                            {isPolling && text}
                            {isSuccess && "Success!"}
                            {isFailure &&
                                "Error: data mapping is still processing. Please try again later and contact us if the error persists"}
                        </p>
                        <div className="flex">
                            {isFailure && (
                                <ExclamationTriangleIcon
                                    data-testid="polling-error-icon"
                                    fill="#FF8A8A"
                                    width={16}
                                />
                            )}
                            {isSuccess && (
                                <CheckIcon
                                    data-testid="polling-success-icon"
                                    fill="#72C681"
                                    width={16}
                                />
                            )}
                            {isPolling && <Spinner />}
                        </div>
                    </div>
                </div>
            </Transition>
        </div>
    );
}
