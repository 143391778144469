import { createRoot } from "react-dom/client";
import { RootContainer } from "./RootContainer";
import { initDataDog } from "@/utils";
import { BrowserRouter } from "react-router-dom";
import dotenv from "dotenv";

dotenv.config();

initDataDog();

const container = document.getElementById("app");
if (container) {
    const root = createRoot(container);
    root.render(
        <BrowserRouter>
            <RootContainer />
        </BrowserRouter>,
    );
}
