import { useRouter } from "@/utils";
import clsx from "clsx";

type Option = {
    label: string;
    path: string;
    component: React.ComponentType;
};

export const Sidebar = ({ options }: { options: Option[] }) => {
    const router = useRouter();
    const selectedOption = router.pathname;

    return (
        <div
            className={clsx(
                "pt-14",
                "hidden",
                "lg:fixed lg:inset-y-0 lg:flex lg:w-72 lg:flex-col",
            )}
        >
            <div
                className={clsx(
                    "bg-[#F8FAFC0F] p-6",
                    "flex flex-col",
                    "grow gap-y-5 overflow-y-auto",
                )}
            >
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-col gap-y-4">
                        {options.map((option) => (
                            <li key={option.path}>
                                <a
                                    onClick={() => router.navigate(option.path)}
                                    className={clsx(
                                        "flex items-center justify-between",
                                        "body-s",
                                        "hover:bg-slate-700",
                                        "rounded-lg",
                                        "p-3",
                                        selectedOption === option.path &&
                                            "bg-slate-700",
                                    )}
                                >
                                    <span>{option.label}</span>
                                </a>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};
