import clsx from "clsx";

export function NotFoundPage() {
    return (
        <main
            className={clsx(
                "px-6 py-24",
                "grid min-h-full place-items-center",
                "sm:py-32 lg:px-8",
            )}
        >
            <div className="text-center">
                <p className="text-cta-primary-default text-base font-semibold">
                    404
                </p>
                <h2
                    className={clsx(
                        "mt-4",
                        "text-defaultTextColor text-3xl",
                        "font-bold tracking-tight",
                        "sm:text-5xl",
                    )}
                >
                    Page not found
                </h2>
                <p className="subdued mt-6 text-base leading-7">
                    Sorry, we couldn’t find the page you’re looking for.
                </p>
                <div
                    className={clsx(
                        "mt-10",
                        "flex items-center justify-center gap-x-6",
                    )}
                >
                    <a
                        href="/"
                        className={clsx(
                            "bg-cta-primary-default px-3.5 py-2.5",
                            "text-sm text-white",
                            "rounded-md font-semibold shadow-sm",
                            "focus-visible:outline-cta-primary-default hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2",
                        )}
                    >
                        Go back home
                    </a>
                    <a
                        href="mailto:hello@humcapital.com"
                        className="link text-sm font-semibold"
                    >
                        Contact support <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </main>
    );
}
