import React from "react";
import { IconProps } from "./types";

const CircleExclamationIcon = ({
    className,
    width = "20",
    height = "20",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M18 10C18 14.4183 14.4183 18 10 18C5.58172 18 2 14.4183 2 10C2 5.58172 5.58172 2 10 2C14.4183 2 18 5.58172 18 10ZM10 5C10.4142 5 10.75 5.33579 10.75 5.75V10.25C10.75 10.6642 10.4142 11 10 11C9.58579 11 9.25 10.6642 9.25 10.25V5.75C9.25 5.33579 9.58579 5 10 5ZM10 15C10.5523 15 11 14.5523 11 14C11 13.4477 10.5523 13 10 13C9.44771 13 9 13.4477 9 14C9 14.5523 9.44771 15 10 15Z"
                        fill="#E29036"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleExclamationIcon;
