import { HighlightsIcons } from "./CompanyProfile.constants";

export type CompanyProfile = {
    isLive: boolean;
    publicToken: string;
    companyId?: string;
    hero: ProfileHero;
    financialHighlights: HighlightsSection;
    downloads: DownloadsSection;
    isPrivate: boolean;
    isReadyForSyndication: boolean;
};

export type PrivateCompanyProfile = CompanyProfile & { isPrivate: true };
export type PublicCompanyProfile = CompanyProfile & { isPrivate: false };

export type ProfileHero = {
    publicHeadline: string;
    publicDescription?: string;
    privateHeadline?: string;
    privateDescription?: string;
    callouts?: { title: string; value: string }[];
};

export type HighlightsSection = {
    title?: string;
    description?: string;
    lastUpdated: string;
    month: string;
    year: string;
    highlights?: { title: string; value: string; icon: HighlightsIcons }[];
};

export type DownloadsSection = {
    description?: string;
    isVisible: boolean;
};

export type HighlightsSectionForm = HighlightsSection & {
    month: string;
    year: string;
};
