import React from "react";

import { IconProps } from "./types";
import ArrowLeftIcon from "./ArrowLeftIcon";
import ArrowRightIcon from "./ArrowRightIcon";
import ChartIcon from "./ChartIcon";
import ChatIcon from "./ChatIcon";
import CheckIcon from "./CheckIcon";
import ChevronDownIcon from "./ChevronDownIcon";
import ChevronRightIcon from "./ChevronRightIcon";
import CircleChartIcon from "./CircleChartIcon";
import CircleCheckIcon from "./CircleCheckIcon";
import CircleExclamationIcon from "./CircleExclamationIcon";
import CircleFileCheckIcon from "./CircleFileCheckIcon";
import CircleHandIcon from "./CircleHandIcon";
import CirclePuzzleIcon from "./CirclePuzzleIcon";
import CircleSignatureIcon from "./CircleSignatureIcon";
import CircleStarIcon from "./CircleStarIcon";
import CircleUserIcon from "./CircleUserIcon";
import CircleSearchIcon from "./CircleSearchIcon";
import CloseIcon from "./CloseIcon";
import CsvFileIcon from "./CsvFileIcon";
import PdfIcon from "./PdfIcon";
import DefaultFileIcon from "./DefaultFileIcon";
import DownloadIcon from "./DownloadIcon";
import ExcelFileIcon from "./ExcelFileIcon";
import ExternalLinkIcon from "./ExternalLinkIcon";
import FileIcon from "./FileIcon";
import FolderIcon from "./FolderIcon";
import InfoIcon from "./InfoIcon";
import Hum from "./Hum";
import HumConnectErrorIcon from "./HumConnectErrorIcon";
import MiniArchiveBoxIcon from "./MiniArchiveBoxIcon";
import MiniArchiveBoxXMarkIcon from "./MiniArchiveBoxXMarkIcon";
import HumConnectIcon from "./HumConnectIcon";
import MoneyIcon from "./MoneyIcon";
import NoteIcon from "./NoteIcon";
import NotificationStarIcon from "./NotificationStarIcon";
import Padlock from "./Padlock";
import Pencil from "./PencilIcon";
import RefreshIcon from "./RefreshIcon";
import SearchIcon from "./SearchIcon";
import TripleDotIcon from "./TripleDotIcon";
import UserIcon from "./UserIcon";
import VerifyEmail from "./VerifyEmail";
import FinancialGrowth from "./FinancialGrowth";
import UnsupportedCountry from "./UnsupportedCountry";
import InvestorSignupStepOne from "./InvestorSignupStepOne";
import InvestorSignupStepTwo from "./InvestorSignupStepTwo";
import InvestorSignupStepThree from "./InvestorSignupStepThree";
import RingOnIcon from "./RingOnIcon";
import RingOffIcon from "./RingOffIcon";
import BuildingIcon from "./BuildingIcon";
import BarChartupIcon from "./BarChartUpIcon";
import LineChartupIcon from "./LineChartUpIcon";
import TagIcon from "./TagIcon";
import PercentIcon from "./PercentIcon";
import CircleDollarIcon from "./CircleDollarIcon";
import DollarBillIcon from "./DollarBillIcon";

import ClearbooksIcon from "./connectors/ClearbooksIcon";
import ExactIcon from "./connectors/ExactIcon";
import FreeAgentIcon from "./connectors/FreeAgentIcon";
import FreshbooksIcon from "./connectors/FreshbooksIcon";
import KashFlowIcon from "./connectors/KashFlowIcon";
import KashooIcon from "./connectors/KashooIcon";
import MDynamicsIcon from "./connectors/MDynamicsIcon";
import MyobIcon from "./connectors/MyobIcon";
import NetsuiteIcon from "./connectors/NetsuiteIcon";
import PandleIcon from "./connectors/PandleIcon";
import QuickbooksIcon from "./connectors/QuickbooksIcon";
import SageIcon from "./connectors/SageIcon";
import WaveIcon from "./connectors/WaveIcon";
import XeroIcon from "./connectors/XeroIcon";
import GraphImage from "./GraphImage";
import StepInProgress from "./StepInProgessIcon";
import MapPinIcon from "./MapPinIcon";

export type UrlIconProps = IconProps & {
    src: string;
};

export const Icon = ({
    src,
    width = "24",
    height = "24",
    className,
    onClick,
}: UrlIconProps) => (
    <div onClick={onClick} role={onClick ? "button" : undefined}>
        <React.Suspense fallback={null}>
            <img
                src={src}
                width={width}
                height={height}
                className={className}
                alt=""
            />
        </React.Suspense>
    </div>
);

Icon.ArrowLeft = ArrowLeftIcon;
Icon.ArrowRight = ArrowRightIcon;
Icon.Chart = ChartIcon;
Icon.Chat = ChatIcon;
Icon.Check = CheckIcon;
Icon.ChevronDown = ChevronDownIcon;
Icon.ChevronRight = ChevronRightIcon;
Icon.CircleChart = CircleChartIcon;
Icon.CircleCheck = CircleCheckIcon;
Icon.CircleExclamation = CircleExclamationIcon;
Icon.CircleFileCheck = CircleFileCheckIcon;
Icon.CircleHand = CircleHandIcon;
Icon.CirclePuzzle = CirclePuzzleIcon;
Icon.CircleSignature = CircleSignatureIcon;
Icon.CircleStar = CircleStarIcon;
Icon.CircleUser = CircleUserIcon;
Icon.CircleSearch = CircleSearchIcon;
Icon.Close = CloseIcon;
Icon.CsvFile = CsvFileIcon;
Icon.DefaultFile = DefaultFileIcon;
Icon.Download = DownloadIcon;
Icon.ExcelFile = ExcelFileIcon;
Icon.PdfIcon = PdfIcon;
Icon.ExternalLink = ExternalLinkIcon;
Icon.File = FileIcon;
Icon.Folder = FolderIcon;
Icon.Hum = Hum;
Icon.HumConnect = HumConnectIcon;
Icon.HumConnectError = HumConnectErrorIcon;
Icon.Info = InfoIcon;
Icon.MiniArchiveBox = MiniArchiveBoxIcon;
Icon.MiniArchiveBoxXMark = MiniArchiveBoxXMarkIcon;
Icon.Money = MoneyIcon;
Icon.Note = NoteIcon;
Icon.NotificationStar = NotificationStarIcon;
Icon.Padlock = Padlock;
Icon.Pencil = Pencil;
Icon.Refresh = RefreshIcon;
Icon.Search = SearchIcon;
Icon.TripleDot = TripleDotIcon;
Icon.User = UserIcon;
Icon.VerifyEmail = VerifyEmail;
Icon.FinancialGrowth = FinancialGrowth;
Icon.UnsupportedCountry = UnsupportedCountry;
Icon.InvestorSignupStepOne = InvestorSignupStepOne;
Icon.InvestorSignupStepTwo = InvestorSignupStepTwo;
Icon.InvestorSignupStepThree = InvestorSignupStepThree;
Icon.GrphImage = GraphImage;
Icon.RingOn = RingOnIcon;
Icon.RingOff = RingOffIcon;
Icon.Building = BuildingIcon;
Icon.BarChartup = BarChartupIcon;
Icon.LineChartup = LineChartupIcon;
Icon.Tag = TagIcon;
Icon.Percent = PercentIcon;
Icon.CircleDollar = CircleDollarIcon;
Icon.DollarBill = DollarBillIcon;

//Connectors
Icon.Clearbooks = ClearbooksIcon;
Icon.Exact = ExactIcon;
Icon.FreeAgent = FreeAgentIcon;
Icon.Freshbooks = FreshbooksIcon;
Icon.KashFlow = KashFlowIcon;
Icon.Kashoo = KashooIcon;
Icon.MDynamics = MDynamicsIcon;
Icon.Myob = MyobIcon;
Icon.Netsuite = NetsuiteIcon;
Icon.Pandle = PandleIcon;
Icon.Quickbooks = QuickbooksIcon;
Icon.Sage = SageIcon;
Icon.Wave = WaveIcon;
Icon.Xero = XeroIcon;
Icon.StepInProgress = StepInProgress;
Icon.MapPin = MapPinIcon;
