import React from "react";
import { IconProps } from "./types";

const TripleDotIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M12.1523 13.5C12.7046 13.5 13.1523 13.0523 13.1523 12.5C13.1523 11.9477 12.7046 11.5 12.1523 11.5C11.6001 11.5 11.1523 11.9477 11.1523 12.5C11.1523 13.0523 11.6001 13.5 12.1523 13.5Z"
                        stroke="#CBD5E1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M19.1523 13.5C19.7046 13.5 20.1523 13.0523 20.1523 12.5C20.1523 11.9477 19.7046 11.5 19.1523 11.5C18.6001 11.5 18.1523 11.9477 18.1523 12.5C18.1523 13.0523 18.6001 13.5 19.1523 13.5Z"
                        stroke="#CBD5E1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                    <path
                        d="M5.15234 13.5C5.70463 13.5 6.15234 13.0523 6.15234 12.5C6.15234 11.9477 5.70463 11.5 5.15234 11.5C4.60006 11.5 4.15234 11.9477 4.15234 12.5C4.15234 13.0523 4.60006 13.5 5.15234 13.5Z"
                        stroke="#CBD5E1"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default TripleDotIcon;
