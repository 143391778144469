import { Graphics } from "./Graphics";
import { HandShake } from "./HandShake";
import { HumLogo } from "./HumLogo";
import { List } from "./List";
import { NoResults } from "./NoResults";
import { SignedFile } from "./SignedFile";
import { VerifyEmail } from "./VerifyEmail";

export const Illustration = {
    HandShake,
    HumLogo,
    Graphics,
    List,
    NoResults,
    VerifyEmail,
    SignedFile,
};
