import React from "react";
import { IconProps } from "./types";

const CircleChartIcon = ({
    className,
    width = "36",
    height = "36",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="36" height="36" rx="18" fill="#1F2630" />
                    <path
                        d="M23.5 9.5C22.6716 9.5 22 10.1716 22 11V24C22 24.8284 22.6716 25.5 23.5 25.5H24.5C25.3284 25.5 26 24.8284 26 24V11C26 10.1716 25.3284 9.5 24.5 9.5H23.5Z"
                        fill="#788598"
                    />
                    <path
                        d="M17.5 13.5C16.6716 13.5 16 14.1716 16 15V24C16 24.8284 16.6716 25.5 17.5 25.5H18.5C19.3284 25.5 20 24.8284 20 24V15C20 14.1716 19.3284 13.5 18.5 13.5H17.5Z"
                        fill="#788598"
                    />
                    <path
                        d="M11.5 17.5C10.6716 17.5 10 18.1716 10 19V24C10 24.8284 10.6716 25.5 11.5 25.5H12.5C13.3284 25.5 14 24.8284 14 24V19C14 18.1716 13.3284 17.5 12.5 17.5H11.5Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleChartIcon;
