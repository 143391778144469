import clsx from "clsx";

export type TableBodyProps = {
    children: React.ReactNode;
    testId?: string;
    className?: string;
};

export function TableBody({ children, testId, className }: TableBodyProps) {
    return (
        <tbody
            className={clsx(
                "divide-y divide-slate-300 bg-transparent dark:divide-slate-700",
                className,
            )}
            data-testid={testId}
        >
            {children}
        </tbody>
    );
}
