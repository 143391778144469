import { PrimitiveAtom, useAtom } from "jotai";
import { useCallback } from "react";

export function useFlowState(atom: PrimitiveAtom<boolean>) {
    const [showModal, setShowModal] = useAtom(atom);

    const toggle = useCallback(() => {
        setShowModal(!showModal);
    }, [showModal, setShowModal]);

    const close = useCallback(() => {
        setShowModal(false);
    }, [showModal, setShowModal]);

    const open = useCallback(() => {
        setShowModal(true);
    }, [showModal, setShowModal]);

    return {
        isOpen: showModal,
        isClosed: !showModal,
        toggle,
        close,
        open,
    };
}
