import { ModalProps } from "./types";
import clsx from "clsx";

// Modal with transitions, can't be used until react and testing-library versions are updated.
// The headlessui elements are not accessible with the outdated version of testing-library in icm.

export function Modal({
    testId,
    title,
    description,
    isOpen,
    children,
    wide = false,
    className,
}: ModalProps) {
    return (
        <div
            className={clsx("relative z-50", { hidden: !isOpen })}
            data-testid={testId}
        >
            <div className="fixed inset-0 bg-slate-900 opacity-40" />

            <div className="fixed inset-0 z-10 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center text-center">
                    <div
                        className={clsx(
                            `border-default shadow-default bg-default border-default relative rounded-lg
                            border text-left transition-all`,
                            wide ? "w-[514px]" : "w-[424px]",
                            className,
                        )}
                    >
                        {title && (
                            <div className="p-8">
                                {title && <h4>{title}</h4>}

                                {description && (
                                    <div className="mt-4 text-wrap">
                                        <p className="body-m subdued">
                                            {description}
                                        </p>
                                    </div>
                                )}
                            </div>
                        )}
                        {children}
                    </div>
                </div>
            </div>
        </div>
    );
}
