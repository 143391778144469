import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import { Tooltip, TooltipSize } from "../../atoms";
import clsx from "clsx";

type TextCopyProps = {
    testId?: string;
    text: string;
};

export function TextCopy({ testId, text }: TextCopyProps) {
    return (
        <div className="mt-5" data-testid={testId}>
            <p
                className={clsx(
                    "ui-element pb-1 pl-4",
                    "text-disabledTextColor text-[12px]",
                )}
            >
                License key
            </p>
            <div
                className={clsx(
                    "w-full bg-transparent p-3 pl-4",
                    "border-default border",
                    "flex justify-between rounded outline-none",
                    "focus:ring-transparent",
                )}
            >
                <p className="ui-element subdued">{text}</p>
                <Tooltip content="Copy" size={TooltipSize.XS}>
                    <DocumentDuplicateIcon
                        onClick={() => {
                            navigator.clipboard.writeText(text);
                        }}
                        className="hover:stroke-defaultTextColor size-6 cursor-pointer stroke-[#788598]"
                    />
                </Tooltip>
            </div>
        </div>
    );
}
