import clsx from "clsx";

export function ErrorPage() {
    return (
        <main
            className={clsx(
                "px-6 py-24",
                "grid min-h-full place-items-center",
                "sm:py-32 lg:px-8",
            )}
        >
            <div className="text-center">
                <h2 className="mt-4 ">Oops! Something went wrong</h2>
                <p className="subdued mt-6 text-base leading-7">
                    There was a problem loading this page.
                </p>
                <div
                    className={clsx(
                        "mt-10",
                        "flex items-center justify-center gap-x-6",
                    )}
                >
                    <button
                        onClick={() => window.location.reload()}
                        className="sm"
                    >
                        Refresh Page
                    </button>
                    <a
                        href="mailto:hello@humcapital.com"
                        className="link text-sm font-semibold"
                    >
                        Contact support <span aria-hidden="true">&rarr;</span>
                    </a>
                </div>
            </div>
        </main>
    );
}
