import React from "react";
import clsx from "clsx";

type SplashProps = {
    testId?: string;
    className?: string;
    graphic: React.ReactNode;
    title: string;
    subtitle: string;
    action?: React.ReactNode;
};

export function Splash({
    testId,
    className,
    graphic,
    title,
    subtitle,
    action,
}: SplashProps) {
    return (
        <div
            className={clsx(
                "flex",
                "flex-col",
                "justify-between",
                "text-center",
                "px-4",
                className,
            )}
            data-testid={testId}
        >
            <div className="flex flex-col justify-start">{graphic}</div>
            <h3 className="mt-10">{title}</h3>
            <p className="subdued body-m mt-3 max-w-md">{subtitle}</p>
            <div className="mt-6">{action}</div>
        </div>
    );
}
