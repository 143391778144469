import { Navigate, Route, Routes } from "react-router-dom";

export function LockedRoute({
    path,
    element,
    children,
}: {
    path: string;
    element: JSX.Element;
    children?: React.ReactNode;
}) {
    return (
        <Routes>
            <Route path={path} element={element} />
            {children}
            <Route path="*" element={<Navigate to={path} />} />
        </Routes>
    );
}
