import React, { ReactElement } from "react";
import { NotificationType } from ".";
import { Icon, IconProps } from "../../atoms";

type NotificationConfig = {
    noIcon?: boolean;
    icon: ReactElement<IconProps>;
    bg: string;
};

export const getNotificationConfig = (
    type: NotificationType,
): NotificationConfig | null => {
    switch (type) {
        case NotificationType.COMPLETED:
            return {
                bg: "bg-blue-200",
                icon: (
                    <Icon.CircleCheck
                        width="20"
                        height="20"
                        className="text-blue-400"
                    />
                ),
            };
        case NotificationType.NEW:
            return {
                bg: "bg-green-200",
                icon: <Icon.NotificationStar className="text-green-700" />,
            };
        case NotificationType.WARNING:
            return {
                noIcon: true,
                bg: "bg-orange-300",
                icon: (
                    <Icon.CircleExclamation
                        width="20"
                        height="20"
                        className="text-orange-700"
                    />
                ),
            };
        default:
            return null;
    }
};
