import { Widget } from "@/ui/organisms/Widget";

export function TextWidget({
    header,
    children,
    accented = false,
}: {
    header: string;
    children: string;
    accented?: boolean;
}) {
    return (
        <Widget accented={accented} header={header}>
            <p className="body-s">{children}</p>
        </Widget>
    );
}
