import React from "react";
import { IconProps } from "../types";

const WaveIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_4113)">
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.0022 26.0899C11.4046 26.5358 12.9008 25.82 13.3291 24.4998L14.9134 19.3657C15.3417 18.0455 14.5437 16.602 13.1472 16.1561L13.0299 16.1209C11.6275 15.675 10.1313 16.3908 9.70297 17.711L8.11873 22.8451C7.6904 24.1653 8.48838 25.6087 9.88486 26.0547L10.0022 26.0899Z"
                            fill="#084F99"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M26.7242 11.5033C27.0234 10.7815 27.575 10.23 28.2263 9.93661C28.2615 9.91901 28.2908 9.90728 28.326 9.88967C28.3436 9.88381 28.3612 9.87794 28.3847 9.8662C28.9186 9.66084 29.5112 9.62563 30.0804 9.80166L30.1977 9.83687C31.6001 10.2828 32.3453 11.8788 31.8524 13.3867C31.8524 13.3867 28.015 26.3423 28.015 26.354C27.0117 29.3347 23.8315 30.8779 21.0854 30.1034C21.0854 30.1034 20.3461 29.8863 20.041 29.6633C22.7636 29.0766 26.1843 12.8058 26.73 11.4915L26.7242 11.5033Z"
                            fill="#5EB7FF"
                        />
                        <path
                            fillRule="evenodd"
                            clipRule="evenodd"
                            d="M10.4478 27.8039C11.0404 27.9917 11.6507 28.0034 12.2257 27.8684C12.3196 27.8508 12.4076 27.8215 12.4956 27.7922C13.6222 27.4342 14.5962 26.513 15.0069 25.2398L17.9935 15.3764C18.4864 13.8802 20.0295 13.0177 21.4201 13.4577L21.5375 13.4929C22.9398 13.9389 23.685 15.5348 23.1921 17.0428C23.1921 17.0428 20.3464 26.3898 19.9532 27.1702C17.0488 32.4275 11.5979 29.8575 10.0547 27.6572C10.1427 27.6924 10.2307 27.7276 10.3246 27.757L10.4419 27.7922L10.4478 27.8039Z"
                            fill="#1479FB"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_4113">
                            <rect
                                width="23.9983"
                                height="20.6186"
                                fill="white"
                                transform="translate(8.00098 9.69043)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};
export default WaveIcon;
