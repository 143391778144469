import { Transition } from "@headlessui/react";
import clsx from "clsx";
import { useEffect, useState } from "react";

type MarketingBannerProps = {
    testId?: string;
    className?: string;
    title?: string;
    description?: string;
    icon?: React.ReactNode;
    actions: {
        primary: {
            label: string;
            onClick: () => void;
        };
        secondary?: {
            label: string;
            onClick: () => void;
        };
    };
};

export function MarketingBanner({
    testId,
    className,
    title,
    description,
    icon,
    actions,
}: MarketingBannerProps) {
    const [showBanner, setShowBanner] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            setShowBanner(true);
        }, 1000);
    }, []);

    return (
        <>
            <Transition
                appear={true}
                as="div"
                show={showBanner}
                enter="transition ease-out duration-500 transform origin-top"
                enterFrom="-translate-y-5"
                enterTo="translate-y-0"
            >
                <div className="bg-secondary">
                    <div
                        className={clsx(
                            className,
                            "mx-auto max-w-2xl px-4 py-11 md:px-0 lg:max-w-4xl",
                        )}
                        data-testid={testId}
                    >
                        <div
                            className={clsx(
                                "flex flex-col",
                                "items-start justify-around",
                                "lg:flex-row lg:items-center",
                            )}
                        >
                            <div className="flex items-center">
                                <div className="mr-4 hidden lg:block">
                                    {icon}
                                </div>
                                <div>
                                    <h3>{title}</h3>
                                    <p className="subdued body-l mt-2">
                                        {description}
                                    </p>
                                </div>
                            </div>
                            <div className="flex items-center pt-5 lg:pt-0">
                                <button
                                    className="md mr-4"
                                    onClick={actions.primary.onClick}
                                >
                                    {actions.primary.label}
                                </button>
                                {actions.secondary && (
                                    <button
                                        className="quiet md"
                                        onClick={actions.secondary.onClick}
                                    >
                                        {actions.secondary.label}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </Transition>

            {!showBanner && (
                //This is a hack to prevent the page from jumping when the banner is shown
                <div className="h-[180px]"></div>
            )}
        </>
    );
}
