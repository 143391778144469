import React from "react";
import { IconProps } from "./types";

const CsvFileIcon = ({
    className,
    width = "25",
    height = "25",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M5.08929 1.5H14.0315C14.3805 1.5 14.7186 1.62168 14.9875 1.8441L20.456 6.36674C20.8005 6.6517 21 7.07551 21 7.52264V21.3917C21 23.3613 20.977 23.5 18.9107 23.5H5.08929C3.023 23.5 3 23.3613 3 21.3917V3.60833C3 1.63874 3.023 1.5 5.08929 1.5Z"
                        fill="#2A313F"
                    />
                    <path
                        d="M9.36736 13.0475C9.39247 13.1647 9.29867 13.2696 9.17887 13.2696H8.53097C8.43138 13.2696 8.34925 13.1955 8.32042 13.1001C8.29992 13.0324 8.27321 12.9693 8.24028 12.9109C8.18134 12.8048 8.10567 12.7146 8.01327 12.6401C7.92087 12.5657 7.81414 12.5087 7.69307 12.4691C7.57359 12.4295 7.44375 12.4097 7.30356 12.4097C7.05027 12.4097 6.82963 12.4723 6.64165 12.5974C6.45367 12.7209 6.3079 12.9014 6.20435 13.139C6.1008 13.3749 6.04903 13.6615 6.04903 13.9988C6.04903 14.3456 6.1008 14.637 6.20435 14.8729C6.30949 15.1089 6.45606 15.287 6.64404 15.4074C6.83202 15.5277 7.04947 15.5879 7.29639 15.5879C7.43499 15.5879 7.56323 15.5697 7.68112 15.5333C7.8006 15.4968 7.90654 15.4438 7.99893 15.3741C8.09133 15.3029 8.1678 15.2165 8.22833 15.1152C8.26337 15.0581 8.29232 14.9964 8.3152 14.9302C8.34682 14.8386 8.42786 14.7687 8.52469 14.7692L9.17932 14.7721C9.29896 14.7727 9.3922 14.8779 9.36334 14.994C9.32723 15.1393 9.27308 15.281 9.2009 15.4192C9.09416 15.6251 8.94999 15.8096 8.76838 15.9727C8.58837 16.1342 8.3733 16.2625 8.12319 16.3575C7.87467 16.4509 7.5935 16.4976 7.27967 16.4976C6.84317 16.4976 6.45287 16.3994 6.10877 16.2031C5.76626 16.0067 5.49544 15.7225 5.29631 15.3504C5.09877 14.9782 5 14.5277 5 13.9988C5 13.4683 5.10036 13.017 5.30109 12.6449C5.50181 12.2728 5.77423 11.9893 6.11833 11.7945C6.46243 11.5982 6.84954 11.5 7.27967 11.5C7.56323 11.5 7.82609 11.5396 8.06823 11.6188C8.31197 11.6979 8.52783 11.8135 8.71581 11.9656C8.90379 12.116 9.05672 12.3005 9.17461 12.519C9.26173 12.6783 9.32598 12.8545 9.36736 13.0475Z"
                        fill="#788598"
                    />
                    <path
                        d="M12.9818 12.9656C12.8791 12.9656 12.797 12.8857 12.7612 12.7894C12.7205 12.6801 12.6517 12.59 12.5547 12.519C12.4097 12.4129 12.213 12.3599 11.9645 12.3599C11.7956 12.3599 11.653 12.3836 11.5367 12.4311C11.4204 12.477 11.3312 12.5412 11.2691 12.6235C11.2085 12.7059 11.1783 12.7993 11.1783 12.9038C11.1751 12.9909 11.1934 13.0669 11.2332 13.1318C11.2747 13.1968 11.3312 13.253 11.4029 13.3005C11.4746 13.3464 11.5574 13.3868 11.6514 13.4216C11.7454 13.4549 11.8458 13.4834 11.9525 13.5071L12.3922 13.6116C12.6057 13.6591 12.8016 13.7225 12.98 13.8017C13.1585 13.8808 13.313 13.9782 13.4436 14.0938C13.5742 14.2094 13.6754 14.3456 13.7471 14.5024C13.8204 14.6591 13.8578 14.8389 13.8594 15.0416C13.8578 15.3393 13.7813 15.5974 13.63 15.8159C13.4803 16.0329 13.2636 16.2015 12.98 16.3219C12.6981 16.4406 12.3579 16.5 11.9597 16.5C11.5646 16.5 11.2205 16.4398 10.9274 16.3195C10.6358 16.1991 10.408 16.021 10.244 15.785C10.1141 15.5952 10.0336 15.3695 10.0024 15.1079C9.98914 14.9964 10.0806 14.9038 10.1929 14.9038H10.8027C10.9079 14.9038 10.9918 14.9865 11.0204 15.0877C11.0443 15.1722 11.081 15.2471 11.1305 15.3124C11.2149 15.42 11.3272 15.5016 11.4674 15.557C11.6092 15.6108 11.7693 15.6378 11.9477 15.6378C12.123 15.6378 12.2751 15.6124 12.4041 15.5618C12.5348 15.5111 12.6359 15.4406 12.7076 15.3504C12.7793 15.2601 12.8151 15.1564 12.8151 15.0392C12.8151 14.9299 12.7825 14.8381 12.7172 14.7637C12.6535 14.6892 12.5595 14.6259 12.4352 14.5736C12.3125 14.5214 12.162 14.4739 11.9836 14.4311L11.4507 14.2981C11.0381 14.1983 10.7123 14.0424 10.4734 13.8302C10.2344 13.618 10.1157 13.3321 10.1173 12.9727C10.1157 12.6781 10.1946 12.4208 10.3539 12.2007C10.5148 11.9806 10.7354 11.8088 11.0158 11.6853C11.2962 11.5618 11.6148 11.5 11.9716 11.5C12.3348 11.5 12.6519 11.5618 12.9227 11.6853C13.1951 11.8088 13.407 11.9806 13.5583 12.2007C13.6733 12.368 13.746 12.5554 13.7764 12.763C13.7926 12.8736 13.7009 12.9656 13.5892 12.9656H12.9818Z"
                        fill="#788598"
                    />
                    <path
                        d="M15.2935 11.5665C15.3805 11.5665 15.4575 11.6227 15.484 11.7055L16.6224 15.2625H16.6678L17.8084 11.7054C17.835 11.6227 17.912 11.5665 17.9989 11.5665H18.719C18.8565 11.5665 18.953 11.7021 18.9079 11.832L17.313 16.4311H15.9796L14.3824 11.8321C14.3372 11.7022 14.4337 11.5665 14.5713 11.5665H15.2935Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CsvFileIcon;
