import React, { useState } from "react";
import {
    ArrowRightStartOnRectangleIcon,
    Bars3Icon,
    Cog6ToothIcon,
} from "@heroicons/react/24/outline";
import clsx from "clsx";
import { INavLink, UserLink } from "@/utils/navigation/Navigation.types";
import { NavLink } from "react-router-dom";
import { Icon } from "../Icon";
import { MobileNavMenu } from "./MobileNavMenu";
import { useRouter } from "@/utils";

const DefaultLogo = (
    <a href="/">
        <Icon.Hum width="32" height="32" />
    </a>
);

function Link({ to, label, external }: INavLink) {
    return (
        <NavLink
            key={to}
            to={to}
            reloadDocument={!!external}
            className="navlink-item"
        >
            <p
                className={clsx(
                    "body-s",
                    // isActive ? "text-color" : "subdued",
                    "hover:text-color",
                    "cursor-pointer px-3 pb-2",
                )}
            >
                {label}
            </p>
        </NavLink>
    );
}

export function NavBar({
    title,
    links = [],
    logo = DefaultLogo,
    fixedBar,
    background = "bg-default",
    className,
    showSettings = false,
    showLogout = false,
}: {
    title?: string;
    links?: INavLink[];
    userLinks?: UserLink[];
    logo?: React.ReactNode;
    fixedBar?: boolean;
    background?: string;
    className?: string;
    flow?: boolean;
    letter?: string;
    showSettings?: boolean;
    showLogout?: boolean;
}) {
    const [open, setOpen] = useState(false);
    const router = useRouter();

    return (
        <div className={clsx(background, className)}>
            {/* Mobile menu */}
            <MobileNavMenu
                open={open}
                close={() => setOpen(false)}
                links={links}
                showLogout={showLogout}
                showSettings={showSettings}
            />

            <header
                className={`${fixedBar ? "fixed" : "relative"} z-40 w-full`}
            >
                <nav aria-label="Top">
                    <div className={background}>
                        <div className={`max-w-8xl px-6 py-4`}>
                            <div>
                                <div
                                    className={clsx(
                                        "flex items-end justify-between",
                                        {
                                            "lg:justify-start":
                                                links.length > 0,
                                        },
                                    )}
                                >
                                    <div className="mr-6 lg:flex lg:items-center">
                                        {logo}
                                    </div>
                                    {title && (
                                        <p className="body-s subdued mr-auto">
                                            {title}
                                        </p>
                                    )}
                                    {links.length > 0 && (
                                        <div className="hidden h-full grow lg:flex">
                                            {/* List of links - Desktop */}
                                            <div
                                                className={clsx(
                                                    "h-full",
                                                    "flex justify-center space-x-4",
                                                )}
                                            >
                                                {links.map(
                                                    ({
                                                        to,
                                                        label,
                                                        external,
                                                    }) => (
                                                        <Link
                                                            key={to}
                                                            to={to}
                                                            label={label}
                                                            external={external}
                                                        />
                                                    ),
                                                )}
                                            </div>
                                        </div>
                                    )}

                                    <div className="flex items-center justify-end">
                                        {(links.length > 0 ||
                                            showSettings ||
                                            showLogout) && (
                                            <div className="flex items-center lg:hidden">
                                                <button
                                                    type="button"
                                                    data-testid="mobile:open-menu-btn"
                                                    className={`rounded-md ${background} secondary p-2 text-gray-400`}
                                                    onClick={() =>
                                                        setOpen(true)
                                                    }
                                                >
                                                    <Bars3Icon
                                                        className="size-6"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            </div>
                                        )}

                                        <div className="hidden items-center justify-end lg:flex">
                                            <div className="flex items-center lg:ml-8">
                                                <div className="flex space-x-2">
                                                    {showSettings && (
                                                        <button
                                                            className={clsx(
                                                                "xs",
                                                                "quiet",
                                                                "hover:text-slate-200",
                                                                "group",
                                                            )}
                                                            onClick={() =>
                                                                router.updateSearch(
                                                                    "settings",
                                                                    "open",
                                                                )
                                                            }
                                                        >
                                                            <Cog6ToothIcon className="xs size-6 group-hover:stroke-slate-200" />
                                                            Settings
                                                        </button>
                                                    )}
                                                    {showLogout && (
                                                        <NavLink to="/logout">
                                                            <button
                                                                className={clsx(
                                                                    "xs",
                                                                    "quiet ",
                                                                    "hover:text-slate-300",
                                                                    "group",
                                                                )}
                                                            >
                                                                <ArrowRightStartOnRectangleIcon className="xs size-6  group-hover:stroke-slate-200" />
                                                                Logout
                                                            </button>
                                                        </NavLink>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </nav>
            </header>
        </div>
    );
}
