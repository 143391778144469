import React from "react";
import { IconProps } from "../types";

const KashFlowIcon = ({
    className,
    width = "40",
    height = "40",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <g clipPath="url(#clip0_1771_4104)">
                        <path
                            d="M22.2392 16.9409C22.2392 16.9409 9.25874 7.98407 8.49891 22.6799C8.49891 22.6799 6.36909 16.5667 10.9626 12.7273C10.9626 12.7273 16.0109 8.17979 23.448 12.8481L24.2769 10.1484L29.7742 18.3454L21.2664 19.6003C21.2664 19.6003 22.0032 18.0115 22.2392 16.9466V16.9409Z"
                            fill="#E5426E"
                        />
                        <path
                            d="M17.7605 23.0546C17.7605 23.0546 30.7467 32.0113 31.5008 17.3213C31.5008 17.3213 33.6364 23.4345 29.0371 27.2739C29.0371 27.2739 23.9888 31.8214 16.5517 27.153L15.7228 29.8527L10.2773 21.6615L18.7851 20.4527C18.7851 20.4067 18.1001 21.9896 17.7663 23.0603L17.7605 23.0546Z"
                            fill="#E5426E"
                        />
                    </g>
                    <defs>
                        <clipPath id="clip0_1771_4104">
                            <rect
                                width="23.9922"
                                height="19.7153"
                                fill="white"
                                transform="translate(8.00391 10.1426)"
                            />
                        </clipPath>
                    </defs>
                </svg>
            </React.Suspense>
        </div>
    );
};

export default KashFlowIcon;
