import React from "react";
import { IconProps } from "./types";

const MiniArchiveBoxIcon = ({
    className,
    width = "20",
    height = "18",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    role="img"
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M3.64771 5C3.09542 5 2.64771 5.44772 2.64771 6V7C2.64771 7.55228 3.09542 8 3.64771 8H19.6477C20.2 8 20.6477 7.55228 20.6477 7V6C20.6477 5.44772 20.2 5 19.6477 5H3.64771Z"
                        fill="#788598"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M3.64771 9.5H19.6477L18.8362 17.2094C18.729 18.2273 17.8707 19 16.8472 19H6.44823C5.42471 19 4.56636 18.2273 4.45922 17.2094L3.64771 9.5ZM8.64771 13C8.64771 12.4477 9.09542 12 9.64771 12H13.6477C14.2 12 14.6477 12.4477 14.6477 13C14.6477 13.5523 14.2 14 13.6477 14H9.64771C9.09542 14 8.64771 13.5523 8.64771 13Z"
                        fill="#788598"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default MiniArchiveBoxIcon;
