import { createQuery } from "@/api/utils";
import { Company, CompanyFile, CompanySearchResult } from "./Company.types";

export const getCompany = createQuery<Company, { uuid?: string }>(
    ({ api, router, params, session }) => {
        const companyId =
            params?.uuid ||
            router.params.companyUUID ||
            session.user?.organizationId;

        return api.get(`/backend/v2/companies/${companyId}`);
    },
);

export const searchCompanies = createQuery<CompanySearchResult[]>(
    ({ api, params }) => {
        return api
            .get(
                `/backend/companies/search?order=updated_at&q=${params.query}&page=1&num_pages=2&page_size=10&company_type=company`,
            )
            .then((res) => {
                return res.results;
            });
    },
);

export const getCompanyFiles = createQuery<CompanyFile[], { uuid?: string }>(
    ({ api, params, router, session }) => {
        const companyId =
            params?.uuid ||
            router.params.companyUUID ||
            session.user?.organizationId;
        return api
            .get(`/backend/v2/companies/${companyId}/files`)
            .then((res) => res);
    },
);

export const getCompanyFile = createQuery<
    CompanyFile,
    { uuid?: string; fileId: number }
>(({ api, params, router, session }) => {
    const companyId =
        params?.uuid ||
        router.params.companyUUID ||
        session.user?.organizationId;

    return api
        .get(
            `/backend/v2/companies/${companyId}/files/${params.fileId}/download`,
        )
        .then((res) => ({ ...res, download: res.downloadUrl }));
});
