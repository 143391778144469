import React from "react";
import { IconProps } from "./types";

const InvestorSignupStepOne = ({
    className,
    width = "200",
    height = "184",
}: IconProps) => {
    return (
        <svg
            width={width}
            height={height}
            viewBox={`0 0 ${width} ${height}`}
            fill="currentColor"
            className={className}
            xmlns="http://www.w3.org/2000/svg"
        >
            <g clipPath="url(#clip0_1602_19650)">
                <g filter="url(#filter0_b_1602_19650)">
                    <rect
                        x="29.3546"
                        y="123.184"
                        width="133.657"
                        height="28.8159"
                        rx="4.39656"
                        fill="#343C47"
                        fillOpacity="0.5"
                    />
                </g>
                <path
                    d="M40.4064 138.296V140.142C40.7111 140.086 40.9886 139.981 41.2096 139.837C41.5501 139.614 41.642 139.379 41.642 139.219C41.642 139.059 41.5501 138.824 41.2096 138.601C40.9886 138.457 40.7111 138.352 40.4064 138.296Z"
                    fill="white"
                />
                <path
                    d="M38.6975 136.744C38.7355 136.784 38.7787 136.822 38.8277 136.86C38.9745 136.974 39.1522 137.061 39.3474 137.116V135.32C39.2947 135.335 39.2434 135.353 39.1935 135.372C39.1618 135.385 39.1307 135.398 39.1003 135.412C39.0014 135.458 38.9097 135.513 38.8277 135.577C38.5612 135.782 38.4648 136.019 38.4648 136.218C38.4648 136.348 38.5056 136.494 38.6076 136.636C38.6335 136.672 38.6634 136.708 38.6975 136.744Z"
                    fill="white"
                />
                <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M45.5253 137.719C45.5253 140.838 42.9964 143.367 39.8769 143.367C36.7574 143.367 34.2285 140.838 34.2285 137.719C34.2285 134.599 36.7574 132.07 39.8769 132.07C42.9964 132.07 45.5253 134.599 45.5253 137.719ZM39.8768 133.482C40.1693 133.482 40.4064 133.72 40.4064 134.012V134.235C40.8246 134.309 41.2312 134.474 41.5736 134.738C41.8745 134.971 42.0986 135.261 42.2268 135.586C42.334 135.858 42.2004 136.165 41.9283 136.273C41.6562 136.38 41.3487 136.246 41.2415 135.974C41.1901 135.844 41.0913 135.704 40.926 135.576C40.7792 135.463 40.6016 135.376 40.4064 135.32V137.224C40.8993 137.29 41.3827 137.449 41.7892 137.715C42.3449 138.078 42.701 138.611 42.701 139.219C42.701 139.827 42.3449 140.36 41.7892 140.723C41.3827 140.989 40.8993 141.148 40.4064 141.214V141.425C40.4064 141.718 40.1693 141.955 39.8768 141.955C39.5844 141.955 39.3473 141.718 39.3473 141.425V141.214C38.8544 141.148 38.371 140.989 37.9645 140.723C37.6238 140.5 37.3589 140.216 37.2034 139.884C37.0793 139.619 37.1935 139.304 37.4583 139.18C37.7232 139.056 38.0384 139.17 38.1625 139.435C38.2195 139.557 38.3355 139.7 38.5441 139.837C38.7651 139.981 39.0426 140.086 39.3473 140.142V138.202C38.9291 138.127 38.5225 137.963 38.1801 137.698C37.6884 137.318 37.4057 136.787 37.4057 136.218C37.4057 135.65 37.6884 135.118 38.1801 134.738C38.5225 134.474 38.9291 134.309 39.3473 134.235V134.012C39.3473 133.72 39.5844 133.482 39.8768 133.482Z"
                    fill="white"
                />
                <rect
                    x="51.3982"
                    y="136.183"
                    width="105.944"
                    height="3.97291"
                    rx="1.98646"
                    fill="#12181E"
                />
                <rect
                    x="51.3982"
                    y="136.183"
                    width="105.944"
                    height="3.97291"
                    rx="1.98646"
                    fill="#12181E"
                />
                <rect
                    x="51.3982"
                    y="136.183"
                    width="105.944"
                    height="3.97291"
                    rx="1.98646"
                    fill="#12181E"
                />
                <rect
                    x="51.3982"
                    y="136.183"
                    width="33.1076"
                    height="3.97291"
                    rx="1.98646"
                    fill="#AEC3FC"
                />
                <rect
                    x="25"
                    y="128"
                    width="138"
                    height="29"
                    fill="url(#paint0_linear_1602_19650)"
                />
                <g filter="url(#filter1_d_1602_19650)">
                    <g filter="url(#filter2_b_1602_19650)">
                        <rect
                            x="58.5078"
                            y="78.4471"
                            width="140.492"
                            height="30.2896"
                            rx="4.39656"
                            fill="#343C47"
                            fillOpacity="0.5"
                        />
                    </g>
                    <path
                        d="M70.1248 94.3319V96.2724C70.4451 96.2132 70.7368 96.1034 70.9691 95.9514C71.327 95.7174 71.4236 95.4706 71.4236 95.3021C71.4236 95.1336 71.327 94.8868 70.9691 94.6528C70.7368 94.5009 70.4451 94.391 70.1248 94.3319Z"
                        fill="white"
                    />
                    <path
                        d="M68.3285 92.7005C68.3684 92.742 68.4139 92.7829 68.4654 92.8227C68.6197 92.942 68.8064 93.0332 69.0116 93.0919V91.2041C68.9563 91.2199 68.9023 91.2381 68.8499 91.2586C68.8166 91.2716 68.7839 91.2856 68.7519 91.3005C68.6479 91.3488 68.5516 91.4067 68.4654 91.4733C68.1853 91.6897 68.0839 91.9383 68.0839 92.148C68.0839 92.2844 68.1268 92.4374 68.234 92.5872C68.2613 92.6253 68.2926 92.6631 68.3285 92.7005Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M75.5055 93.7251C75.5055 97.0041 72.8473 99.6623 69.5682 99.6623C66.2892 99.6623 63.631 97.0041 63.631 93.7251C63.631 90.446 66.2892 87.7878 69.5682 87.7878C72.8473 87.7878 75.5055 90.446 75.5055 93.7251ZM69.5682 89.2721C69.8756 89.2721 70.1248 89.5213 70.1248 89.8287V90.0631C70.5644 90.1412 70.9918 90.3143 71.3517 90.5924C71.668 90.8368 71.9035 91.1411 72.0383 91.483C72.151 91.769 72.0105 92.0922 71.7245 92.2049C71.4385 92.3177 71.1153 92.1772 71.0026 91.8912C70.9486 91.7542 70.8447 91.6075 70.671 91.4733C70.5167 91.354 70.33 91.2628 70.1248 91.204V93.2051C70.6429 93.2741 71.151 93.4417 71.5783 93.7211C72.1624 94.103 72.5368 94.6634 72.5368 95.3021C72.5368 95.9409 72.1624 96.5013 71.5783 96.8832C71.151 97.1626 70.6429 97.3302 70.1248 97.3991V97.6214C70.1248 97.9288 69.8756 98.178 69.5682 98.178C69.2608 98.178 69.0116 97.9288 69.0116 97.6214V97.3991C68.4935 97.3302 67.9854 97.1626 67.558 96.8832C67.1999 96.649 66.9215 96.3505 66.758 96.0014C66.6276 95.723 66.7476 95.3916 67.026 95.2612C67.3044 95.1308 67.6357 95.2508 67.7661 95.5292C67.8261 95.6573 67.948 95.8081 68.1672 95.9514C68.3996 96.1034 68.6912 96.2132 69.0116 96.2724V94.2328C68.572 94.1547 68.1445 93.9816 67.7847 93.7036C67.2678 93.3042 66.9706 92.7455 66.9706 92.148C66.9706 91.5504 67.2678 90.9918 67.7847 90.5924C68.1445 90.3143 68.572 90.1412 69.0116 90.0631V89.8287C69.0116 89.5213 69.2608 89.2721 69.5682 89.2721Z"
                        fill="white"
                    />
                    <rect
                        x="80"
                        y="92"
                        width="113"
                        height="4"
                        rx="2"
                        fill="#12181E"
                    />
                    <rect
                        x="80"
                        y="92"
                        width="113"
                        height="4"
                        rx="2"
                        fill="#12181E"
                    />
                    <rect
                        x="80"
                        y="92"
                        width="113"
                        height="4"
                        rx="2"
                        fill="#12181E"
                    />
                    <rect
                        x="80"
                        y="92"
                        width="98"
                        height="4"
                        rx="2"
                        fill="#AEC3FC"
                    />
                </g>
                <g filter="url(#filter3_d_1602_19650)">
                    <g filter="url(#filter4_b_1602_19650)">
                        <rect
                            x="1"
                            y="27"
                            width="172.145"
                            height="37.1139"
                            rx="4.39656"
                            fill="#29303D"
                        />
                    </g>
                    <path
                        d="M15.2343 46.4636V48.8414C15.6269 48.7689 15.9842 48.6342 16.2689 48.4481C16.7074 48.1613 16.8257 47.8589 16.8257 47.6525C16.8257 47.446 16.7074 47.1436 16.2689 46.8569C15.9842 46.6707 15.6269 46.5361 15.2343 46.4636Z"
                        fill="white"
                    />
                    <path
                        d="M13.0334 44.4647C13.0823 44.5156 13.138 44.5657 13.2011 44.6144C13.3902 44.7606 13.619 44.8723 13.8703 44.9443V42.6311C13.8026 42.6505 13.7364 42.6728 13.6722 42.6979C13.6314 42.7139 13.5913 42.731 13.5521 42.7492C13.4247 42.8085 13.3067 42.8794 13.2011 42.961C12.8578 43.2262 12.7336 43.5308 12.7336 43.7877C12.7336 43.9549 12.7862 44.1423 12.9176 44.3259C12.951 44.3725 12.9894 44.4189 13.0334 44.4647Z"
                        fill="white"
                    />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M21.8273 45.7201C21.8273 49.7379 18.5702 52.995 14.5524 52.995C10.5346 52.995 7.27747 49.7379 7.27747 45.7201C7.27747 41.7023 10.5346 38.4452 14.5524 38.4452C18.5702 38.4452 21.8273 41.7023 21.8273 45.7201ZM14.5523 40.2639C14.929 40.2639 15.2343 40.5693 15.2343 40.9459V41.2331C15.773 41.3288 16.2967 41.5409 16.7376 41.8816C17.1252 42.1811 17.4138 42.554 17.5789 42.9729C17.717 43.3233 17.5449 43.7193 17.1945 43.8575C16.8441 43.9956 16.448 43.8235 16.3099 43.4731C16.2437 43.3052 16.1165 43.1254 15.9036 42.961C15.7145 42.8148 15.4857 42.7031 15.2343 42.6311V45.083C15.8691 45.1675 16.4918 45.3729 17.0154 45.7152C17.731 46.1832 18.1898 46.8698 18.1898 47.6525C18.1898 48.4351 17.731 49.1218 17.0154 49.5897C16.4918 49.9321 15.8691 50.1375 15.2343 50.2219V50.4942C15.2343 50.8709 14.929 51.1763 14.5523 51.1763C14.1756 51.1763 13.8703 50.8709 13.8703 50.4942V50.2219C13.2355 50.1375 12.6129 49.9321 12.0893 49.5897C11.6505 49.3028 11.3093 48.937 11.109 48.5092C10.9492 48.1681 11.0962 47.7621 11.4373 47.6023C11.7784 47.4426 12.1845 47.5896 12.3442 47.9307C12.4177 48.0876 12.5672 48.2724 12.8358 48.4481C13.1205 48.6342 13.4778 48.7689 13.8703 48.8414V46.3422C13.3317 46.2466 12.8079 46.0345 12.367 45.6938C11.7336 45.2044 11.3695 44.5199 11.3695 43.7877C11.3695 43.0555 11.7336 42.371 12.367 41.8816C12.8079 41.5409 13.3317 41.3288 13.8703 41.2331V40.9459C13.8703 40.5693 14.1756 40.2639 14.5523 40.2639Z"
                        fill="white"
                    />
                    <rect
                        x="28"
                        y="44"
                        width="137"
                        height="5"
                        rx="2.5"
                        fill="#12181E"
                    />
                    <rect
                        x="28"
                        y="44"
                        width="137"
                        height="5"
                        rx="2.5"
                        fill="#12181E"
                    />
                    <rect
                        x="28"
                        y="44"
                        width="137"
                        height="5"
                        rx="2.5"
                        fill="#12181E"
                    />
                    <rect
                        x="28"
                        y="44"
                        width="94"
                        height="5"
                        rx="2.5"
                        fill="#AEC3FC"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_b_1602_19650"
                    x="25.3546"
                    y="119.184"
                    width="141.657"
                    height="36.8159"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1602_19650"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_1602_19650"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter1_d_1602_19650"
                    x="54.5078"
                    y="78.4471"
                    width="148.492"
                    height="38.2896"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1602_19650"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1602_19650"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter2_b_1602_19650"
                    x="54.5078"
                    y="74.4471"
                    width="148.492"
                    height="38.2896"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1602_19650"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_1602_19650"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter3_d_1602_19650"
                    x="-3"
                    y="27"
                    width="180.145"
                    height="45.1139"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="2" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.5 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_1602_19650"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_1602_19650"
                        result="shape"
                    />
                </filter>
                <filter
                    id="filter4_b_1602_19650"
                    x="-3"
                    y="23"
                    width="180.145"
                    height="45.1139"
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="2" />
                    <feComposite
                        in2="SourceAlpha"
                        operator="in"
                        result="effect1_backgroundBlur_1602_19650"
                    />
                    <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_backgroundBlur_1602_19650"
                        result="shape"
                    />
                </filter>
                <linearGradient
                    id="paint0_linear_1602_19650"
                    x1="128.651"
                    y1="148.233"
                    x2="128.651"
                    y2="126.314"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor="#12181E" />
                    <stop offset="1" stopColor="#12181E" stopOpacity="0" />
                </linearGradient>
                <clipPath id="clip0_1602_19650">
                    <rect width="200" height="184" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default InvestorSignupStepOne;
