import { useEffect } from "react";
import { Spinner } from "@/ui";
import { useAuth0 } from "@auth0/auth0-react";
import Cookies from "js-cookie";

function LogoutRedirect() {
    const { logout } = useAuth0();

    useEffect(() => {
        logout();
        localStorage.clear();
        sessionStorage.clear();
        Cookies.remove(process.env.TOKEN_COOKIE);
        console.log("user logged out");
    }, [logout]);

    return (
        <>
            <Spinner fullScreen />
            <p className="sr-only">Logout Page</p>
        </>
    );
}

export default LogoutRedirect;
