import { Icon, StickyFooter, Spinner } from "@/ui";

type CTAProps = {
    testId?: string;
    onNotInterested?: () => void;
    onExploreOpportunity?: () => void;
    showSpinnerButton?: boolean;
    showReject?: boolean;
    showAccept?: boolean;
};

export function OpportunityCTA({
    onNotInterested,
    onExploreOpportunity,
    testId,
    showSpinnerButton,
    showReject = true,
    showAccept = true,
}: CTAProps) {
    return (
        <StickyFooter testId={testId}>
            {showReject && (
                <button
                    onClick={onNotInterested}
                    className="secondary lg sm:mr-4"
                >
                    {"I'm not interested"}
                </button>
            )}
            {showAccept && (
                <button
                    onClick={onExploreOpportunity}
                    className="lg mt-4 sm:ml-4 sm:mt-0"
                    disabled={showSpinnerButton}
                >
                    {showSpinnerButton ? (
                        <Spinner className="mr-2 inline-block" />
                    ) : (
                        <Icon.Check className="mr-2 inline-block" />
                    )}
                    <p className="body-s text-gray-0">
                        I am interested in this company
                    </p>
                </button>
            )}
        </StickyFooter>
    );
}
