import { ddActions } from "@/utils";
import { Section } from "@/ui";
import clsx from "clsx";

export const Footer = () => {
    return (
        <Section>
            <p className="body-micro subdued">
                Securities are offered through ICM Capital Markets, LLC, Member
                FINRA/SIPC. Find out more about us on{" "}
                <a
                    href="https://brokercheck.finra.org/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="ui-link text-bodyMicoSize"
                    dd-action={ddActions.profile.footer.finra}
                >
                    {"FINRA's Brokercheck website."}
                </a>{" "}
                The information summarized on this page represents the views of
                Hum Capital at this time; its views may change without notice.
                The information presented herein may not be accurate or
                complete. This information does not constitute an offer to sell
                or a solicitation to buy any securities. By clicking the Explore
                this opportunity button below, you acknowledge this disclaimer
                and agree to keep this information strictly confidential.
            </p>
            <div
                className={clsx(
                    "mt-10 pb-10",
                    "flex items-center justify-center",
                )}
            >
                <div>
                    <p
                        className={clsx(
                            "my-2",
                            "text-center text-white",
                            "body-s subdued",
                        )}
                    >
                        Have a question?
                    </p>
                    <p className="body-s subdued flex items-center gap-1 text-center">
                        Check out our{" "}
                        <a
                            href="https://info.humcapital.com/hum-help-center"
                            target="_blank"
                            rel="noopener noreferrer"
                            className="ui-link"
                            dd-action={ddActions.profile.footer.help}
                        >
                            Help Center
                        </a>{" "}
                        or{" "}
                        <a
                            href="mailto:hum-an@humcapital.com"
                            className="ui-link"
                            dd-action={ddActions.profile.footer.contact}
                        >
                            Contact Hum
                        </a>
                    </p>
                </div>
            </div>
        </Section>
    );
};
