import { Controller, Control, FieldValues } from "react-hook-form";
import { NumericFormat } from "react-number-format";
import clsx from "clsx";

export enum FieldMode {
    TEXT = "text",
    PASSWORD = "password",
    MONEY = "money",
    TEXTAREA = "textarea",
}

type FieldProps = {
    testId?: string;
    label?: string;
    className?: string;
    name: string;
    control: Control<FieldValues>;
    error?: string;
    placeholder?: string;
    required?: boolean;
    type?: FieldMode;
};

export const Field = ({
    label,
    className,
    name,
    type = FieldMode.TEXT,
    control,
    error,
    placeholder,
    required = false,
}: FieldProps) => {
    return (
        <>
            {label && (
                <label className="subdued" data-testid={name}>
                    {label}
                </label>
            )}

            <div className={`mt-2 ${className}`}>
                <Controller
                    name={name}
                    control={control}
                    defaultValue=""
                    render={({ field: { ref, onChange, ...rest } }) => {
                        if (type === FieldMode.MONEY) {
                            return (
                                <NumericFormat
                                    getInputRef={ref}
                                    allowNegative={false}
                                    thousandSeparator=","
                                    required={required}
                                    prefix="$"
                                    decimalScale={0}
                                    id={name}
                                    onValueChange={(v) => {
                                        onChange(Number(v.value));
                                    }}
                                    type="text"
                                    {...rest}
                                    placeholder={placeholder}
                                    className={clsx(
                                        "bg-secondary w-full px-4 py-3",
                                        "ring-1 ring-inset ring-slate-300 dark:ring-slate-700",
                                        "subdued block rounded-md border-0",
                                        "focus:ring-interactive placeholder:text-disabledTextColor focus:ring-2 focus:ring-inset focus:placeholder:text-transparent",
                                    )}
                                />
                            );
                        } else if (type === FieldMode.TEXTAREA) {
                            return (
                                <textarea
                                    onChange={onChange}
                                    ref={ref}
                                    required={required}
                                    id={name}
                                    {...rest}
                                    rows={4}
                                    placeholder={placeholder}
                                    className={clsx(
                                        "bg-secondary w-full px-4 py-3",
                                        "ring-1 ring-inset ring-slate-300 dark:ring-slate-700",
                                        "subdued block rounded-md border-0",
                                        "focus:ring-interactive placeholder:text-disabledTextColor focus:ring-2 focus:ring-inset focus:placeholder:text-transparent",
                                    )}
                                />
                            );
                        } else {
                            return (
                                <input
                                    onChange={onChange}
                                    ref={ref}
                                    required={required}
                                    id={name}
                                    type={type}
                                    {...rest}
                                    placeholder={placeholder}
                                    className={clsx(
                                        "bg-secondary w-full px-4 py-3",
                                        "ring-1 ring-inset ring-slate-300 dark:ring-slate-700",
                                        "subdued block rounded-md border-0",
                                        "focus:ring-interactive placeholder:text-disabledTextColor focus:ring-2 focus:ring-inset focus:placeholder:text-transparent",
                                    )}
                                />
                            );
                        }
                    }}
                />
                {error && (
                    <p className="body-micro text-interactive-destructive-default mt-1">
                        {error}
                    </p>
                )}
            </div>
        </>
    );
};
