import React from "react";
import { IconProps } from "./types";

const CircleSignatureIcon = ({
    className,
    width = "52",
    height = "52",
    onClick,
}: IconProps) => {
    return (
        <div onClick={onClick} role={onClick ? "button" : undefined}>
            <React.Suspense fallback={null}>
                <svg
                    width={width}
                    height={height}
                    viewBox={`0 0 ${width} ${height}`}
                    fill="currentColor"
                    className={className}
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="52" height="52" rx="26" fill="#92ABFA" />
                    <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M25.8134 24.6443L24.5516 27.7987C24.3883 28.2069 24.7934 28.6119 25.2015 28.4487L28.356 27.1869C28.859 26.9857 29.3158 26.6845 29.6989 26.3014L34.4995 21.5002C35.3279 20.6718 35.3279 19.3287 34.4995 18.5002C33.671 17.6718 32.3279 17.6718 31.4995 18.5002L26.6989 23.3014C26.3158 23.6845 26.0146 24.1413 25.8134 24.6443ZM21.0339 26.9733C19.6222 26.9733 18.6225 28.6387 19.112 30.6874C19.2816 31.3974 19.5941 31.9925 19.9716 32.4722C19.9118 32.4849 19.8576 32.4931 19.8103 32.4977H17.7549C17.3407 32.4977 17.0049 32.8335 17.0049 33.2477C17.0049 33.6619 17.3407 33.9977 17.7549 33.9977H19.8415C19.8597 33.9977 19.878 33.997 19.8962 33.9957C20.2495 33.9698 20.7429 33.8492 21.2556 33.5838C21.7174 33.8456 22.1994 33.9977 22.6236 33.9977C23.1552 33.9977 23.7823 33.8287 24.2873 33.5265C24.5949 33.3423 24.9396 33.0538 25.1406 32.6572C25.3156 33.006 25.5914 33.2661 25.8422 33.444C26.2551 33.7367 26.7291 33.9058 27.0513 33.979C27.1058 33.9914 27.1616 33.9977 27.2175 33.9977H31.7747C32.189 33.9977 32.5247 33.6619 32.5247 33.2477C32.5247 32.8335 32.189 32.4977 31.7747 32.4977H27.3103C27.1336 32.4484 26.8971 32.3531 26.7098 32.2203C26.4898 32.0643 26.4611 31.9527 26.4611 31.8975C26.4611 30.8774 25.9406 30.1499 25.1935 30.1139C25.1814 30.1133 25.1694 30.113 25.1573 30.113C24.83 30.113 24.4562 30.2617 24.1834 30.5947C23.906 30.9334 23.7861 31.3932 23.812 31.9335C23.8122 31.9369 23.8138 32.0617 23.5169 32.2394C23.2397 32.4053 22.8781 32.4977 22.6236 32.4977C22.6122 32.4977 22.5993 32.4971 22.5853 32.4958C22.8867 32.1199 23.1486 31.6597 23.3352 31.1002C24.0448 28.9714 22.7881 26.9733 21.0339 26.9733ZM21.9121 30.6258C21.7599 31.0824 21.5353 31.4349 21.2851 31.7042C20.9731 31.3594 20.7063 30.9053 20.5709 30.3388C20.3873 29.5704 20.5105 29.0743 20.6649 28.8018C20.8325 28.5061 21.0199 28.4733 21.0339 28.4733C21.4766 28.4733 22.3948 29.1777 21.9121 30.6258ZM25.3486 31.5387L25.3474 31.5405C25.3481 31.5392 25.3486 31.5386 25.3486 31.5387Z"
                        fill="#2A313F"
                    />
                </svg>
            </React.Suspense>
        </div>
    );
};

export default CircleSignatureIcon;
