import { createApiClient, createMutation } from "@/api/utils";
import { User } from "./User.model";

export const createUser = createMutation<
    User,
    {
        email: string;
        auth0Id: string;
        role: string;
        token: string;
    }
>(
    async ({ payload }) => {
        const { email, auth0Id, role, token } = payload;

        return await createApiClient({
            token,
            convert: false,
        }).post(`/identity/users`, {
            ["auth0_id"]: auth0Id,
            user_role: role,
            email,
        });
    },
    {
        waitForSession: false,
        mutationKey: ["createUser"],
    },
);

export const updateUser = createMutation<User>(
    ({ session, payload }) => {
        console.log("payload", payload);
        return createApiClient({
            baseURL: `${process.env.API_GATEWAY_URL}`,
            token: session.token.data || "",
        }).patch(`/identity/users/${session.user?.id}`, payload);
    },
    {
        errorToast: {
            message: "Failed to save details",
            options: { description: "Please try again or contact support." },
        },
        invalidates: ["/session", "/companies", "Company Onboarding"],
    },
);

export const sendVerificationEmail = createMutation<User>(
    ({ session }) => {
        return createApiClient({
            baseURL: `${process.env.API_GATEWAY_URL}`,
            token: session.token.data || "",
        }).post(`/identity/verify-email`, {});
    },
    {
        successToast: { message: "We've sent a verification email" },
        errorToast: { message: "Failed to send verification email" },
    },
);
