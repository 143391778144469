import { datadogRum } from "@datadog/browser-rum";
import { LinkIcon } from "@heroicons/react/24/outline";
import { ddActions } from "@/utils";
import { StickyFooter, toast } from "@/ui";
import clsx from "clsx";

type CTAProps = {
    testId?: string;
    token?: string;
    showSpinnerButton?: boolean;
};

export function LinkCTA({ token, testId }: CTAProps) {
    if (!token) return null;
    const publicBaseURL =
        process.env.NODE_ENV === "development"
            ? `${process.env.V2_BASE_URL}public`
            : `${process.env.V1_BASE_URL}${process.env.V2_BASE_URL}/public`;

    return (
        <StickyFooter testId={testId}>
            <button
                onClick={() => {
                    navigator.clipboard.writeText(
                        `${publicBaseURL}/${token}?utm_source=hum_admin&utm_medium=copy_link`,
                    );
                    toast.success("Copied to clipboard");
                    datadogRum.addAction(ddActions.profile.share.public, {
                        token: token,
                    });
                }}
                className={clsx("lg", "secondary flex items-center", "sm:mr-4")}
            >
                <LinkIcon
                    className="mr-2 inline-block"
                    width={20}
                    color="white"
                />
                <p className="text-button">Copy public link</p>
            </button>
        </StickyFooter>
    );
}
