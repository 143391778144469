import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import clsx from "clsx";
import { twMerge } from "tailwind-merge";

// useV2Background is temporary, following up in next PR.
export const SearchBar = ({
    placeholder,
    searchHandler,
    className,
    secondary = false,
    defaultValue,
}: {
    placeholder: string;
    searchHandler: (value: string) => void;
    className?: string;
    secondary?: boolean;
    defaultValue?: string;
    useV2Background?: boolean;
}) => {
    return (
        <div className={clsx(twMerge("relative flex items-center", className))}>
            <input
                type="text"
                className="w-full"
                placeholder={placeholder}
                onChange={(e) => searchHandler(e.target.value)}
                defaultValue={defaultValue}
            />
            {secondary && (
                <div
                    className={clsx(
                        "pr-3 pt-1",
                        "pointer-events-none absolute inset-y-0 right-0 flex items-center",
                    )}
                >
                    <MagnifyingGlassIcon className="size-5 text-slate-500" />
                </div>
            )}
        </div>
    );
};
